<template>
    <div class="container-fluid">
        <br>
        <p class="text-header">WALLET BALANCE</p>
       
        <div class="account-details">
            <div class="col-sm-12 table table-responsive">
                <div class="contract-header">
                    <div>Wallet <span class="badge badge-primary">{{ utilityQty }}</span></div>
                    <div> </div>
                </div>
    
                <div class="table table-responsive table-sm">
                    <v-data-table :headers="header" :items="desserts" dense class="table-striped table-bordered table-responsive">
                        
                        <template v-slot:item.business_name="{ item }">
                            <div class="a-text">{{ item.business_name }}</div>
                        </template>
                        <template v-slot:item.available_balance="{ item }">
                            <div class="a-text"> {{item.available_balance}}</div>
                        </template>
                        <template v-slot:item.main_balance="{ item }">
                            <div class="a-text"> {{item.main_balance}}</div>
                        </template>
                                               
                    </v-data-table>
                </div>
            </div>
        </div>
    
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
      
    <script>
    
    //import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
    
        name: "UtilityReport",
        components: {
           // quillEditor,
            PageLoader
        },
    
        data() {
            return {
    
                isLoading: false,
                isRightImage: false,
                isLeftImage: false,
                isBackImage: false,
                is_transaction_fee: true,
                is_transaction_fee_hidden: false,
                user_id: '',
                firstname: '',
                lastname: '',
                bank_name: '',
                account_name: '',
                account_number: '',
    
                loading: false,
                loading1: false,
                loading2: false,
                loading3: false,
                role_type: '',
                contract_title: '',
                transaction_type: '',
                delivery_type: '',
                delivery_location: '',
                delivery_time: '',
                contract_amount: '',
                transaction_fee: '',
                whois_paying: '',
                contract_rebate: '',
                product_description: '',
                accept_terms: '',
    
                products: [],
                subcategorys: [],
                selectedFile: '',
                selectedFile1: '',
                selectedFile2: '',
                selectedFile3: '',
                addProduct: false,
                viewProduct: true,
                editorOptions: {
                    placeholder: 'Write something...',
                },
    
                isVariations: false,
                productid: '',
                variations: '',
                vprice: '',
                is_fees_status: false,
                is_submit: false,
                is_submit1: false,
                is_submit2: false,
                is_submit3: false,
                img_url: process.env.VUE_APP_IMAGE_URL,
                header: [
                                   
                    {
                        value: 'business_name',
                        text: 'Business name'
                    },
                    {
                        value: 'available_balance',
                        text: 'Available balance'
                    },
                   
                    {
                        value: 'main_balance',
                        text: 'Main balance'
                    },
                               
                ],
    
                desserts: [],
                user_role: '',
                product_id: '',
                userid: '',
                utilityQty: '',
                
                utilityData: [],
                isPeriod: false,
                delivery_period: '',
                payment_status: '',
                is_escrow_charge: false,
                error: '',
                error1: '',
                duration_twelve: false,
                duration_twelve1: true,
                label1: '',
                isContractLink: false,
                contractLink: '',
                service_charge_fee: ''
            };
        },
    
        beforeMount() {
    
        },
    
        mounted() {
    
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
    
            this.bank_name = localStorage.getItem("bank_name");
            this.account_name = localStorage.getItem("account_name");
            this.account_number = localStorage.getItem("account_number");
            this.user_role = localStorage.getItem("user_role");
    
            this.fetchWalletBalance()
        },
    
        created() {
    
        },
    
        computed: {
    
               
        },
    
        methods: {
           
            async fetchWalletBalance() {
                try {
                    this.isLoading = true
                    this.desserts = []
                    const response = await this.$http.get('/zedlocker-wallet-balance', {
                    })
                    this.isLoading = false
                    
                    this.utilityData = response.data.data
                    this.utilityQty = this.utilityData.length
                        this.desserts.push({
                            business_name:  this.utilityData.business_name,
                            available_balance:  this.utilityData.available_balance,
                            main_balance:  this.utilityData.main_balance
                        })
    
                } catch (err) {
                    console.log(err);
                }
            },
               
        }
    
    }
    </script>
      
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
    .error {
        color: #fff;
      }
      .error1 {
        color: #fff;
      }
    .quill-container {
        height: 200px;
        /* Set the desired height here */
    }
    .copy-refid{
        cursor: pointer;
        font-size: 16px;
    }
    .copy-refid1{
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    }
    .a-text {
        font-size: 11px;
        font-family: Poppins;
        font-weight: 600;
    }
    .a-text1 {
        font-size: 14px;
        font-family: Poppins;
    }
    .process-payment{
        cursor:pointer;
    
    }
    label {
        font-size: 12px;
        font-family: Poppins;
        font-weight: normal;
    }
    
    .inputs {
        margin-top: 20px;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .price-fee {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        div {
            margin: 4px;
            width: 100%;
    
        }
    }
    
    .adjust-fee-input {
        // width: 100px;
        text-align: center;
    }
    
    .whopays-fee-input {
        //width: 100% !important;
    }
    
    .shop-detail {
        font-size: 12px;
        font-family: Poppins;
    }
    
    .btn-danger {
        text-transform: uppercase;
        font-size: 13px;
        font-family: Poppins;
        background: #2979FF;
        border: 0px;
        height: 45px;
    }
    
    .text-header {
        padding: 0.5rem;
        font-size: 14px;
        font-weight: bold;
        font-family: Poppins;
        background: #ccc;
    }
    
    .contract-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #2979FF;
        color: #fff;
        border-radius: 4px;
        padding: 0.8rem;
        font-size: 14px;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: 5px;
    }
    
    .welcome {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        color: #170e0fac;
    }
    
    .account-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        //padding: 0.9rem;
        background: #fff;
        width: 98%;
    }
    
    .account-balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #780712;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .wallet-detail {
        padding-top: 1rem;
        line-height: 1.4px;
        font-size: 15px;
    
        h4 {
            font-weight: 650;
        }
    
        .acct-d {
            line-height: 0.8;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    
    .wallet-icon {
        font-size: 60px
    }
    
    .fund-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #076378;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .inputs-field {
        font-size: 14px;
        font-family: Poppins;
        height: 45px;
    }
    
    .content-center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 1px #170e0fac;
        border-radius: 4px;
        padding: 0.5rem;
        margin: 4px;
    }
    
    .box1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .image-input {
        margin-top: 10px;
    }
    
    .box-icon {
        padding-left: 0.4rem;
        font-size: 35px;
        color: #637807;
    }
    
    .box-details {
        font-size: 14px;
        color: #170e0fac;
    }
    
    .box1:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box2:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box3:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box4:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box5:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box6:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    
    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;
    
        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 10px;
            text-transform: capitalize;
            color: #ff4d63;
        }
    
        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }
    
    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        color: rgb(237, 138, 108);
        margin-right: 10px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            ;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Poppins;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Poppins;
        }
    }
    
    .account-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .account-content-h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
    
        padding-top: 1.3rem;
        text-transform: uppercase;
        text-align: center;
        //text-decoration: underline;
    }
    
    .add-fund {
        font-size: 12px;
        text-decoration: none !important;
        text-transform: capitalize;
    }
    
    .account-content-d {
        padding-top: 1.3rem;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .btn-secondary {
        text-transform: uppercase;
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .shop-name {
        //font-weight: 550;
        color: #000;
        font-family: Poppins;
    }
    
    @media screen and (max-width: 768px) {
        .copy-refid1{
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }
        .inputs-field {
            font-size: 12px;
            font-family: Poppins;
            font-weight: normal;
            height: 45px;
        }
        .contract-header {
    
            font-size: 12px !important;
    
        }
    
        .counter {
            font-size: 13px;
            font-weight: 500 !important;
        }
    
        .welcome {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            color: #170e0fac;
        }
    
        .content-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0.9rem;
        }
    
        .image-input {
            margin-top: 20px;
        }
    
        .price-fee {
            display: flex;
            flex-direction: column;
    
            div {
                width: 100%;
            }
        }
        .margin-mobile{
            margin-top: 90px;;
        }
    
        .adjust-fee-input {
            width: 100%;
        }
    
        .account-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            width: 100%;
        }
    
        .account-balance {
            margin-bottom: 10px;
        }
    
        .box-details {
            padding: 0.4rem;
            line-height: 1.4px;
    
            .title {
                font-family: Poppins;
                font-weight: 550;
                font-size: 8px;
                text-transform: capitalize;
                color: #ff4d63;
            }
    
            .description {
                font-family: Poppins;
                font-size: 12px;
            }
        }
    
        .inputs {
            font-size: 12px;
        }
    
        .box1 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box2 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box3 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box4 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box5 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .continue {
            display: flex;
            flex-direction: row;
            justify-content: right;
            padding-right: 0.4rem;
            font-size: 30px;
            margin-top: -10px;
            color: rgb(237, 138, 108);
        }
    
        .modal-close-mobile {
            display: block;
        }
    
        .modal-close {
            display: none;
        }
    
        .account-content {
            margin: 0px;
            margin-right: 0px;
        }
    
    }
    </style>
    