<template>
    <div class="container-fluid">
        <p>&nbsp;</p>
        <div class="account-details">
            <div class="col-sm-12">

                <div class="contract-header">
                    <div> CONTRACT DETAILS </div>
                    <div> <i class="fa fa-arrow-left" @click="view_contracts" style="cursor:pointer;"> List
                            Contracts</i></div>
                </div>

                <div class="inputs price-fee">

                    <!-- <div class="user-role">
                        <h5 style="font-size:12px; color: #000">Role type:</h5>
                        <span v-if="role_type == 1"> Buyer</span>
                        <span v-else> Seller</span>
                    </div> -->
                    <v-autocomplete disabled v-model="role_type" label="Created By" :items="roleType"
                        variant="outlined"></v-autocomplete>
                    <v-autocomplete disabled v-model="transaction_type" label="Transaction Type"
                        :items="transactionType" variant="outlined"></v-autocomplete>
                </div>
                <div class="inputs">
                    <label for=""> Contract title </label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter contract title"
                        v-model="contract_title" disabled>
                </div>
                <div class="inputs product-desc">
                    <label for="" style="background: #fff; cursor: pointer;  font-size:12px" @click="show_description"> Contract description/expectations  <i class="fa fa-angle-down" id="angle-down"></i> <i class="fa fa-angle-up" id="angle-up" style="display: none"></i></label>
                    <br/><br/>
                    <div v-html="product_description" v-if="is_product_description">
                        
                    </div>
                </div>
                <div class="inputs price-fee margin-mobile">
                    <div>
                        <label for=""> Transaction amount</label>
                        <input type="text" class="form-control inputs-field" placeholder="Enter contract amount"
                            v-model="contract_amount" disabled @input="fetchContractFees(contract_amount)">
                    </div>
                    <div>
                        <label>Escrow service charge</label>
                        <input type="text" class="form-control inputs-field" v-model="transaction_fee" required
                        disabled>
                    </div>

                    <div>
                        <label>Fees handling(<span style="color: red">who bears the escrow fee?</span>)</label>
                       
                        <input type="text" class="form-control inputs-field" v-model="service_charge_fee" 
                        disabled>
                    </div>

                </div>
                <div class="inputs price-fee">
                    <div>
                        <label>Delivery type </label>
                        <select name="" id="" class="form-control inputs-field" v-model="delivery_type" disabled>
                            <option value="">-Select-</option>
                            <option value="1">Same day delivery(12hrs)</option>
                            <option value="2">Interstate</option>
                        </select>
                    </div>
                    <div>
                        <label>Delivery location </label>
                        <input type="text" class="form-control inputs-field" name="" id=""
                            v-model="delivery_location" disabled>
                    </div>
                    <div v-if="delivery_type==2">
                        <label>Delivery duration </label>
                        <select name="" id="" class="form-control inputs-field" v-model="delivery_time"
                            @change="selectDays()" disabled>
                            <option value="">-Select-</option>
                            <option value="day">{{ delivery_period}} Day</option>
                            <option value="week">{{ delivery_period}} Week</option>
                            <option value="month">{{ delivery_period}} Month</option>
                        </select>
                    </div>
                  
                </div>
                <div class="image-input">
                    <div class="inputs">
                        <table class="table table-responsive">
                            <thead>
                                <th colspan="4"></th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="(data, index) in contract_document" :key="index">
                                        <div class=" d-flex flex-column justify-center ">
                                            <img
                                            width="100"
                                            height="100"
                                            :src="img_url + 'contractDocuments/' + data"
                                            alt="Contract Document"
                                            style="border-radius: 4px; cursor: pointer"
                                            @click="previewImage(img_url + 'contractDocuments/' + data)"  
                                        >
                                        <button class="btn btn-default btn-sm" style="border-style:none">X</button>
                                        </div>
                                        &nbsp;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="inputs text-center" v-if="accept_contract == 1">
                        <h3 class="text-success">CONTRACT ACCEPTED</h3>
                        on the <i> <strong>{{ new Date(accepted_date).toLocaleString() }}</strong></i>
                        &nbsp;by <i> <strong>{{ accepted_by }}</strong></i>
                </div>
                <div class="inputs" v-if="userID != user_id && accept_contract == 1">
                    
                    <hr>
                </div>
                <div class="inputs" v-if="userID != user_id && accept_contract != 1">
                    <button id="submit2" class="btn btn-danger btn-block" @click="confirmContract()"
                        v-if="!loading">accept contract</button>
                    <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <hr>
                </div>

                <div class="comments-board">
                    <h5>Both parties discussions</h5>
                    <span class="comments-section" v-if="comments_list == ''">
                        <i class="fa fa-comments"></i> No discussions yet
                    </span>
                    <span v-else>
                    <div class="comments-section" v-for="(data, index) in comments_list" :key="index">
                        <div class="comments-user">
                            <i class="fa fa-user"></i> {{ data.fullname }}
                        </div>
                        <div class="comments">
                            <span class="ccomment"><i class="fa fa-comments"></i> {{ data.comments }}</span> 
                            <span class="date"><i class="fa fa-calendar"></i> {{ new Date(data.comment_date).toLocaleString() }}</span>
                        </div>
                    </div>
                    </span>
                </div>

                <div class="inputs">
                    <label for="" style="background: #162b4e15; font-size: 11px; padding: 0.4rem; border-radius: 4px;"> Make your reservations below if you're not satisfied with the contract terms above</label>
                    <div >
                        <textarea class="form-control" name="" id="" cols="10" rows="5" v-model="contract_comments" placeholder="Enter your comments on the contract terms here..."></textarea>
                    </div>
                </div>
                <div class="inputs" v-if="accept_contract != 1">
                    <button id="submit2" class="btn btn-info btn-block" @click="submitComment()"
                        v-if="!loading1">submit comment</button>
                    <button class="btn btn-info btn-block" type="button" disabled v-if="loading1">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <p>&nbsp;</p>
                </div>
                <div class="inputs" v-if="accept_contract == 1">
                    <button id="submit2" class="btn btn-info btn-block" disabled
                        v-if="!loading1">submit comment</button>
                    <button class="btn btn-info btn-block" type="button" disabled v-if="loading1">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <p>&nbsp;</p>
                </div>

            </div>
        </div>
        <div v-if="isApprove">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row account-content">
                                    <div class="account-content-d">
                                        <div class="inputs ">
                                            <center>
                                                <h4><strong>Accept Contract</strong></h4>
                                                <br>
                                                <span style="font-size: 14px; font-family: Poppins">Do you really want to accept this contract?</span>
                                            </center>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                    <div class=" d-flex flex-row justify-content-center">
                                        <button id="submit2" class="btn btn-info" @click="acceptContract()"
                                            v-if="!loading2">Yes</button> &nbsp;&nbsp;
                                        <button class="btn btn-info" type="button" disabled v-if="loading2">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button> &nbsp;&nbsp;
                                        <button id="submit2" class="btn btn-secondary" @click="isApprove = false">No</button>
                                    </div>
                                    
                                    <!-- <button class="btn btn-secondary" type="button" disabled >
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button> -->
                                    <!-- <button @click="isBackImage = false"><i class="fa fa-times"></i> Close</button> -->
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-1">
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div v-if="isViewImage">
            <transition name="modal">
                <div class="modal-mask1">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row account-content">
                                    <div class="account-content-d">
                                     
                                    </div>
                                    <button @click="isViewImage = false">X</button>
                                    <p>&nbsp;</p>
                                    <div class=" d-flex flex-row justify-content-center">
                                        
                                        <div class="card">
                                            <div class="card-body">
                                                <img 
                                                :src="image_url" alt=""
                                                style="border-radius: 4px; width: 100%;"
                                            >
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-1">
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>


        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>


<script>
import 'quill/dist/quill.snow.css';
//import {
    //quillEditor
//} from 'vue-quill-editor';
import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {

    name: "ProductPage",
    components: {
       // quillEditor,
        PageLoader
    },

    data() {
        return {

            isLoading: false,
            isRightImage: false,
            isLeftImage: false,
            isBackImage: false,
            isApprove: false,
            isViewImage: false,

            user_id: '',
            firstname: '',
            lastname: '',
            bank_name: '',
            account_name: '',
            account_number: '',

            loading: false,
            loading1: false,
            loading2: false,
            loading3: false,
            role_type: '',
            contract_title: '',
            transaction_type: '',
            delivery_type: '',
            delivery_location: '',
            delivery_time: '',
            contract_amount: '',
            transaction_fee: '',
            whois_paying: '',
            contract_rebate: '',
            product_description: '',
            accept_terms: '',

            contracts: [],
            subcategorys: [],
            selectedFile: '',
            selectedFile1: '',
            selectedFile2: '',
            selectedFile3: '',
            addProduct: false,
            viewProduct: true,
            editorOptions: {
                placeholder: 'Write something...',
            },

            isVariations: false,
            productid: '',
            variations: '',
            vprice: '',
            is_fees_status: false,
            is_submit: false,
            is_submit1: false,
            is_submit2: false,
            is_submit3: false,
            //img_url: "http://localhost:3000/assets/uploads/",
            img_url: process.env.VUE_APP_IMAGE_URL,

            desserts: [],
            user_role: '',
            product_id: '',
            userid: '',
            contractQty: '',
            deliveryType: [{
                text: 'Same day delivery (14 hours max)',
                value: 'Same day delivery (14 hours max)'
            },
            {
                text: 'Interstate',
                value: 'Interstate'
            },
            ],

            transactionType: [{
                text: 'Physical Product',
                value: 'Physical Product'
            },
            {
                text: 'Services',
                value: 'Services'
            },
            {
                text: 'Digital Product',
                value: 'Digital Product'
            },
            {
                text: 'Mutual Contract',
                value: 'Mutual Contract'
            },
            ],
            roleType: [{
                text: 'Seller',
                value: 1
            },
            {
                text: 'Buyer',
                value: 2
            },
            ],

            contractsData: [],
            isPeriod: false,
            delivery_period: '',
            contract_id: '',
            contract_number: '',
            contract_document: '',
            contract_comments: '',
            comments_list: [],
            userID: '',
            accept_contract: '',
            accepted_date: '',
            is_product_description: false,
            service_charge_fee: '',
            is_payment: '',
            ccontract_id: '',
            accepted_by: '',
            image_url: '',

        };
    },

    beforeMount() {

    },

    mounted() {

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");

        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.user_role = localStorage.getItem("user_role");
        this.fetchContractById(this.$route.params.id)
        this.fetchContractCommentById(this.$route.params.id)
        this.ccontract_id = this.$route.params.id

    },

    created() {

    },

    computed: {


        indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
        },

    },

    methods: {

        previewImage(data){
            this.image_url = data
            this.isViewImage = true
        },
        processPayment(contract_id){
            this.$router.push('/checkout/summary/'+contract_id)
        },
        show_description(){
            if (this.is_product_description == false) {
                this.is_product_description = true
                document.getElementById('angle-down').style.display = 'none'
                document.getElementById('angle-up').style.display = 'inline'
            }else{
                this.is_product_description = false
                document.getElementById('angle-up').style.display = 'none'
                document.getElementById('angle-down').style.display = 'inline'
            }
        },
        async fetchContractCommentById(contract_id) {
            try {

                this.isLoading = true
                const response = await this.$http.post('/fetch-contract-comment-byid', {
                    contract_id: contract_id
                })
                this.isLoading = false
                this.comments_list = response.data.data

            } catch (err) {
                console.log(err);
            }
        },

        async fetchContractById(contract_id) {
            try {

                this.isLoading = true
                const response = await this.$http.post('/fetch-contract-byid', {
                    contract_id: contract_id
                })
                this.isLoading = false
                this.contracts = response.data.data[0]
                this.userID = this.contracts.user_id
                this.role_type = this.contracts.role_type,
                this.transaction_type = this.contracts.transaction_type
                this.contract_title = this.contracts.contract_title
                this.product_description = this.contracts.contract_description
                this.contract_amount = this.formatToCurrency(this.contracts.amount)
                this.transaction_fee = this.contracts.transaction_fee
                this.contract_rebate = this.formatToCurrency(this.contracts.rebate_fee)
                this.whois_paying = this.contracts.whois_paying
                this.delivery_type = this.contracts.delivery_type
                this.delivery_location = this.contracts.delivery_location
                this.delivery_time = this.contracts.delivery_time
                this.delivery_period = this.contracts.delivery_period
                this.accept_terms = this.contracts.terms_conditions
                this.contract_id = this.contracts.contractid
                this.contract_document = JSON.parse(this.contracts.documents)
                this.contract_number = this.contracts.contract_number
                this.accept_contract = this.contracts.accept_contract
                this.is_payment = this.contracts.is_payment
                this.accepted_date = this.contracts.accepted_date
                this.accepted_by = this.contracts.accepted_fullname

                if (this.whois_paying == 'buyer') {
                    this.service_charge_fee = 'Buyer will pay ' + this.formatToCurrency(this.transaction_fee) + ' of the service charge'
                }
                else if(this.whois_paying == 'seller'){
                    this.service_charge_fee = 'Seller will pay ' + this.formatToCurrency(this.transaction_fee) + ' of the service charge'
                }
                else if(this.whois_paying == 'both'){
                    this.service_charge_fee = '50/50 (Seller pays ' + this.formatToCurrency(this.transaction_fee / 2) + ', ' + 'Buyer pays ' + this.formatToCurrency(this.transaction_fee / 2) +')'
                }

                if (this.contracts.delivery_time) {
                    this.isPeriod = true
                }

            } catch (err) {
                console.log(err);
            }
        },

        confirmContract(){
            this.isApprove = true
        },

        async acceptContract() {
            try {
                    this.loading2 = true

                    const response = await this.$http.post('/accept-contract', {
                        contract_id: this.contract_id,
                        user_id: this.user_id,
                        fullname: this.firstname +' ' +this.lastname,
                        email: this.email_address,
                        contract_number: this.contract_number
                    })

                    this.loading2 = false

                    if (!response.data.success) {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
                        return;
                    } else {
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
                        
                        this.view_contracts()
                        return;
    
                    }

            } catch (err) {
                console.log(err);
            }
        },

        selectDays() {
            if (this.delivery_time == '') {
                this.isPeriod = false
            } else {
                this.isPeriod = true
            }
        },

        formatToCurrency(value) {
            return value.toLocaleString('en-NG', { minimumFractionDigits: 2 })
        },

        async fetchContractFees(amount) {
            try {

                this.is_fees_status = true
                const response = await this.$http.post('/fetch-fees', {
                    contract_amount: amount,
                })

                this.contract_amount = this.formatToCurrency(amount);
                this.transaction_fee = this.contract_amount * (response.data.fees[0].per / 100)
                this.contract_rebate = this.contract_amount * (0.5 / 100)

                this.is_fees_status = false

            } catch (err) {
                console.log(err);
            }
        },

        copyToClipboard(link) {
            // var copyText = document.getElementById("myInput");
            navigator.clipboard.writeText(link).then(function () {
                alert("Contract link copied to clipboard!");
            }).catch(function (err) {
                console.error('Failed to copy: ', err);
            });
        },

        pickFile() {
            this.selectedFile = this.$refs.fileInput.files[0];
        },

        view_contracts() {
            this.$router.push('/contract')
        },

        async submitComment() {
            try {

                if (!this.contract_comments) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: "Please provide your comment",
                    })
                    return;
                }
                this.loading1 = true
                const response = await this.$http.post('/submit-comments', {
                    contract_id: this.contract_id,
                    contract_number: this.contract_number,
                    contract_comments: this.contract_comments,
                    user_id: this.user_id,
                    role_type: this.role_type
                })
                this.loading1 = false

                if (!response.data.success) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    this.contract_comments = ""
                    this.fetchContractCommentById(this.contract_id)
                    return;
                }


            } catch (err) {
                console.log(err);
            }
        },

    }

}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

.card-body{
    overflow-y: auto;
    height: 450px;
    width: 100%;
}
.quill-container {
    height: 200px;
    /* Set the desired height here */
}
.comments-board{
    text-transform: normal;
    font-size: 13px;
    font-family: Poppins;
    background: #162b4e15;
   // opacity: 0.6;
    padding: 0.5rem;
    border-radius: 4px;
}
.comments-section{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    text-transform: normal;
    font-size: 12px;
    font-family: Poppins;
    color: #000000b1;
    background: #2978ff24;
    padding: 0.3rem;
    border-radius: 4px;
    border-bottom: solid 1px #ccc;

    .comments-user{
        background: #fff; 
        color: #2979FF;
        padding: 0.6rem;
        font-size: 12px;
        border-radius: 4px;
    }
    .comments{
        display: flex;
        flex-direction: column;
        padding-left: 0.6rem;
    }
    .comments-user{
        font-weight: 500;
    }
    .ccomment{
        font-size: 12px;
        font-weight: 400;
    }
    .date{
        font-style: italic;
        font-size: 10px;
    }
}

.copy-refid {
    cursor: pointer;
    font-size: 16px;
}

.a-text {
    font-size: 11px;
    font-family: Poppins;
}

label {
    font-size: 12px;
    font-weight: bold;
    font-family: Poppins;
}

.inputs {
    margin-top: 10px;
    border-radius: 3px;
    font-size: 12px;
}


.price-fee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
        margin: 4px;
        width: 100%;

    }
}
.user-role{
    font-size: 24px;
    color: #ccc;
}

.adjust-fee-input {
    // width: 100px;
    text-align: center;
}

.whopays-fee-input {
    //width: 100% !important;
}

.shop-detail {
    font-size: 12px;
    font-family: Poppins;
}

.btn-danger {
    text-transform: uppercase;
    font-size: 13px;
    font-family: Poppins;
    background: #2979FF;
    border: 0px;
    height: 45px;
}
.btn-info {
    text-transform: uppercase;
    font-size: 13px;
    font-family: Poppins;
    border: 0px;
    height: 45px;
}

.text-header {
    padding: 0.5rem;
    font-size: 12px;
    font-family: Poppins;
    background: #ccc;
}

.contract-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2979FF;
    color: #fff;
    border-radius: 4px;
    padding: 0.8rem;
    font-size: 14px;
    font-weight: 700;
    font-family: Poppins;
    margin-bottom: 5px;
}

.welcome {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    color: #170e0fac;
}

.account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    //padding: 0.9rem;
    background: #fff;
    width: 98%;
}

.account-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Poppins;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wallet-detail {
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;

    h4 {
        font-weight: 650;
    }

    .acct-d {
        line-height: 0.8;
        cursor: pointer;
        text-decoration: underline;
    }
}

.wallet-icon {
    font-size: 60px
}

.fund-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Poppins;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.inputs-field {
    font-size: 12px;
    font-family: Poppins;
    font-weight: 450;
    height: 45px;
    background: #fff;
    border: solid 0px;
}

.content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
}

.box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.image-input {
    margin-top: 10px;
}

.box-icon {
    padding-left: 0.4rem;
    font-size: 35px;
    color: #637807;
}

.box-details {
    font-size: 14px;
    color: #170e0fac;
}

.box1:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box2:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box3:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box4:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box5:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box6:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.img {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.box-details {
    padding: 0.4rem;
    line-height: 1.4px;

    .title {
        font-family: Poppins;
        font-weight: 550;
        font-size: 10px;
        text-transform: capitalize;
        color: #ff4d63;
    }

    .description {
        font-family: Poppins;
        font-size: 12px;
    }
}

.continue {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 90%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
}
.modal-mask1 {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
}

.account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
}

.add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
}

.account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
}

.btn-secondary {
    text-transform: uppercase;
}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

a {
    text-decoration: none;
}

.shop-name {
    //font-weight: 550;
    color: #000;
    font-family: Poppins;
}

@media screen and (max-width: 768px) {
    .modal-mask1 {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 90%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            ;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Poppins;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Poppins;
        }
    }
    .inputs-field {
        font-size: 12px;
        font-family: Poppins;
        font-weight: normal;
        height: 45px;
        background: #fff;
        border: solid 0px;
    }
    .contract-header {

        font-size: 12px !important;

    }

    .counter {
        font-size: 13px;
        font-weight: 500 !important;
    }

    .welcome {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: #170e0fac;
    }

    .comments-section{
        display: flex;
        flex-direction: column;
        text-transform: normal;
        font-size: 12px;
        font-family: Poppins;
        color: #000000b1;
        padding: 0.3rem;
        border-radius: 4px;
        border-bottom: solid 1px #ccc;
    
        .comments-user{
            background: #fff; 
            color: #2979FF;
            padding: 0.3rem;
            font-size: 10px;
            border-radius: 4px;
            width: 100%;
        }
        .comments{
            width: 100%;
        }
        .comments-user{
            font-weight: 500;
        }
        .ccomment{
            font-size: 10px;
            font-weight: 400;
            text-align: left;
        }
        .date{
            font-style: italic;
            font-size: 10px;
            text-align: left;
        }
    }

    .content-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.9rem;
    }

    .image-input {
        margin-top: 10px;
    }

    .price-fee {
        display: flex;
        flex-direction: column;

        div {
            width: 100%;
        }
    }

    .product-desc{
        margin-top: 20px;
    }

    .margin-mobile {
       margin-top: -20px;
    }

    .adjust-fee-input {
        width: 100%;
    }

    .account-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 100%;
    }

    .account-balance {
        margin-bottom: 10px;
    }

    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;

        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 8px;
            text-transform: capitalize;
            color: #ff4d63;
        }

        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }

    .inputs {
        font-size: 12px;
    }

    .box1 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box2 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box3 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box4 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box5 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        margin-top: -10px;
        color: rgb(237, 138, 108);
    }

    .modal-close-mobile {
        display: block;
    }

    .modal-close {
        display: none;
    }

    .account-content {
        margin: 0px;
        margin-right: 0px;
    }

}
</style>