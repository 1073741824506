<template>
<div class="container-fluid">
    <br>
    <p class="text-header">CONTRACT</p>
    <div class="account-details" v-if="addProduct">
        <div class="col-sm-12">
            <div class="contract-header">
                <div>CONTRACT TERMS AND AGREEMENT </div>
                <div> <i class="fa fa-eye" @click="view_products" style="cursor:pointer;"> List Escrows</i></div>
            </div>
            <form @submit.prevent="createBrand" enctype="multipart/form-data">

                <div class="inputs price-fee">
                    <v-autocomplete required v-model="role_type" label="Whats is your role *" :items="roleType" variant="outlined"></v-autocomplete>
                    <v-autocomplete required v-model="transaction_type" label="Transaction Type *" :items="transactionType" variant="outlined"></v-autocomplete>
                </div>
                <div class="inputs">
                    <label for=""> Contract title *</label>
                    <input type="text" class="form-control inputs-field" placeholder="What's the name of product or service" v-model="contract_title" required>
                </div>
                <div class="inputs">
                    <label for=""> Contract description/expectations *</label>
                    <quill-editor class="quill-container" v-model="product_description" :options="editorOptions"></quill-editor>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div class="inputs price-fee margin-mobile">
                    <div>
                        <label for=""> Transaction amount *</label>
                        <input type="text" class="form-control inputs-field" placeholder="Enter contract amount" v-model="contract_amount" required @blur="fetchContractFees(contract_amount)">
                        <span v-if="error1" class="error">{{ error1 }}</span>
                    </div>
                    <div>
                        <label>Fees handling (<span style="color: red">who bears the escrow fee?</span>)*</label>
                        <select name="" id="" class="form-control inputs-field whopays-fee-input" v-model="whois_paying" @change="fee_handling">
                            <option value="">-Select-</option>
                            <option value="buyer">Buyer 100%</option>
                            <option value="seller">Seller 100%</option>
                            <option value="both">50/50 split</option>
                        </select>
                    </div>
                    <div v-if="is_escrow_charge">
                        <label>Escrow service charge</label>
                        <input type="text" class="form-control inputs-field" v-model="transaction_fee" required readonly>
                        <span class="text-danger" style="font-size: 11px; font-style: italic">{{service_charge_fee}}</span>
                    </div>

                </div>

                <div class="inputs price-fee">
                    <div>
                        <label>Delivery type *</label>
                        <select name="" id="" class="form-control inputs-field" v-model="delivery_type" @change="select_durations()">
                            <option value="">-Select-</option>
                            <option value="1">Same day delivery(12hrs)</option>
                            <option value="2">Interstate</option>
                        </select>
                    </div>
                    <div>
                        <label>Delivery location *</label>
                        <input type="text" class="form-control inputs-field" name="" id="" v-model="delivery_location">
                    </div>
                    <div>
                        <label>Delivery duration {{label1}}*</label>
                        <input type="text" class="form-control inputs-field" name="" id="" v-model="delivery_time" v-if="duration_twelve" readonly>
                        <select name="" id="" class="form-control inputs-field" v-model="delivery_time" @change="selectDays()" v-if="duration_twelve1">
                            <option value="">-Select-</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                        </select>
                    </div>
                    <div v-if="isPeriod">
                        <label>Enter period *</label>
                        <input type="text" class="form-control inputs-field" name="" id="" @input="validateNumber()" v-model="delivery_period" min="0" step="1" >
                        <span v-if="error" class="error">{{ error }}</span>
                    </div>
                </div>
              
                <div class="image-input">
                    <div class="inputs">
                        <label for=""><i class="fa fa-image"></i> Media upload(product pictures) *</label>
                        <input type="file" ref="fileInput" id="image_input" class="file-input" accept="image/*" multiple  @change="pickFile" />
                    </div>
                </div>

                <div class="inputs">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" value="" v-model="accept_terms"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I accept the terms and conditions of this contract
                    </div>
                </div>

            </form>

            <div class="inputs">
                <button id="submit2" class="btn btn-danger btn-block" :disabled="isProductEnabled" @click="createContract()" v-if="!loading">create and submit contract</button>
                <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                <p>&nbsp;</p>
            </div>
        </div>
    </div>
    <div class="account-details" v-if="viewProduct">
        <div class="col-sm-12 table table-responsive">
            <div class="contract-header">
                <div>My Escrows <span class="badge badge-primary">{{ contractQty }}</span></div>
                <div> <i class="fa fa-plus-square" @click="add_products" style="cursor:pointer;font-weight: bold"> Create New Escrow</i></div>
            </div>

            <div class="table table-responsive table-sm">
                <v-data-table :headers="header" :items="desserts" dense class="table-striped table-bordered table-responsive">
                    <template v-slot:item.contract_number="{ item }">
                        <div class="a-text">{{ item.contract_number }}</div>
                    </template>
                    <template v-slot:item.contracttitle="{ item }">
                        <div class="a-text">{{ item.contracttitle }}</div>
                    </template>
                    <template v-slot:item.roletype="{ item }">
                        <div class="a-text" v-if="item.roletype == 1">{{ 'Seller' }}</div>
                        <div class="a-text" v-if="item.roletype == 2">{{ 'Buyer' }}</div>
                    </template>
                    <template v-slot:item.amount="{ item }">
                        <div class="a-text">&#8358;{{ item.amount }}</div>
                    </template>
                    <template v-slot:item.fees="{ item }">
                        <div class="a-text">&#8358;{{ item.fees }}</div>
                    </template>
                    <template v-slot:item.whois_paying="{ item }">
                        <div class="a-text">{{ item.whois_paying }}</div>
                    </template>
                    <template v-slot:item.link="{ item }">
                        <span v-if="user_id == item.userid">
                            <div class="a-text">Copy contract link </div>
                            <span class="copy-refid" data-toggle="tooltip" data-placement="top" :title="item.link" @click="copyToClipboard(item.link)">
                              <i class="fa fa-copy"></i>
                            </span>
                        </span>
                        <span class="a-text" v-else>-</span>
                    </template>
                    <template v-slot:item.accept_status="{ item }">
                        <div class="a-text text-success" v-if="item.accept_status == 1"><i class="fa fa-check"></i> Accepted</div>
                        <div class="a-text text-warning" v-else> 
                            <span class=" text-warning process-payment" v-if="item.cis_accept == 0"> Pending</span>
                            <button style="cursor: pointer" class="btn btn-info btn-xs" v-else-if="item.cis_accept == 1"  @click="viewContract(item.contract_id)"> <i class="fa fa-arrow-up"></i> Accept contract</button>
                        </div>
                    </template>
                    <template v-slot:item.accepted_fullname="{ item }">
                        <div class="a-text text-secondary"> {{item.accepted_fullname}}</div>
                    </template>
                    <template v-slot:item.payment_status="{ item }">
                        <div class="a-text text-success" v-if="item.payment_status == 1"> Paid</div>
                        <div class="a-text text-warning" v-else>
                            <span  class="process-payment btn btn-secondary btn-xs" v-if="item.is_payment == 1 && item.accept_status == 1" @click="processPayment(item.contract_id)"><i class="fa fa-money"></i> Process payment </span>
                            <span class="text-warning" v-else> Pending </span>
                        </div>
                    </template>
                    <template v-slot:item.delivery_status="{ item }">
                        <div class="a-text text-success" v-if="item.delivery_status == 1"> Delivered</div>
                        <div class="a-text text-warning" v-else>
                             Pending
                        </div>
                    </template>
                    <template v-slot:item.contract_id="{ item }">
                        <button style="font-size: 10px" @click="viewContract(item.contract_id)" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fa fa-folder-open" style="color: blue" aria-hidden="true"></i> View details</button>
                        <div v-if="item.accept_status != 1">
                            &nbsp;<button style="font-size: 10px" @click="editContract(item.contract_id)" v-if="item.userid == user_id" data-toggle="tooltip" data-placement="top" title="Edit Contract"><i class="fa fa-edit"></i> Edit</button>
                        </div>
                        <div v-if="item.accept_status == 1">
                            &nbsp;<button style="font-size: 10px" @click="editContract1()" v-if="item.userid == user_id" data-toggle="tooltip" data-placement="top" title="Edit Contract"><i class="fa fa-edit"></i> Edit</button>
                        </div>
                        
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>

    <div v-if="isVariations">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                    Add variations
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                    <input type="hidden" class="form-control inputs-field" v-model="productid" required>
                                    <label for="">Variation </label>
                                    <input type="text" id="variation" class="form-control inputs-field" placeholder="Enter variations" v-model="variations" required>
                                    <label for="">Price</label>
                                    <input type="text" id="price" class="form-control inputs-field" placeholder="Enter price" v-model="vprice">
                                </div>
                                <button id="submit2" class="btn btn-secondary" @click="createVariation()" v-if="!is_submit">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button class="btn btn-default" @click="isVariations = false"><i class="fa fa-long-arrow-left"></i> back</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div v-if="isRightImage">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                    Add Image Right View
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                    <div class="inputs">
                                        <label for="">Browse image *</label>
                                        <input type="file" ref="fileInput1" id="image_input1" class="file-input" @change="pickFile1" />
                                        <input type="hidden" placeholder="" v-model="product_id">
                                    </div>
                                </div>
                                <button id="submit2" class="btn btn-secondary" @click="submit1()" v-if="!is_submit1">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit1">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button @click="isRightImage = false"><i class="fa fa-times"></i> Close</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div v-if="isLeftImage">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                    Add Image Left View
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                    <div class="inputs">
                                        <label for="">Browse image *</label>
                                        <input type="file" ref="fileInput2" id="image_input2" class="file-input" @change="pickFile2" />
                                        <input type="hidden" placeholder="" v-model="product_id">
                                    </div>
                                </div>
                                <button id="submit2" class="btn btn-secondary" @click="submit2()" v-if="!is_submit2">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit2">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button @click="isLeftImage = false"><i class="fa fa-times"></i> Close</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div v-if="isBackImage">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                    Add Image Back View
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                    <div class="inputs">
                                        <label for="">Browse image *</label>
                                        <input type="file" ref="fileInput3" id="image_input3" class="file-input" @change="pickFile3" />
                                        <input type="hidden" placeholder="" v-model="product_id">
                                    </div>
                                </div>
                                <button id="submit2" class="btn btn-secondary" @click="submit3()" v-if="!is_submit3">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit3">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button @click="isBackImage = false"><i class="fa fa-times"></i> Close</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div v-if="isContractLink">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-0">
                        </div>
                        <div class="col-md-12">
                            <div class="row account-content">
                                <div class="account-content-d">
                                    <div class="inputs ">
                                        <center>
                                        <p style="font-size: 13px"><strong>Escrow successfully created</strong></p>
                                        <span>Escrow link generated! Please copy and share with the other party</span>
                                        <!-- <div class="a-text1">{{ contractLink }} </div> -->
                                        <div class="a-text1">
                                        <button class="btn btn-default copy-refid1" data-toggle="tooltip" data-placement="top" title="" @click="copyToClipboard1(contractLink)">
                                            <i class="fa fa-copy"></i> Copy Link
                                        </button>
                                        </div>
                                        </center>
                                    </div>
                                </div>
                                <button id="submit2" class="font-weight-bold" @click="copied()">X</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-0">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <p>&nbsp;</p>
    <page-loader :is-loading="isLoading"></page-loader>
</div>
</template>

  
<script>
import 'quill/dist/quill.snow.css';
import {
    quillEditor
} from 'vue-quill-editor';

import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {

    name: "ProductPage",
    components: {
        quillEditor,
        PageLoader
    },

    data() {
        return {

            isLoading: false,
            isRightImage: false,
            isLeftImage: false,
            isBackImage: false,
            is_transaction_fee: true,
            is_transaction_fee_hidden: false,
            user_id: '',
            firstname: '',
            lastname: '',
            bank_name: '',
            account_name: '',
            account_number: '',

            loading: false,
            loading1: false,
            loading2: false,
            loading3: false,
            role_type: '',
            contract_title: '',
            transaction_type: '',
            delivery_type: '',
            delivery_location: '',
            delivery_time: '',
            contract_amount: '',
            transaction_fee: '',
            whois_paying: '',
            contract_rebate: '',
            product_description: '',
            accept_terms: '',

            products: [],
            subcategorys: [],
            selectedFile: '',
            selectedFile1: '',
            selectedFile2: '',
            selectedFile3: '',
            addProduct: false,
            viewProduct: true,
            editorOptions: {
                placeholder: 'Write something...',
            },

            isVariations: false,
            productid: '',
            variations: '',
            vprice: '',
            is_fees_status: false,
            is_submit: false,
            is_submit1: false,
            is_submit2: false,
            is_submit3: false,
            img_url: process.env.VUE_APP_IMAGE_URL,
            header: [
                // {
                //     align: 'start',
                //     value: 'contract_number',
                //     sortable: false,
                //     text: 'Contract id',
                // },
                {
                    value: 'contracttitle',
                    text: 'Contract title'
                },
                {
                    value: 'roletype',
                    text: 'Role type'
                },
                {
                    value: 'amount',
                    text: 'Amount'
                },
                // {
                //     value: 'fees',
                //     text: 'Escrow service charge'
                // },
                // {
                //     value: 'whois_paying',
                //     text: 'Who is paying for escrow charge'
                // },
                
                {
                    value: 'link',
                    text: 'Link'
                },
                {
                    value: 'accept_status',
                    text: 'Status'
                },
                // {
                //     value: 'accepted_fullname',
                //     text: 'Accepted By',
                //     visible: false,
                // },
                {
                    value: 'payment_status',
                    text: 'Payment'
                },
                {
                    value: 'delivery_status',
                    text: 'Delivery'
                },
                {
                    value: 'contract_id',
                    text: 'Action'
                },
            ],

            desserts: [],
            user_role: '',
            product_id: '',
            userid: '',
            contractQty: '',
            deliveryType: [{
                    text: 'Same day delivery (14 hours max)',
                    value: 'Same day delivery (14 hours max)'
                },
                {
                    text: 'Interstate',
                    value: 'Interstate'
                },
            ],

            transactionType: [{
                    text: 'Physical Product',
                    value: 'Physical Product'
                },
                {
                    text: 'Services',
                    value: 'Services'
                },
                {
                    text: 'Digital Product',
                    value: 'Digital Product'
                },
                {
                    text: 'Mutual Contract',
                    value: 'Mutual Contract'
                },
            ],
            roleType: [{
                    text: 'I am the seller',
                    value: '1'
                },
                {
                    text: 'I am the buyer',
                    value: '2'
                },
            ],

            contractsData: [],
            isPeriod: false,
            delivery_period: '',
            payment_status: '',
            is_escrow_charge: false,
            error: '',
            error1: '',
            duration_twelve: false,
            duration_twelve1: true,
            label1: '',
            isContractLink: false,
            contractLink: '',
            service_charge_fee: ''
        };
    },

    beforeMount() {

    },

    mounted() {

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");

        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.user_role = localStorage.getItem("user_role");

        this.fetchContractByUser()
        //this.isContractLink = true
    },

    created() {

    },

    computed: {

        isProductEnabled() {
            return (
                this.role_type === '' ||
                this.contract_title === '' ||
                this.delivery_type === '' ||
                this.delivery_time === '' ||
                this.contract_amount === '' ||
                this.product_description === '' ||
                this.whois_paying === '' ||
                this.accept_terms === '' ||
                this.delivery_location === ''
            );
        },

        indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
        },

    },

    methods: {
        validateNumber() {
        const value = this.delivery_period;
        if (value < 0) {
          this.error = 'Value must be non-negative';
          this.delivery_period = ''
          return
        } else if (!Number.isInteger(Number(value))) {
          this.error = 'Value must be an integer';
          this.delivery_period = ''
          return
        } else {
          this.error = '';
        }
      },
        fee_handling(){
            if (!this.transaction_fee) {
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please provide transaction amount',
                })
                this.whois_paying = ''
                return;
            }
            this.is_escrow_charge = true
            if (this.whois_paying == 'buyer') {
                    this.service_charge_fee = 'Buyer will pay ' + this.formatToCurrency(this.transaction_fee) + ' of the service charge'
            }
            else if(this.whois_paying == 'seller'){
                this.service_charge_fee = 'Seller will pay ' + this.formatToCurrency(this.transaction_fee) + ' of the service charge'
            }
            else if(this.whois_paying == 'both'){
                this.service_charge_fee = '50/50 (Seller pays ' + this.formatToCurrency(this.transaction_fee / 2) + ', ' + 'Buyer pays ' + this.formatToCurrency(this.transaction_fee / 2) +')'
            }

        },
        processPayment(contract_id){
            this.$router.push('/checkout/summary/'+contract_id)
        },
        selectDays(){
            if (this.delivery_time == '') {
                this.isPeriod = false
            }else{
                this.isPeriod = true
            }
        },
        select_durations(){
            if (this.delivery_type == 1) {
                this.duration_twelve = true
                this.duration_twelve1 = false
                this.isPeriod = false
                this.delivery_time = 12
                this.label1 = '(hrs)'
            }else if(this.delivery_type == 2){
                this.duration_twelve1 = true
                this.duration_twelve = false
                this.delivery_time = ''
                this.label1 = ''
            }
        },
        viewContract(contract_id) {
            this.$router.push('/view-contract/' + contract_id)
        },
        editContract(contract_id) {
            this.$router.push('/edit-contract/' + contract_id)
        },
        editContract1() {
            let msg = confirm("You cannot edit contract once accepted! Please generate new escrow")
            if (msg == true) {
                return;
            }
        },
        async upload1(contract_id) {
            this.product_id = contract_id
            this.isRightImage = true
        },

        async upload2(contract_id) {
            this.product_id = contract_id
            this.isLeftImage = true
        },

        async upload3(contract_id) {
            this.product_id = contract_id
            this.isBackImage = true
        },

        async submit1() {
            try {

                this.loading1 = true

                const formData = new FormData();
                formData.append('images', this.selectedFile1),
                    formData.append('product_id', this.product_id),

                    this.response = await this.$http.post('/upload-product1', formData

                    );
                this.loading1 = false

                if (!this.response.data.success) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })
                    return;
                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })
                    this.isRightImage = false
                    this.fetchMyProducts(this.user_id)
                    return;
                }
            } catch (err) {
                console.log(err);
            }
        },

        async submit2() {
            try {

                this.loading2 = true

                const formData = new FormData();
                formData.append('images', this.selectedFile2),
                    formData.append('product_id', this.product_id),

                    this.response = await this.$http.post('/upload-product2', formData

                    );
                this.loading2 = false

                if (!this.response.data.success) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })

                    return;
                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })
                    this.isLeftImage = false
                    this.fetchMyProducts(this.user_id)
                    return;
                }
            } catch (err) {
                console.log(err);
            }
        },

        async submit3() {
            try {

                this.loading3 = true

                const formData = new FormData();
                formData.append('images', this.selectedFile3),
                    formData.append('product_id', this.product_id),

                    this.response = await this.$http.post('/upload-product3', formData

                    );
                this.loading3 = false

                if (!this.response.data.success) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })

                    return;
                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })
                    this.isBackImage = false
                    this.fetchMyProducts(this.user_id)
                    return;
                }
            } catch (err) {
                console.log(err);
            }
        },

        addVariations(productid) {
            this.productid = productid
            this.isVariations = true
        },

        async fetchcategorys() {
            try {

                const response = await this.$http.get('/fetch-all-categories', {})
                response.data.categories.forEach(el => {
                    this.categorys.push({
                        text: el.category_name,
                        value: el.categoryid
                    })
                });

            } catch (err) {
                console.log(err);
            }
        },

        formatToCurrency(value) {
          return value.toLocaleString('en-NG', { minimumFractionDigits: 2 })
        },

        async fetchContractFees(amount) {
            try {
                
                // Convert the input value to a number
                const value = Number(this.contract_amount);

                // Check if the value is non-negative
                if (value < 0) {
                    this.error1 = 'Value must be non-negative';
                    this.contract_amount = '';
                    return;
                } 

                // Check if the value is an integer
                if (!Number.isInteger(value)) {
                    this.error1 = 'Value must be an integer';
                    this.contract_amount = '';
                    return;
                } 

                // Clear the error if the input is valid
                this.error1 = '';

                // Check if the value is less than 3000
                if (value < 3000) {
                    this.error1 = 'Transaction amount must be 3000 and above';
                    this.contract_amount = '';
                    return;
                }

                // If all checks pass, proceed with the API call

                this.is_fees_status = true
                const response = await this.$http.post('/fetch-fees', {
                    contract_amount: amount,
                })

                this.contract_amount = this.formatToCurrency(amount);
                this.transaction_fee = this.contract_amount * (response.data.fees[0].per / 100)
                this.contract_rebate = 0
               
                this.is_fees_status = false

            } catch (err) {
                console.log(err);
            }
        },

        async createContract() {
            try {

                if (!this.accept_terms) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please accept terms and conditions',
                    })
                    this.loading = false
                    return;
                }
                if (!this.selectedFile) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please attach contract document',
                    })
                    this.loading = false
                    return;
                }
                if (this.delivery_type == 2) {

                        if (this.delivery_time != '') {
                        
                            if (!this.delivery_period) {
                                swal.fire({
                                icon: 'warning',
                                title: 'Warning',
                                width: 450,
                                height: 5,
                                text: 'Please enter delivery period',
                            })
                            this.loading = false
                            return;
                        }
                    }
                }
               
                this.loading = true

                const formData = new FormData();

                // Loop through each selected file and append it to the formData
                for (let i = 0; i < this.selectedFile.length; i++) {
                    formData.append('images', this.selectedFile[i]);
                }

                //formData.append('images', this.selectedFile),
                formData.append('role_type', this.role_type),
                formData.append('transaction_type', this.transaction_type),
                formData.append('contract_title', this.contract_title),
                formData.append('delivery_type', this.delivery_type),
                formData.append('delivery_location', this.delivery_location),
                formData.append('delivery_time', this.delivery_time),
                formData.append('delivery_period', this.delivery_period),
                formData.append('contract_amount', this.contract_amount),
                formData.append('transaction_fee', this.transaction_fee),
                formData.append('contract_rebate', this.contract_rebate),
                formData.append('whois_paying', this.whois_paying),
                formData.append('accept_terms', 1),
                formData.append('product_description', this.product_description),
                formData.append('addedby', this.user_id),
                formData.append('email', this.email_address),

                this.response = await this.$http.post('/create-contract', formData

                    );
                this.loading = false

                if (!this.response.data.success) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })
                    return;
                } else {
                    // swal.fire({
                    //     icon: 'success',
                    //     title: 'Success',
                    //     width: 450,
                    //     height: 5,
                    //     text: this.response.data.message,
                    // })
                    this.contractLink = this.response.data.contract_link
                    this.addProduct = false,
                    this.viewProduct =true,
                    this.isContractLink = true
                    
                    this.role_type = ''
                    this.contract_title = ''
                    this.delivery_type = ''
                    this.delivery_time = ''
                    this.contract_amount = ''
                    this.product_description = ''
                    this.whois_paying = ''
                    this.accept_terms = ''
                    this.delivery_period = ''
                    this.fetchMyProducts()
                    this.fetchContractByUser()
                    return;
                }
            } catch (err) {
                console.log(err);
            }
        },
        copied(){
            this.isContractLink = false
        },
        async createVariation() {
            try {
                this.is_submit = true
                const response = await this.$http.post('/create-variation', {
                    product_id: this.productid,
                    variation: this.variations,
                    price: this.vprice,
                    addedby: this.user_id
                })
                this.is_submit = false

                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })

            } catch (err) {
                console.log(err);
            }
        },

        async fetchshops() {
            try {
                const response = await this.$http.get('/fetch-all-shops', {})
                response.data.shops.forEach(el => {
                    this.shops.push({
                        text: el.shop_name,
                        value: el.shopid
                    })
                });

            } catch (err) {
                console.log(err);
            }
        },

        async fetchbrands() {
            try {
                const response = await this.$http.get('/get-brands', {})
                response.data.brands.forEach(el => {
                    this.brands.push({
                        text: el.brand_name,
                        value: el.brandid
                    })
                });

            } catch (err) {
                console.log(err);
            }
        },

        async selectCategory() {
            try {

                this.subcategorys = [] // reset list

                const response = await this.$http.post('/fetch-all-subcategories-byid', {
                    category_id: this.category_name
                })
                response.data.categories.forEach(el => {
                    this.subcategorys.push({
                        text: el.subcategory_name,
                        value: el.subcategoryid
                    })
                });

            } catch (err) {
                console.log(err);
            }
        },

        copyToClipboard(link) {
           // var copyText = document.getElementById("myInput");
            navigator.clipboard.writeText(link).then(function() {
                alert("Contract link copied to clipboard!");
            }).catch(function(err) {
                console.error('Failed to copy: ', err);
            });
        },

        copyToClipboard1(link) {
           // var copyText = document.getElementById("myInput");
            navigator.clipboard.writeText(link).then(function() {
                alert("Contract link copied to clipboard!");
            }).catch(function(err) {
                console.error('Failed to copy: ', err);
            });
        },
        async fetchContractByUser() {
            try {
                this.isLoading = true
                this.desserts = []
                const response = await this.$http.post('/fetch-contract-byuser', {
                    user_id: this.user_id
                })
                this.isLoading = false
                
                this.contractsData = response.data.data
                this.contractQty = this.contractsData.length
                this.contractsData.forEach(el => {
                    this.desserts.push({
                        contract_number: el.contract_number,
                        contracttitle: el.contract_title,
                        roletype: el.role_type,
                        amount: el.amount.toLocaleString('en-NG', {
                            minimumIntegerDigits: 2
                        }),
                        fees: el.transaction_fee.toLocaleString('en-NG', {
                            minimumIntegerDigits: 2
                        }),
                        rebate: el.rebate_fee.toLocaleString('en-NG', {
                            minimumIntegerDigits: 2
                        }),
                        link: el.link,
                        product_id1: el.contractid,
                        contract_id: el.contractid,
                        accept_status: el.accept_contract,
                        userid: el.user_id,
                        payment_status: el.contract_status,
                        accepted_by: el.accepted_by,
                        accepted_fullname: el.accepted_fullname,
                        whois_paying: el.whois_paying,
                        role_type: el.role_type,
                        crole_type: el.crole_type,
                        cis_accept: el.cis_accept,
                        is_payment: el.is_payment,
                        delivery_status: el.delivered,
                    })
                });

            } catch (err) {
                console.log(err);
            }
        },
        async fetchMyProducts(userid) {
            try {

                this.isLoading = true
                this.desserts = []
                const response = await this.$http.post('/fetch-product-byuserid', {
                    userid: userid
                })
                this.isLoading = false
                this.products = response.data.products
                this.contractQty = this.products.length
                this.products.forEach(el => {
                    this.desserts.push({
                        businessname: el.shop_name,
                        brand: el.brand_name,
                        category: el.category_name,
                        subcategory: el.subcategory_name,
                        productname: el.product_name,
                        img: el.product_image,
                        price: el.product_price.toLocaleString('en-NG', {
                            minimumIntegerDigits: 2
                        }),
                        productid: el.productid,
                        product_id: el.productid,
                        p_id: el.productid,
                        right: el.right_image,
                        left: el.left_image,
                        back: el.back_image,
                    })
                });

            } catch (err) {
                console.log(err);
            }
        },

        async removeCategory(categoryid) {
            let con = confirm("          You're about to remove this category. Are you sure?")
            if (con == true) {
                try {

                    const response = await this.$http.post('/delete-subcategory', {
                        subcategory_id: categoryid,
                    })

                    if (!response.data.success) {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
                        return;
                    }

                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    this.fetchProducts()
                    return;

                } catch (err) {
                    console.log(err);
                }
            }
        },

        pickFile() {
            this.selectedFile = this.$refs.fileInput.files;
        },
        pickFile1() {
            this.selectedFile1 = this.$refs.fileInput1.files[0];
        },
        pickFile2() {
            this.selectedFile2 = this.$refs.fileInput2.files[0];
        },
        pickFile3() {
            this.selectedFile3 = this.$refs.fileInput3.files[0];
        },
        view_products() {
            this.viewProduct = true,
                this.addProduct = false
        },
        add_products() {
            this.viewProduct = false,
                this.addProduct = true
        }

    }

}
</script>
  
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
.error {
    color: #fff;
  }
  .error1 {
    color: #fff;
  }
.quill-container {
    height: 200px;
    /* Set the desired height here */
}
.copy-refid{
    cursor: pointer;
    font-size: 16px;
}
.copy-refid1{
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}
.a-text {
    font-size: 11px;
    font-family: Poppins;
}
.a-text1 {
    font-size: 14px;
    font-family: Poppins;
}
.process-payment{
    cursor:pointer;
    font-size: 12px;
}
label {
    font-size: 12px;
    font-family: Poppins;
    font-weight: normal;
}

.inputs {
    margin-top: 20px;
    border-radius: 3px;
    font-size: 12px;
}

.price-fee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
        margin: 4px;
        width: 100%;

    }
}

.adjust-fee-input {
    // width: 100px;
    text-align: center;
}

.whopays-fee-input {
    //width: 100% !important;
}

.shop-detail {
    font-size: 12px;
    font-family: Poppins;
}

.btn-danger {
    text-transform: uppercase;
    font-size: 13px;
    font-family: Poppins;
    background: #2979FF;
    border: 0px;
    height: 45px;
}

.text-header {
    padding: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    font-family: Poppins;
    background: #ccc;
}

.contract-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2979FF;
    color: #fff;
    border-radius: 4px;
    padding: 0.8rem;
    font-size: 14px;
    font-weight: 700;
    font-family: Poppins;
    margin-bottom: 5px;
}

.welcome {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    color: #170e0fac;
}

.account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    //padding: 0.9rem;
    background: #fff;
    width: 98%;
}

.account-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Poppins;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wallet-detail {
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;

    h4 {
        font-weight: 650;
    }

    .acct-d {
        line-height: 0.8;
        cursor: pointer;
        text-decoration: underline;
    }
}

.wallet-icon {
    font-size: 60px
}

.fund-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Poppins;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.inputs-field {
    font-size: 14px;
    font-family: Poppins;
    height: 45px;
}

.content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
}

.box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.image-input {
    margin-top: 10px;
}

.box-icon {
    padding-left: 0.4rem;
    font-size: 35px;
    color: #637807;
}

.box-details {
    font-size: 14px;
    color: #170e0fac;
}

.box1:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box2:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box3:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box4:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box5:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box6:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.img {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.box-details {
    padding: 0.4rem;
    line-height: 1.4px;

    .title {
        font-family: Poppins;
        font-weight: 550;
        font-size: 10px;
        text-transform: capitalize;
        color: #ff4d63;
    }

    .description {
        font-family: Poppins;
        font-size: 12px;
    }
}

.continue {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 90%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
}

.account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;

    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
}

.add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
}

.account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
}

.btn-secondary {
    text-transform: normal;
}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

a {
    text-decoration: none;
}

.shop-name {
    //font-weight: 550;
    color: #000;
    font-family: Poppins;
}

@media screen and (max-width: 768px) {
    .copy-refid1{
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
    }
    .inputs-field {
        font-size: 12px;
        font-family: Poppins;
        font-weight: normal;
        height: 45px;
    }
    .contract-header {

        font-size: 12px !important;

    }

    .counter {
        font-size: 13px;
        font-weight: 500 !important;
    }

    .welcome {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: #170e0fac;
    }

    .content-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.9rem;
    }

    .image-input {
        margin-top: 20px;
    }

    .price-fee {
        display: flex;
        flex-direction: column;

        div {
            width: 100%;
        }
    }
    .margin-mobile{
        margin-top: 90px;;
    }

    .adjust-fee-input {
        width: 100%;
    }

    .account-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 100%;
    }

    .account-balance {
        margin-bottom: 10px;
    }

    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;

        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 8px;
            text-transform: capitalize;
            color: #ff4d63;
        }

        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }

    .inputs {
        font-size: 12px;
    }

    .box1 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box2 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box3 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box4 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box5 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        margin-top: -10px;
        color: rgb(237, 138, 108);
    }

    .modal-close-mobile {
        display: block;
    }

    .modal-close {
        display: none;
    }

    .account-content {
        margin: 0px;
        margin-right: 0px;
    }

}
</style>
