<template>
    <div>
      <div id="splash-message" class="splash-message"></div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
        <div class="product-container">
            <div class="column9">
               
                <div class="product-cart-number">
                  <span>&nbsp;&nbsp;<i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                   payment method</span>
                </div>
                <div class="cart-0">
                   <div class="product">
                       <div class="payment-method">
                           <div class="product-name payment-span">
                               <p class="cart-title">Pay with Zedlocker Wallet Balance, Bank Transfer or USSD</p>
                               <p class="brand">You will be redirected to our secure checkout page </p>
                               <div class="form-check">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="optradio" value="wallet" v-model="selectedPaymentMethod">&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;Zedlocker Wallet
                                </label>
                              </div>
                           </div>
                           <div class="product-name payment-span">
                              <p class="cart-title"></p>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="optradio" value="bankTransfer" v-model="selectedPaymentMethod">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Bank Transfer
                                </label>
                              </div>
                            </div>
                       </div>
                   </div>  
                </div>
            </div>
            
            <div class="column3">
              <div class="delivery-status">
                <div class="delivery-return">
                 payment summary
                </div>
                <br>
                <div  class="d-info1">
                  <div class="d-info">
                    <span>Invoice total:</span>  <span>₦{{ contract_sum.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 }) }}</span>   
                  </div>
                  <div class="d-flex flex-row justify-content-between"><span>Escrow fee:</span> <span>{{ transactionFee.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 }) }}</span> </div> 
                </div>
                <hr>
                <div  class="d-info">
                    <span>Total:</span>  <span>₦{{total_sum.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })}}</span> 
                </div>
                <hr>
                <div>
                    <button id="submit4" class="btn btn-primary" :disabled="isPaymentMenthod" @click="btnConfirmOrder()" v-if="!loading">confirm payment</button>
                    <button class="btn btn-primary" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                </div>
              </div>
            </div>
        </div>
        <router-link to="/contract">
          <button class="btn btn-light continue-shopping"> <i class="fa fa-long-arrow-left"></i> Go back</button>
        </router-link>
        <p>&nbsp;</p>
       
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  export default {
    
    name: "CheckoutPage",
    components: {
        //quillEditor,
    },
  
    data(){
      return {
        
          user_id: '',
          firstname: '',
          lastname: '',
          address: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          user_state: [],
          loading: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          img_url: 'http://localhost:3000/assets/uploads/products/',

          payment_bank_name: '',
          payment_account_name: '',
          payment_account_number: '',
          payment_amount_payment: '',
          ussd_code: '',
          transaction_reference: '',
          transaction_details: '',
          isSending: false,
          duration: 20 * 60 * 1000, // 20 minutes in milliseconds
          interval: 0 * 1000, // 30 seconds in milliseconds
          timer: null, 
          loading3: false,
          cart_detail: [],
          total_amount: '',
          selectedPaymentMethod: '',
          is_address: false,
          is_change_address: false,
          cart_length: '',
          cart_items: [],
          checkout_amount: '',
          item_qty: '',
          state: '',
          state_address: [],
          date1: '',
          date2: '',
          payment_mode: '', // one off / installment payment
          addr_state: '',
          user_address: '',
          contract_sum: '',
          contract_id: '',
          transactionFee: 0,
          total_sum: 0,
          contract_number: '',
          contract_user_role: '',
          whois_paying: '',
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.address = localStorage.getItem("address");
        this.user_id = localStorage.getItem("userid");
        this.cart_id = localStorage.getItem('cart_id');
               
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.fetchContractById(this.$route.params.id) 
        this.contract_id = this.$route.params.id
                   
    },
  
    created(){
          
    },
  
    computed: {
      isPaymentMenthod() {
        return (
            this.selectedPaymentMethod === ''
        );
      },
      calculateQty(){
          return this.cart_items.reduce(
              (accumulator, item) => accumulator + item.qty,
              0
          );
      },
    },
  
    methods: {

      async submit_address() {
          try {

            if( this.state == '' ){
              swal.fire({
                    icon: 'info',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please select delivery state',
                })
                return;
              }

              this.is_address = false
              this.is_change_address = true;
              const response = await this.$http.post('/update-address', {
                address: this.address,
                state: this.state,
                userid: this.user_id
              })
              this.is_change_address = false;
              if( response.data.success ){

                this.showSplashMessage("Address successfully updated")
                this.fetch_address()
                this.address = ''
                this.state = ''
                return;
              }
          } catch (err) {
              console.log(err);
          }
      },

      async fetch_address() {
          try {
              const response = await this.$http.post('/fetch-user-byid', {
                userid: this.user_id
              })
              this.addr_state = response.data.users[0].state
              this.user_address = response.data.users[0].address

          } catch (err) {
              console.log(err);
          }
      },

      async fetch_states() {
          try {
              const response = await this.$http.get('/fetch-states', {
              })
              this.state_address = response.data.states
              this.state_address.forEach(el => {
                  this.user_state.push({
                    text: el.state,
                    value: el.state
                  })
                });    

          } catch (err) {
              console.log(err);
          }
      },

      async fetch_contract_users(contract_number, user_id) {
          try {
              const response = await this.$http.post('/fetch-contract-userid', {
                contract_number: contract_number,
                user_id: user_id
              })
                this.contract_user_role = response.data.data[0].role_type
                const userRole = this.contract_user_role;

                if (userRole == 1 && this.whois_paying == 'seller') {
                    this.contract_sum = this.contract_amount
                    this.transactionFee = this.transaction_fee
                    this.total_sum = this.contract_sum + this.transaction_fee
                }
                else {
                    this.contract_sum = this.contract_amount 
                    this.transactionFee = 0
                    this.total_sum = this.contract_sum 
                }
                if(userRole == 2 && this.whois_paying == 'buyer'){
                    this.contract_sum = this.contract_amount 
                    this.transactionFee = this.transaction_fee
                    this.total_sum = this.contract_sum + this.transaction_fee
                }
                else {
                    this.contract_sum = this.contract_amount 
                    this.transactionFee = 0
                    this.total_sum = this.contract_sum 
                }
                if(userRole == 1 && this.whois_paying == 'both'){
                    const both = (this.transaction_fee / 2)
                    this.contract_sum = this.contract_amount
                    this.transactionFee = both
                    this.total_sum = this.contract_amount + both
                }
                else if(userRole == 2 && this.whois_paying == 'both'){
                    const both = (this.transaction_fee / 2)
                    this.contract_sum = this.contract_amount
                    this.transactionFee = both
                    this.total_sum = this.contract_amount + both
                }

          } catch (err) {
              console.log(err);
          }
      },

      async fetchContractById(contract_id) {
            try {

                this.isLoading = true
                const response = await this.$http.post('/fetch-contract-byid', {
                    contract_id: contract_id
                })
                this.isLoading = false
               
                this.contracts = response.data.data[0]
                this.contract_number = this.contracts.contract_number
                this.fetch_contract_users(this.contract_number, this.user_id) 
                this.contract_amount = this.contracts.amount
                this.transaction_fee = this.contracts.transaction_fee
                this.whois_paying = this.contracts.whois_paying;
                       if (this.contracts.delivery_time) {
                    this.isPeriod = true
                }


            } catch (err) {
                console.log(err);
            }
      },


       calculateOrder(){
            this.total_amount = this.cart_items.reduce(
                (accumulator, item) => accumulator + item.unit_price * item.qty,
                0
            );
            if (this.payment_mode == 0) {
              this.checkout_amount = this.total_amount / this.cart_items[0].duration
            }
            else if (this.payment_mode == 1) {
              this.checkout_amount = this.total_amount 
            }
            
        },

      async btnConfirmOrder(){
        try {
            
              this.$router.push('/checkout/payment/review/' + this.contract_id +'/'+this.selectedPaymentMethod)              
          } catch (err) {
              console.log(err);
          }
      },
      showSplashMessage(message) {
        const splashMessage = document.getElementById('splash-message');
        splashMessage.textContent = message;
        splashMessage.style.display = 'block';
        setTimeout(() => {
            splashMessage.style.display = 'none';
        }, 2000); // Hide the message after 2 seconds
    },


    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

  .splash-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: none;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background:#fff;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    width: 100%;
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .account-content-h{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 2px;
    margin-top: 20px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background: #fff;
    color: #000;
  }
  .account-content-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
    padding-top: 1.3rem;
    font-size: 14px;
    width: 100%;
  }
  .account-content-d1{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    padding-top: 1.3rem;
    font-size: 14px;
    margin: 10px;
  }
  .account-content-d2{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    padding-top: 1.3rem;
    font-size: 14px;
    margin: 10px;
  }
  .btn-bottom{
    margin-bottom: 20px;
  }
  .btn-secondary{
    margin-bottom: 10px;
  }

  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }

  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }

  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }

  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }

  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }

  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }

  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;

  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Poppins;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    font-family: Poppins;
    font-size: 13px;
    background: #fff;
    border-bottom: solid 1px #bdbaba;
    color: #626060;
    text-transform: uppercase;
    font-weight: normal;
  }
  .address{
    color: #2979FF;
    text-transform: capitalize;
    font-weight: normal;
    cursor: pointer;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Poppins;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #2979FF;
    color: #fff;
    border-radius: 4px;
}
.counter-action{
    font-size: 13px;
}
.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Poppins;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.3rem;
    font-family: Poppins;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #2979FF;
        font-size: 12px;
      }
  }
  
  .order-number{
    background: #03BE03; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  .product:hover{
    //border: solid 1px #2979FF;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
  }
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    width: 100%;
  }
  .payment-span{
    margin: 5px;
  }
  .payment-method{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    width: 100%;
  }
  .continue-shopping{
    margin-left: 15px;
    font-size: 12px;
  }
  .product-name{
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    margin-right: 10px;
    .cart-title{
        margin-top: -10px;
        font-size: 14px;
        font-weight: normal;
        color: #6e6c6c;
    }
    .brand{
        margin-top: -10px;
        font-size: 12px;
        span{
          font-weight: normal;
          font-size: 15px;
        }
    }
  }
  .payment-made{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;
    font-size: 13px;
    border-radius: 4px;
    border: solid 10px #a5a3a3;
    //background: #a5a3a3;
    .btn-primary{
      background: rgb(42, 136, 230);
      font-size: 14px !important;
    }
    p{
      color: #a5a3a3;
    }
  }
  .product-price{
    .price{
        padding: 16px 0;
        font-size: 20px;
        //font-weight: 600;
        color: #2b2929;
    }
   
  }
  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #2979FF;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }

  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Poppins;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }

  .inputs-field{
    font-size: 14px;
    font-family: Poppins;
    height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 60%;

}
.delivery-return{
    font-size: 14px;
    font-family: Poppins;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
    margin-bottom: 14px;
}
.d-info1{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  font-family: Poppins;
  //padding: 0.3rem;

}
.d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: Poppins;
    //padding: 0.3rem;
    padding-bottom: 1.9rem;
}
.qty{
  padding-left: 0.2rem;
}
.location{
  font-size: 12px;
  font-family: Poppins;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
}

  @media screen and (max-width: 768px) {

    .account-content{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      width: 100%;
      padding-right: 0rem;
      padding-left: 1.3rem;
    }
    .product-detail{
      display: flex;
      flex-direction: column;
      padding: 0.2rem;
      width: 100%;
    }
    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.7rem;
        font-family: Poppins;
        width: 100%;
      }
     
      .column9{
        width: 100%;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Poppins;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }

  
  }
  
  </style>