import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cartCount: 0, // initial value
  },
  mutations: {
    setCartCount(state, count) {
      state.cartCount = count;
    },
  },
  actions: {
    updateCartCount({ commit }, count) {
      commit('setCartCount', count);
    },
  },
  getters: {
    getCartCount: (state) => state.cartCount,
  },
})
