<template>
    <div>
        <div class="package-header">
            <p>&nbsp;</p>
            <p class="package-title">WITHDRAWAL</p>
            <p>&nbsp;</p>
        </div>
        <div class="package-container">
            <div class="product">
                <div class="product-0">
                    <div class="button-task">
                        <div class="sort-record">
                         
                            Sort by: 
                            <select name="" id="" @change="sortWithdrawal" v-model="sort_withdrawal">
                                <option value="">--Select--</option>
                                <option :value="3">All</option>
                                <option :value="1">Pending</option>
                                <option :value="2">Approved</option>
                            </select>
                            <!-- &nbsp;|&nbsp; Record count: <span class="badge badge-success">{{ withdrawal_list_count }}</span>  -->
                        </div>
                        <div>
                          <button class="btn btn-success btn-sm assign-task"  @click="disburseFund()" v-if="!loading"><i class="fa fa-money"></i> Withdraw</button>
                          <button class="btn btn-success btn-sm assign-task"  type="button" disabled v-if="loading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <v-data-table
                          :headers="header"
                          :items="desserts"
                        >
                        <template v-slot:item.amount="{ item }">
                            {{ item.amount.toLocaleString('en-NG', {
                              minimumIntegerDigits: 2
                          })}}
                        </template>
                          <!-- <template v-slot:item.userid="{ item }">
                            <input type="checkbox" class="form-control1 input-check pl" name="" id="pl" v-model="item.selectedUser" @change="selectAll1">
                          </template> -->
                            <template v-slot:item.withd_status="{ item }">
                              <span class="text-warning" v-if="item.withd_status == 1">
                                Pending
                              </span>
                              <span class="text-success" v-else-if="item.withd_status == 2">
                                Approved 
                              </span>
                          </template>
                        </v-data-table>
                      </div>
                </div>
            </div>   
        </div>   
        <div v-if="isAmount">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-4" style="margin:auto">
                    <div class="row">
                        <div class="col-md-0">
                        </div>
                        <div class="col-md-12">
                            <div class="account-content">
                                <div class="account-content-h">
                                  <u>fund withdrawal</u> 
                                </div>
                                <div class="pt-6 pb-0">
                                  <center>
                                  <u>Disbursement Account</u>
                                  <h5>{{ user_bank_detail.bank_name}}</h5>
                                  <h5>{{ user_bank_detail.account_name}}</h5>
                                  <h5>{{ user_bank_detail.account_number}}</h5>
                                </center>
                               </div>
                                <div class="account-content-d ">
                                  <span>Amount</span><br>
                                  <input type="text" class="form-control" placeholder="Enter withdrawal amount" v-model="withdrawal_amount" @input="validateNumber()">
                                  <span v-if="error" class="error">{{ error }}</span>
                                </div>
                                <button class="btn btn-success" :disabled="isSubmitButtonDisabled1" @click="submitWithdrawal()" v-if="!loading1">submit</button>
                                <button class="btn btn-success btn-block" type="button" disabled v-if="loading1">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button class="font-weight-bold" @click="isAmount = false">X</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-0">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <div v-if="isAutoriseCode">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   authorise transaction
                                </div>
                                <div class="account-content-d">
                                  <span>Authorise code</span><br>
                                  <input type="text" class="form-control" placeholder="Please enter the authorise code sent to your email" v-model="authorise_code" @input="validateNumber1()">
                                  <span v-if="error1" class="error1">{{ error1 }}</span>
                                </div>
                                <button class="btn btn-success" :disabled="isSubmitButtonDisabled" @click="authoriseCode()"  v-if="!loading">Authorise</button>
                                <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <!-- <button class="btn btn-secondary" @click="isAutoriseCode = false"><i class="fa fa-long-arrow-left"></i> Back</button> -->
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    <script>
    import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
        name: "WithdrawalPage",
        components: {
            PageLoader
        },
      
        data(){
          return {
    
              isLoading: false,
              isaddVendor: false,
              isAutoriseCode: false,
              isAmount: false,
              error: '',
              withdrawal_amount: '',
              user_id: '',
              firstname: '',
              lastname: '',
              bank_name:'',
              account_name:'',
              account_number: '',
              ussd: '',
              exp_time: '',
              referral_code: '',
              loading: false,
              loading1: false,
              plans: [],      
              transfer_data: [], 
              current_plan: '',   
              tasks: '',

              vfirstname: '',
              vlastname: '',
              vemail: '',
              vphone: '',
              vusername: '',
              vplan:'',
              vamount: '0.00',
              vbank: '',
              vaccount: '',
              date_joined: '',
              plan_active_status: '',

              authorise_code: '',
              confirm_authorise_code: '',
              header: [
               
                { value: 'amount', text: 'Amount', sortable: false },
                { value: 'payment_ref', text: 'Withdrawal reference', sortable: false },
                { value: 'dest_bank', text: 'Disbursement account', sortable: false },
                { value: 'withd_status', text: 'Action', sortable: false },
                // { value: 'userid', text: '', sortable: false },
              ],
            desserts: [],
            withdrawal_list: [],
            vendor_name: '',
            phone_number1: '',
            email_address1: '',
            countUsers: 0,
            sort_withdrawal: '',
            withdrawal_list_count: 0,
            disbursement_details: [],
            transaction_success: '',
            transaction_success_code: '',
            transaction_batch_ref: '',
            authorise_success:'',
            user_bank_detail: '',
            error1: ''

          };
        },
      
        beforeMount(){
         
        },
      
        mounted(){
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
            this.referral_code = localStorage.getItem("referral_code");
            this.fetchWithdrawal(this.user_id)
            this.fetchUserBank(this.user_id)

            this.desserts.forEach((item) => {
                item.selectedUser = item;
                this.selectedUsers = this.desserts.filter(item => item.selectedUser);
            }); 
        },
      
        created(){
              
        },
      
        computed: {
          isSubmitButtonDisabled() {
            return (
              this.authorise_code === ''
            );
          },
          isSubmitButtonDisabled1() {
            return (
              this.withdrawal_amount === ''
            );
          },
          indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
            },
        },
      
        methods: {
          
          validateNumber() {
            const value = this.withdrawal_amount;
              if (value < 0) {
                this.error = 'Value must be non-negative';
                this.withdrawal_amount = ''
                return
              } else if (!Number.isInteger(Number(value))) {
                this.error = 'Value must be an integer';
                this.withdrawal_amount = ''
                return
              } else {
                this.error = '';
              }
          },

          validateNumber1() {
            const value = this.authorise_code;
              if (value < 0) {
                this.error1 = 'Value must be non-negative';
                this.authorise_code = ''
                return
              } else if (!Number.isInteger(Number(value))) {
                this.error1 = 'Value must be an integer';
                this.authorise_code = ''
                return
              } else {
                this.error1 = '';
              }
          },

          async fetchUserBank(userid) {
              try {

                const response =  await this.$http.post('/fetch-user-bank', {
                    userid: userid,
                  })

                  this.user_bank_detail = response.data.bank[0]
                  if (!this.user_bank_detail) {
                  swal.fire({
                            icon: 'info',
                            title: 'Bank account not found',
                            width: 450,
                            height: 5,
                            text: 'Please add beneficiary account',
                        })
                        this.$router.push('/bank')
                        return;
                }
                      
              } catch (err) {
                console.log(err);
            }
          },
          
          generateRandomId() {
              return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          },
  
          setShowBtn(index, value) {
            this.$set(this.showBtn, index, value);
          },
  
          async fetchWithdrawal(user_id) {
              try {
                  this.desserts = []
                  this.isLoading = true
                  const response =  await this.$http.post('/fetch-withdrawal-userId', {
                    userid: user_id
                  })
                  this.isLoading = false
                 
                  this.withdrawal_list = response.data.withdrawal_list
                  this.withdrawal_list_count = this.withdrawal_list.length
                 
                  this.withdrawal_list.forEach(el => {
                     
                      this.desserts.push({
                        amount: el.totalWithdrawal,
                        payment_ref: el.payment_reference,
                        dest_bank: el.dest_bank_name + '-'+el.dest_account_name+'-'+el.dest_account_number,
                        withd_status: el.withdrawal_status,
                        date: new Date(el.created_at).toDateString(),
                        userid: el.user_id,
                      })
                  }); 
                                          
              } catch (err) {
                console.log(err);
            }
          },

          async sortWithdrawal() {
              try {
                  this.desserts = []
                  this.isLoading = true
                  let response = ''
                  if(this.sort_withdrawal == 3){
                        response =  await this.$http.get('/fetch-all-withdrawal', {
                      })
                  }else{
                        response =  await this.$http.post('/fetch-all-withdrawal-bystatus', {
                          withdrawal_status: this.sort_withdrawal
                      })
                  }
                  this.isLoading = false
                  let withd_status = '';
                  this.withdrawal_list = response.data.withdrawal_list
                  this.withdrawal_list_count = this.withdrawal_list.length
                  this.withdrawal_list.forEach(el => {
                      if(el.withdrawal_status == 1){
                      withd_status = "<span class='text-warning'><strong>Pending</strong></span>";
                      }else if(el.withdrawal_status == 2){
                      withd_status = "<span class='text-success'><strong>Approved</strong></span>";
                      }
                      let bank_info = el.bank_name + ' - ' + el.account_name + ' - ' + el.account_number;
                      this.desserts.push({
                      user: el.firstname + ' ' + el.lastname,
                      package: el.plan_name,
                      amount: el.debit,
                      bank: bank_info,
                      account_number: el.account_number,
                      bankcode: el.bank_code,
                      withdrawal_status: withd_status,
                      date: new Date(el.created_at).toDateString(),
                      userid: el.userid,
                      })
                  });                  
                                          
              } catch (err) {
                console.log(err);
            }
          },

          addVendor(){
            this.isaddVendor = true
          },

          selectAll(event) {
            this.desserts.forEach((item) => {
                item.selectedUser = event.target.checked;
                this.selectedUsers = this.desserts.filter(item => item.selectedUser);
              });       

              if (this.selectedUsers == '') {
                  document.getElementById('assign-task').style.display = 'none'
              }
              else{
                  document.getElementById('assign-task').style.display = 'block'
              }
              //console.log(" TEST " + JSON.stringify(this.selectedUsers))
          },

          selectAll1() {
           
              this.selectedUsers = this.desserts.filter(item => item.selectedUser);

              if (this.selectedUsers == '') {
                  document.getElementById('assign-task').style.display = 'none'
              }
              else{
                  document.getElementById('assign-task').style.display = 'block'
              }
             
          },

          async disburseFund(){
            this.isAmount = true 
          },

          async disburseFund1(){

              this.loading1 = true

              const response =  await this.$http.post('/single-fund-transfer', {
                  user_details: this.desserts,
                  amount: this.withdrawal_amount
              })

              this.loading1 = false
              if (!response.data.success) {
                swal.fire({
                  icon: 'warning',
                  title: 'Account Transfer',
                  width: 450,
                  height: 5,
                  text: response.data.message,
                })
                return;
              }
              
                this.disbursement_details = response.data.disburseFund
                this.transaction_success = this.disbursement_details['requestSuccessful']
                if ( this.transaction_success) {
                  this.transaction_success_code = this.disbursement_details['responseCode']
                  this.transaction_batch_ref = this.disbursement_details['responseBody']['reference']
                  this.isAutoriseCode = true
                  this.isAmount = false
                }
                else{
                  this.isAutoriseCode = false
                }
              
          },

          async authoriseCode(){
            try {

                  this.loading = true
                  const response =  await this.$http.post('/authorise-single-transaction', {
                      user_details: this.desserts,
                      single_ref: this.transaction_batch_ref,
                      authorise_code: this.authorise_code,
                  })
                  this.loading = false

                  if(!response.data.success){
                    swal.fire({
                        icon: 'success',
                        title: 'Account Transfer',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                      })
                      return

                  }
                  if(response.data.success){

                      this.authorise_success = response.data.authoriseTransfer
                      this.transaction_success = this.authorise_success['requestSuccessful']
                      if (this.transaction_success) {
                        //let authorise_success_code = this.authorise_success['responseCode']
                        let authorise_status = this.authorise_success['responseBody']['batchStatus']
                      
                        swal.fire({
                        icon: 'success',
                        title: 'Account Transfer',
                        width: 450,
                        height: 5,
                        text: authorise_status,
                      })
                      this.fetchWithdrawal()
                      this.isAutoriseCode = false
                      return
                  }
                }
                      
              } catch (err) {
                console.log(err);
              }
          },
          async submitWithdrawal(){
            this.loading1 = true
              const response =  await this.$http.post('/check-wallet-balance', {
                  userid: this.user_id,
                  amount: this.withdrawal_amount
              })

              this.loading1 = false
              if (!response.data.success) {
                swal.fire({
                  icon: 'info',
                  title: 'Amount Status',
                  width: 450,
                  height: 5,
                  text: response.data.message,
                })
                return;
              }
            
              const response1 =  await this.$http.post('/withdrawal-request', {
                  userid: this.user_id,
                  amount: this.withdrawal_amount,
                  bank_name: this.user_bank_detail.bank_name,
                  account_name: this.user_bank_detail.account_name,
                  account_number: this.user_bank_detail.account_number,
              })

              if (!response1.data.success) {
                swal.fire({
                  icon: 'warning',
                  title: 'Warning',
                  width: 450,
                  height: 5,
                  text: response1.data.message,
                })
                return;
              }

              swal.fire({
                  icon: 'success',
                  title: 'Sumitted',
                  width: 450,
                  height: 5,
                  text: response1.data.message,
              })
              this.fetchWithdrawal(this.user_id)
              this.isAmount = false
              return;
          },
      },

    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
    .error {
      color: #fff;
      font-size: 10px;
    }
    .error1 {
      color: #d71212;
      font-size: 10px;
    }
    .modal-mask {
      position: fixed;
      //z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .4s ease;
    
      &>div {
          width: 90%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #fff;;
          backdrop-filter: blur(13px);
          border-radius: 10px;
          box-sizing: border-box;
          font-family: Poppins;
      }
    
      h3 {
          margin-top: 10px;
          font-size: 18px;
          color: #000;
          font-weight: bold;
          font-family: Poppins;
      }
    }
    .account-content{
      display: flex;
      flex-direction: column;
      justify-content: center;  
    }
    .account-content-h{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      align-items: center;
      width: 100%;
      height: 50px;   
      padding-top: 1.3rem;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    .account-content-d{
      padding-top: 1.3rem;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .btn-success{
      background: #2979FF;   
      border: solid 0px;
      margin-bottom: 10px;
    }
    .p-img{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 80px;
      height: 80px;
      background: #312F33;
      border-radius: 47px;
      margin: 10px;
    }
    .active-status{
      background: #312f334b;
      border-radius: 4px;
      margin: 10px;
      width: 80px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .package-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #ccc;
      }
      .package-title{
        font-size: 30px;
        font-weight: bold;
        font-family: Poppins;
        color: #2979FF;
      }
    .tasks-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 15px; 
        font-weight: bold; 
    }
    .package-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0.9rem;
        background: #fff;
        border-radius: 4px;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Poppins;
        font-size: 12px;
        border: solid 0px #ccc;
        margin-bottom: 40px;
        border-radius: 6px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
        padding: 1.2rem;
        width: 100%;
      }
      .product-0{
        width: 100%;
      }
      .button-task{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .assign-task{
        font-family: Poppins;
        font-size: 10px;
        text-transform: capitalize;
      }
      .product-1{
        display: flex;
        flex-direction: column;
        font-family: Poppins;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 10px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
      }
      .product-1-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .product-2{
        display: flex;
        flex-direction: column;
        font-family: Poppins;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 30px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
        width: 100%;
      }
      .product-2-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .edit-authorise_code{
        cursor: pointer;
      }
      .form-control{
        height: 40px;
        margin-bottom: 4px;
        border-bottom: solid 1px #ccc;
        font-family: Poppins;
        font-size: 12px;
      }
    
      .fa-user{
        font-size: 120px;
        color: #800080;
      }
    
      @media screen and (max-width: 768px) {
        .package-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .product-0{
            width: 100%;
          }
          .product{
            display: flex;
            flex-direction: column;
            padding: 1.7rem;
            font-family: Poppins;
            width: 100%;
    
          }
          .account-content{
            margin: 0px;
            margin-right: 0px;
          }
      }
    </style>