<template>
    <div>
        <div class="package-header">
            <p>&nbsp;</p>
            <p class="package-title">WITHDRAWAL</p>
            <p>&nbsp;</p>
        </div>
        <div class="package-container">
            <div class="product">
                <div class="product-0">
                    <div class="button-task">
                        <div class="sort-record">
                            Sort by: 
                            <select class="text-success" name="" id="" @change="sortWithdrawal" v-model="sort_withdrawal">
                                <option value="">--Select--</option>
                                <option :value="3">All</option>
                                <option :value="1">Pending</option>
                                <option :value="2">Approved</option>
                            </select>
                            &nbsp;|&nbsp; Record count: <span class="badge badge-success">{{ withdrawal_list_count }}</span> 
                        </div>
                        <div>
                          Select All:  <input type="checkbox" class="form-control1 input-check" name="" id="" @change="selectAll" >
                          <button class="btn btn-success btn-sm assign-task" id="assign-task" @click="approveDisbursement()" v-if="!loading1">Approve</button>
                          <button class="btn btn-success btn-sm assign-task" id="assign-task" type="button" disabled v-if="loading1">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <v-data-table
                          :headers="header"
                          :items="desserts"
                        >
                        <template v-slot:item.amount="{ item }">
                            {{ item.amount.toLocaleString('en-NG', {
                              minimumIntegerDigits: 2
                          })}}
                        </template>
                          <template v-slot:item.withdrawal_status="{ item }">
                            <span v-html="item.withdrawal_status"></span>
                          </template>
                          <template v-slot:item.userid="{ item }">
                            <div v-if="item.wstatus == 1">
                              <input type="checkbox" class="form-control1 input-check pl" name="" id="pl" v-model="item.selectedUser" @change="selectAll1">
                            </div>
                            <div v-else></div>
                          </template>
                        </v-data-table>
                      </div>
                </div>
            </div>   
        </div>   
        <div v-if="isAutoriseCode">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-4" style="margin:auto">
                    <div class="row">
                        <div class="col-md-0">
                        </div>
                        <div class="col-md-12">
                            <div class="account-content">
                                <div class="account-content-h">
                                   authorise transaction
                                </div>
                                <div class="account-content-d">
                                  <span>Enter authorise code</span><br>
                                  <input type="password" class="form-control" v-model="authorise_code">
                                </div>
                                <button class="btn btn-success" :disabled="isSubmitButtonDisabled" @click="auto_disbursement()" v-if="!loading">Authorise</button>
                                <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button @click="isAutoriseCode = false">X</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-0">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>

        <div v-if="isDisbusement_mode">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-4" style="margin:auto">
                    <div class="row">
                        <div class="col-md-0">
                        </div>
                        <div class="col-md-12">
                            <div class="account-content">
                                <div class="account-content-h">
                                   disbursement mode
                                </div>
                                <div class="account-content-d">
                                  <span>Select Mode</span><br>
                                  <select name="" id="" v-model="disbursement_mode" class="form-control">
                                    <option value="">-Select-</option>
                                    <option value="auto">Auto Disbursement</option>
                                    <option value="manual">Manual Disbursement</option>
                                  </select>
                                </div>
                                <button class="btn btn-success" :disabled="isSubmitButton" @click="disburse_fund()" v-if="!loading2">Continue</button>
                                <button class="btn btn-success btn-block" type="button" disabled v-if="loading2">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button  @click="isDisbusement_mode = false">X</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-0">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    <script>
    import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
        name: "ListWithdrawalPage",
        components: {
            PageLoader
        },
      
        data(){
          return {
    
              isLoading: false,
              isaddVendor: false,
              isAutoriseCode: false,
              isDisbusement_mode: false,
              disbursement_mode: '',
              user_id: '',
              firstname: '',
              lastname: '',
              bank_name:'',
              account_name:'',
              account_number: '',
              ussd: '',
              exp_time: '',
              referral_code: '',
              loading: false,
              loading1: false,
              loading2: false,
              plans: [],      
              transfer_data: [], 
              current_plan: '',   
              tasks: '',

              vfirstname: '',
              vlastname: '',
              vemail: '',
              vphone: '',
              vusername: '',
              vplan:'',
              vamount: '0.00',
              vbank: '',
              vaccount: '',
              date_joined: '',
              plan_active_status: '',

              authorise_code: '',
              confirm_authorise_code: '',
              header: [
                {
                    align: 'start',
                    value: 'user',
                    sortable: false,
                    text: 'Fullname',
                },
                { value: 'withdrawal_reference', text: 'Withdrawal reference' },
                { value: 'amount', text: 'Amount' },
                { value: 'bank', text: 'Disbursement account' },
                // { value: 'account_number', text: '' },
                // { value: 'bankcode', text: '' },
                { value: 'withdrawal_status', text: 'Status' },
                { value: 'date', text: 'Date' },
                { value: 'userid', text: '' },
              ],
            desserts: [],
            withdrawal_list: [],
            vendor_name: '',
            phone_number1: '',
            email_address1: '',
            countUsers: 0,
            sort_withdrawal: '',
            withdrawal_list_count: 0,
            disbursement_details: [],
            transaction_success: '',
            transaction_success_code: '',
            transaction_batch_ref: '',
            authorise_success:'',
            selectedUsers: ''
          };
        },
      
        beforeMount(){
         
        },
      
        mounted(){
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
            this.referral_code = localStorage.getItem("referral_code");
            this.fetchWithdrawal()
        },
      
        created(){
              
        },
      
        computed: {
          isSubmitButtonDisabled() {
            return (
              this.authorise_code === ''
            );
          },

          isSubmitButton() {
            return (
              this.disbursement_mode === ''
            );
          },

          indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
            },
        },
      
        methods: {
        
          generateRandomId() {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          },
  
          setShowBtn(index, value) {
            this.$set(this.showBtn, index, value);
          },
  
          async fetchWithdrawal() {
              try {
                  this.desserts = []
                  this.isLoading = true
                  const response =  await this.$http.get('/fetch-all-withdrawal', {
                  })
                  this.isLoading = false
                  let withd_status = '';
                  this.withdrawal_list = response.data.withdrawal_list
                  this.withdrawal_list_count = this.withdrawal_list.length
                  this.withdrawal_list.forEach(el => {
                      if(el.withdrawal_status == 1){
                        withd_status = "<span class='text-warning'><strong>Pending</strong></span>";
                      }else if(el.withdrawal_status == 2){
                        withd_status = "<span class='text-success'><strong>Approved</strong></span>";
                      }
                      let bank_info = el.bank_name + ' - ' + el.account_name + ' - ' + el.account_number;
                      this.desserts.push({
                        user: el.firstname + ' ' + el.lastname,
                        withdrawal_reference: el.payment_reference,
                        amount: el.debit,
                        bank: bank_info,
                        account_number: el.account_number,
                        bankcode: el.bank_code,
                        withdrawal_status: withd_status,
                        date: new Date(el.created_at).toDateString(),
                        userid: el.userid,
                        walletid: el.walletid,
                        wstatus: el.withdrawal_status
                      })
                  }); 
                                          
              } catch (err) {
                console.log(err);
            }
          },

          async sortWithdrawal() {
              try {
                  this.desserts = []
                  this.isLoading = true
                  let response = ''
                  if(this.sort_withdrawal == 3){
                        response =  await this.$http.get('/fetch-all-withdrawal', {
                      })
                  }else{
                        response =  await this.$http.post('/fetch-all-withdrawal-bystatus', {
                          withdrawal_status: this.sort_withdrawal
                      })
                  }
                  this.isLoading = false
                  let withd_status = '';
                  this.withdrawal_list = response.data.withdrawal_list
                  this.withdrawal_list_count = this.withdrawal_list.length
                  this.withdrawal_list.forEach(el => {
                      if(el.withdrawal_status == 1){
                      withd_status = "<span class='text-warning'><strong>Pending</strong></span>";
                      }else if(el.withdrawal_status == 2){
                      withd_status = "<span class='text-success'><strong>Approved</strong></span>";
                      }
                      let bank_info = el.bank_name + ' - ' + el.account_name + ' - ' + el.account_number;
                      this.desserts.push({
                      user: el.firstname + ' ' + el.lastname,
                      withdrawal_reference: el.payment_reference,
                      amount: el.debit,
                      bank: bank_info,
                      account_number: el.account_number,
                      bankcode: el.bank_code,
                      withdrawal_status: withd_status,
                      date: new Date(el.created_at).toDateString(),
                      userid: el.userid,
                      })
                  });                  
                                          
              } catch (err) {
                console.log(err);
            }
          },

          addVendor(){
            this.isaddVendor = true
          },

          selectAll(event) {
            //console.log(this.desserts);
            this.desserts.forEach((item) => {
                if (item.wstatus == 1) {
                  item.selectedUser = event.target.checked;
                  this.selectedUsers = this.desserts.filter(item => item.selectedUser);
                }
                                
              });       

              if (this.selectedUsers == '') {
                  document.getElementById('assign-task').style.display = 'none'
              }
              else{
                  document.getElementById('assign-task').style.display = 'block'
              }
              console.log(" TEST " + JSON.stringify(this.selectedUsers))
          },

          selectAll1() {
           
              this.selectedUsers = this.desserts.filter(item => item.selectedUser);

              if (this.selectedUsers == '') {
                  document.getElementById('assign-task').style.display = 'none'
              }
              else{
                  document.getElementById('assign-task').style.display = 'block'
              }
              //console.log(" TEST " + JSON.stringify(this.selectedUsers))
          },

          async disburse_fund(){
            if (this.disbursement_mode == 'auto') {
                this.isAutoriseCode = true
                this.isDisbusement_mode = false
                
            }else if( this.disbursement_mode == 'manual'){

              this.manual_disbursement()
            }
          },

          async manual_disbursement(){
            try {
                  this.loading2 = true
                  const response =  await this.$http.post('/approve-withdrawal-request', {
                      user_details: this.selectedUsers,
                  })
                  this.loading2 = false

                  if(!response.data.success){
                          swal.fire({
                          icon: 'warning',
                          title: 'Error',
                          width: 450,
                          height: 5,
                          text: response.data.message,
                          })
                          this.isDisbusement_mode = false
                          this.fetchWithdrawal()
                          return
                      }
                      else{
                          swal.fire({
                          icon: 'success',
                          title: 'Disbursement Successful',
                          width: 450,
                          height: 5,
                          // text: response.data.message,
                          })
                          this.isDisbusement_mode = false
                          this.fetchWithdrawal()
                          return
                      }
                      
                  } catch (err) {
                  console.log(err);
                  }
          },
          
          async auto_disbursement(){
            try {
                  this.loading = true
                  const response =  await this.$http.post('/bank-transfer', {
                      user_details: this.selectedUsers,
                      transaction_pin: this.authorise_code
                  })
                  this.loading = false

                    if(!response.data.success){
                          swal.fire({
                          icon: 'warning',
                          title: 'Error',
                          width: 450,
                          height: 5,
                          text: response.data.message,
                          })
                          this.authorise_code = ''
                          this.fetchWithdrawal()
                          this.isDisbusement_mode = false
                          this.isAutoriseCode = false
                          return
                      }
                      else{
                          swal.fire({
                          icon: 'success',
                          title: 'Disbursement Successful',
                          width: 450,
                          height: 5,
                          text: response.data.message,
                          })
                          this.authorise_code = ''
                          this.isDisbusement_mode = false
                          this.isAutoriseCode = false
                          this.fetchWithdrawal()
                          return
                      }
                      
                  } catch (err) {
                  console.log(err);
                  }
          },
          
          async approveDisbursement(){
            this.isDisbusement_mode = true
          },

          async disburseFund1(){

              this.loading1 = true
              const response =  await this.$http.post('/disburse-fund', {
                  user_details: this.selectedUsers,
              })

              this.loading1 = false
              if(response.data.success){

                      this.disbursement_details = response.data.disburseFund
                      this.transaction_success = this.disbursement_details['requestSuccessful']
                      if (this.transaction_success) {
                        this.transaction_success_code = this.disbursement_details['responseCode']
                        this.transaction_batch_ref = this.disbursement_details['responseBody']['batchReference']
                        //let transaction_status = this.disbursement_details['responseBody']['batchStatus']

                        this.isAutoriseCode = true

                  }
              }
          },

          async authoriseCode(){
            try {

                  this.loading = true
                  const response =  await this.$http.post('/authorise-transaction', {
                      user_details: this.selectedUsers,
                      batch_ref: this.transaction_batch_ref,
                      authorise_code: this.authorise_code,
                  })
                  this.loading = false
                  if(response.data.success){

                      this.authorise_success = response.data.authoriseTransfer
                      this.transaction_success = this.authorise_success['requestSuccessful']
                      if (this.transaction_success) {
                        //let authorise_success_code = this.authorise_success['responseCode']
                        let authorise_status = this.authorise_success['responseBody']['batchStatus']
                      
                        swal.fire({
                        icon: 'success',
                        title: 'Batch Disbursement',
                        width: 450,
                        height: 5,
                        text: authorise_status,
                      })
                      this.fetchWithdrawal()
                      this.isAutoriseCode = false
                      return
                  }
                }
                      
              } catch (err) {
                console.log(err);
              }
          }
        }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    .modal-mask {
      position: fixed;
      //z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .4s ease;
    
      &>div {
          width: 90%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #fff;;
          backdrop-filter: blur(13px);
          border-radius: 10px;
          box-sizing: border-box;
          font-family: Mulish;
      }
    
      h3 {
          margin-top: 10px;
          font-size: 18px;
          color: #000;
          font-weight: bold;
          font-family: Mulish;
      }
    }
    .account-content{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      //margin-right: 8px;
    }
    .account-content-h{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      align-items: center;
      width: 100%;
      height: 50px;   
      //margin: 4px;
      padding-top: 1.3rem;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    .account-content-d{
      padding-top: 1.3rem;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .btn-success{
      background: #2979FF;   
      border: solid 0px;
      margin-bottom: 10px;
    }
    .p-img{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 80px;
      height: 80px;
      background: #312F33;
      border-radius: 47px;
      margin: 10px;
    }
    .active-status{
      background: #312f334b;
      border-radius: 4px;
      margin: 10px;
      width: 80px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .package-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #ccc;
      }
      .package-title{
        font-size: 30px;
        font-weight: bold;
        font-family: Mulish;
        color: #2979FF;
      }
    .tasks-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 15px; 
        font-weight: bold; 
    }
    .package-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0.9rem;
        background: #fff;
        border-radius: 4px;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Mulish;
        font-size: 12px;
        border: solid 0px #ccc;
        margin-bottom: 40px;
        border-radius: 6px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
        padding: 1.2rem;
        width: 100%;
      }
      .product-0{
        width: 100%;
      }
      .button-task{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .assign-task{
        font-family: Mulish;
        font-size: 10px;
        display: none;
      }
      .product-1{
        display: flex;
        flex-direction: column;
        font-family: Mulish;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 10px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
      }
      .product-1-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .product-2{
        display: flex;
        flex-direction: column;
        font-family: Mulish;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 30px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
        width: 100%;
      }
      .product-2-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .edit-authorise_code{
        cursor: pointer;
      }
      .form-control{
        height: 40px;
        margin-bottom: 4px;
        border-bottom: solid 1px #ccc;
        font-family: Mulish;
        font-size: 12px;
      }
    
      .fa-user{
        font-size: 120px;
        color: #2979FF;
      }
    
      @media screen and (max-width: 768px) {
        .package-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .product-0{
            width: 100%;
          }
          .product{
            display: flex;
            flex-direction: column;
            padding: 1.7rem;
            font-family: Mulish;
            width: 100%;
    
          }
          .account-content{
            display: flex;
            flex-direction: column;
            justify-content: center;  
            width: 100%;
            padding: 10px;
          }
      }
    </style>