<template>
    <div class="container-fluid">
        <p class="welcome"></p>
         <div class="col-lg-12 col-sm-12 pt-3 dashboard-page">             
              <div class="col-lg-6 col-sm-6 pt-3">
                <div @click="go_back()" style="cursor:pointer" class="font-weight-normal pb-5"><i class="fa fa-long-arrow-left"></i> Home</div>
                <div class="dashboard-inner-new">
                    <div class="wallet-board-new amount">
                      <div class="avail-amount">
                        <div class="topup">Bank Transfer</div>
                        <div class="add-money">Payment via mobile or internet banking</div>
                      </div>
                    </div>
                    <div class="confirm-text pt-2">
                        <div class="c-text-1"></div>
                          <div class="c-text-10">
                                <img src="../assets/img-success.png" alt="" width="30%">
                                <div class="pt-1 amt-sent-text">Amount Sent</div>
                                <div class="pt-0 amt-sent">₦{{ amount.toLocaleString('en-NG', {
                                  minimumIntegerDigits: 2
                              }), }}</div>
                                <div class="pt-1 amt-k">
                                    On the way to <span>{{ dest_account_name }}</span> bank account
                                </div>
                          </div>  
                        <div class="c-text-1"></div>
                    </div>
                    <div class="payment-sent-panel">
                      <div class="bg-transparent">
                        <div class="panel-in">Payment Method:</div>
                        <div class="panel-ins">Bank Transfer</div>
                      </div>
                      <div class="bg-transparent">
                        <div class="panel-in ">Sent To:</div>
                        <div class="panel-ins ">{{ dest_account_number}}</div>
                      </div>
                      <div class="bg-transparent">
                        <div class="panel-in ">Bank Name:</div>
                        <div class="panel-ins ">{{ dest_bank_name }}</div>
                      </div>
                      <div class="bg-transparent">
                        <div class="panel-in ">Status:</div>
                        <div class="panel-ins ">ONGOING</div>
                      </div>
                    </div>
                    <div class="wallet-info">
                        <p>You will be notified as soon as the payment made is recieved and confirmed.</p>
                    </div>
                    <div class="add-withd-panel-enter-p"><button @click="confirm_payment()" class="proceed">Return to Dashboard</button></div>
                    <div class="add-withd-panel-enter">
                      <!-- <button @click="go_back()" class="go-back"> Go Back</button>  -->
                    </div>
                </div>
              </div>
         </div>

          <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
 // import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "BankPaymentSuccessful",
    components: {
      PageLoader
    },
  
    data(){
      return {
          search: '',
          loading: false,
          isLoading: false,
          phone_number: '',
          email_address: '',
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',     
          amount: '',
          img_url: process.env.VUE_APP_IMAGE_URL + '/products/',
          source_account_name:'',
          source_account_number: '',

          dest_bank_name: '',
          dest_account_name: '',
          dest_account_number: '',
        };
    },
  
    beforeMount(){
      this.user_id = localStorage.getItem("userid");
    },
  
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.referral_code = localStorage.getItem("referral_code");
        this.fullname = this.firstname +' '+ this.lastname 

        this.amount = this.$route.params.amount
        this.dest_bank_name = this.$route.params.dest_bank_name
        this.dest_account_name = this.$route.params.dest_account_name
        this.dest_account_number = this.$route.params.dest_account_number
        this.reference_number = this.$route.params.ref
        // this.source_bank = this.$route.params.bank_name
        // this.source_account_name = this.$route.params.account_name
        // this.source_account_number = this.$route.params.account_number
    },
  
    created(){
          
    },
  
    computed: {
     
     
    },
  
    methods: {
      confirm_payment(){
        this.$router.push('/home')
      },
      go_back(){
          this.$router.push('/home')
      },
    
      copyToClipboard() {
        var copyText = document.getElementById("myInput");
        navigator.clipboard.writeText(copyText.value).then(function() {
            alert("Referral link copied!");
        }).catch(function(err) {
            console.error('Failed to copy: ', err);
        });
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
  
  .copy-refid1{
    cursor: pointer;
    //font-size: 20px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 90%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
  }
  
  .account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  
  .add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .btn-secondary {
    text-transform: normal;
  }
  .welcome{
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    padding: 0px 20px;
    text-align: left;
    color: #170e0fac;
  }
   .dashboard-page{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: #fff;
      padding: 0.6rem;
      font-family: Poppins;
      strong{
        font-weight: normal;
        font-family: Poppins;
      }
   }
   .wallet-board{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
      //background: #807b7b33;
      border-radius: 4px;
      padding: 0.5rem;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
      height: 100%;
   }
   .wallet-account{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    background: #2979FF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
   }
   .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.6rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1.2;
        color: #ccc;
      }
  
   }
   .account-number{
      padding: 0.6rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
  
      span{
        color: #fff;
      }
    }
   }
   .wallet-balance{
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      width: 60%;
      height: 100%;
      //background-image: linear-gradient(-90deg, #fff, #cccccc56, #fff);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      
      h4{
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 12px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
      border-radius: 4px;
   }
   .dashboard-inner{
    overflow-y: auto;
    height: 255px;
    width: 100%;
    border-radius: 15px;
    padding: 1.2rem;
    margin: 10px;
    background: #fff;
   }
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 18px 35px 18px 35px;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
  
      .topup{
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        color: var(--DIM, #181818);
      }
      .add-money{
        font-size: 8px;
        font-weight: 400;
        text-align: center;
        color: var(--DIM, #181818);
      }
  
    }   
   }
   .confirm-text{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }
    .c-text-1{
       width: 10%;
    }
    .c-text-10{
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        width: 90%;
        color: var(--DIM, #7F7F7F);

        .amt-sent-text{
            font-size: 10px;
            font-weight: 700;
            color: var(--Primary-Color, #2879FD);
        }
        .amt-sent{
            font-size: 24px;
            font-weight: 700;
            color: var(--Secondary-color, #08294A);

        }
        .amt-k{
            font-size: 12px;
            font-weight: 400;
            color: var(--Primary-Color, #2879FD);
            span{
                color: #201e1e;
            }
        }
    }
    .payment-sent-panel{
        display: flex;
        flex-direction: column;
        background: url('../assets/img-bg.png') no-repeat ;
        background-size: cover;
        padding: 0.4rem;
    }
    .bg-transparent{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div{
            margin: 12px;
            color: #fff;
        }
      .panel-ins{
        font-weight:500;
      }
    }
    .wallet-info{
        p{
            text-align: center;
            font-size: 10px;
            font-weight: 400;
        }
    }

   .avail-amount-p{
      display: flex;
      flex-direction: column;
      justify-content: left;
      
   }
   .amount-p{
    font-size: 11px;
    font-weight: 450;
    color: var(--DIM, #7F7F7F);
   }
   .amount{
    padding: 0.6rem !important;
    background: var(--sub-layer, #ECF3FF) !important;
   }
   .amount-v{
    border-radius: 6px;
    margin-top: -10px;
    padding: 0.2rem !important;
    background: var(--sub-layer, #ECF3FF) !important;
   }
   .input-amount-symbol{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span{
      width: 21px;
      height: 20px;
      border-right: solid 1.4px #7F7F7F;
    }
   }
   .input-amount{
    width: 100%;
    height: 32px;
    gap: 10px;
    
    background: #ECF3FF;
    color:#7F7F7F
   }
   .enter-fund{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #7F7F7F);
   }
   .trans-charge{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #2879FD);
   }
   .add-withd-panel-center{
    display: flex;
    flex-direction: column;
   }
   .change-payment{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    text-align: right;
    color: #414244;
    .fa-angle-down{
      color: var(--DIM, #2879FD);
      font-size: 12px;
    }
   }
   .add-withd-panel-enter-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-withd-panel-enter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .proceed{
    width: 50%;
    height: 40px;
    border-radius: 8px;
    
    background: #000000B8;
    color: #FFFFFF;
    font-size: 12px;
   }

   .go-back{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
   }
   .cancel-trans{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;  
    color: var(--DIM, #7F7F7F);
   }
   .separator{
    color: var(--Primary-Color, #2879FD);
   }
   .active-c{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 15px;
    height: 100px;
    width: 100%;
  
    .icon{
      font-size: 44px;
    }
   }
   .active-c-history{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
  
    .icon2{
      font-size: 20px;
    }
   }
   .activeo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
   }
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 400;
      font-size: 11px;
      font-family: Poppins;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .location{
      color: #807b7b;
      font-weight: 550;
      font-size: 11px;
      font-family: Poppins;
      margin-top: 4px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
  
      button{
        font-size: 10px;
      }
    }
    button{
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .active-cc-history{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #00000091;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
  
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 12px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 40px;
    }
   }
  
  
  @media screen and (max-width: 768px) {
    .welcome{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      padding: 0px 15px;
      text-align: left;
      color: #170e0fac;
    }
  
    .dashboard-page{
      display: flex;
      flex-direction: column;
      strong{
        margin-top: 10px;
      }
   }
   .dashboard-inner{
    margin: 0px;
    margin-top: 10px;
    padding: 0.8rem;
   }
  
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.6rem;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
   
    width: 100%;
    height: auto;
    padding: 0.9rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      
      width: 100%;
      .topup{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }
      .add-money{
        color: var(--DIM, #181818);
      }
      .avail-bal{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 400;
      }
      .trans-his{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: normal;
        color: #D9D9D9;
      }
  
    }
   
    .input-amount-symbol{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span{
        color: #2D2D2D;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        width: 21px;
        height: 20px;
        gap: 0px;
        
      }
     }
     .input-amount{
        background: #ECF3FF;
        color:#7F7F7F;
        font-size: 12px;
       }
     .amount-p{
      color: var(--DIM, #7F7F7F);
      font-size: 10px;
     }
     .enter-fund{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #7F7F7F);
     }
     .trans-charge{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #2879FD);
     }
     .add-withd-panel-center{
      display: flex;
      flex-direction: column;
     }
     .change-payment{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: 400;
      text-align: right;
      color: #414244;
      .fa-angle-down{
        color: var(--DIM, #2879FD);
        font-size: 10px;
      }
     }
    
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        font-family: Montserrat;
        font-size: 8px;
        font-weight: normal;
        color: #D9D9D9;
      }
      .pending-fund{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 600;
      }
    }
   }

   .c-text-10{
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    width: 90%;
    color: var(--DIM, #7F7F7F);

    .amt-sent-text{
        font-size: 10px;
        font-weight: 700;
        color: var(--Primary-Color, #2879FD);
    }
    .amt-sent{
        font-size: 18px;
        font-weight: 700;
        color: var(--Secondary-color, #08294A);

    }
    .amt-k{
        font-size: 10px;
    }
}
   .payment-sent-panel{
    display: flex;
    flex-direction: column;
    background: url('../assets/img-bg.png') no-repeat ;
    background-size: cover;
    padding: 0.2rem;
    margin: 0px;
    font-size: 10px !important;
    }

    .bg-transparent{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 10px !important;
        margin: -5px;
    }
   
    .panel-ins{
        font-weight:bold;
    }
    .wallet-info{
      p{
        text-align: center;
        font-size: 10px;
        font-weight: 400;
      }
    }
   .add-withd-panel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-fund{
    width: Fixed (111.7px)px;
    height: Fixed (38px)px;
    padding: 12.67px 16.12px 12.67px 16.12px;
    border-radius: 81.53px;
    
    background: var(--Primary-Color, #2879FD);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 4px;
  
   }
   .withd-fund{
    width: Fixed (112.59px)px;
    height: Hug (38px)px;
    padding: 12.67px 9.85px 12.67px 9.85px;
    border-radius: 81.53px;
    
    background: var(--Secondary-color, #08294A);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
  
   }
  
   .active-c{
  
    .icon{
      font-size: 40px;
    }
   }
  
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .location{
      color: #807b7b;
      font-weight: 200;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .wallet-board{
    padding: 0.5rem;
  }

  .proceed{
    padding: 0.5rem;
    font-size: 10px;
   }
  
  .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.4rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1;
      }
  
   }
   .account-number{
      padding: 0.4rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
      span{
        color: #fff;
        font-size: 11px;
      }
    }
   }
   .wallet-balance{
      h4{
        font-weight: normal;
        font-size: 14px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
   }
  
   .active-c-history{
    padding: 0px;
    margin-top: 10px;
    .icon2{
      font-size: 15px;
    }
   }
  
   .active-cc-history{
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      
      span{
        color: #00000091;
        font-style: italic;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 23px;
    }
   }
   .account-content {
    margin: 0px;
    margin-right: 0px;
  }
    
  }
  
  </style>