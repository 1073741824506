<template>
    <div class="container-fluid">
       <br>
        <p class="text-header">WALLET</p>
        <div class="account-details">
            <div class="col-sm-12 table table-responsive">
                <div class="contract-header">
                    <div>Wallet Transactions <span class="badge badge-primary">{{ contractQty }}</span></div>
                    <div></div>
                    <!-- <div> <i class="fa fa-plus-square" @click="add_products" style="cursor:pointer;"> Create Contracts</i></div> -->
                </div>
    
                <div class="table table-responsive table-sm">
                    <v-data-table :headers="header" :items="desserts" dense class="table-striped table-bordered table-responsive">
                        <template v-slot:item.contract_number="{ item }">
                            <div class="a-text">{{ item.contract_number }}</div>
                        </template>
                        <template v-slot:item.contracttitle="{ item }">
                            <div class="a-text">{{ item.contracttitle }}</div>
                        </template>
                        <template v-slot:item.beneficiary="{ item }">
                            <div class="a-text">{{ item.beneficiary }}</div>
                        </template>
                        <template v-slot:item.amount="{ item }">
                            <div class="a-text">&#8358;{{ item.amount }}</div>
                        </template>
                        <template v-slot:item.payment_mode="{ item }">
                            <div class="a-text">{{ item.payment_mode }}</div>
                        </template>
                        <template v-slot:item.payment_date="{ item }">
                            <div class="a-text">{{ item.payment_date }}</div>
                        </template>
                       
                    </v-data-table>
                </div>
            </div>
        </div>
        
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
      
    <script>
    //import 'quill/dist/quill.snow.css';
    // import {
    //     quillEditor
    // } from 'vue-quill-editor';
    
   // import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
    
        name: "TransactionPage",
        components: {
            //quillEditor,
            PageLoader
        },
    
        data() {
            return {
    
                isLoading: false,
                isRightImage: false,
                isLeftImage: false,
                isBackImage: false,
                user_id: '',
                firstname: '',
                lastname: '',
                bank_name: '',
                account_name: '',
                account_number: '',
    
                loading: false,
                loading1: false,
                loading2: false,
                loading3: false,
                role_type: '',
                contract_title: '',
                transaction_type: '',
                delivery_type: '',
                delivery_location: '',
                delivery_time: '',
                contract_amount: '',
                transaction_fee: '',
                whois_paying: '',
                contract_rebate: '',
                product_description: '',
                accept_terms: '',
    
                products: [],
                subcategorys: [],
                selectedFile: '',
                selectedFile1: '',
                selectedFile2: '',
                selectedFile3: '',
                addProduct: false,
                viewProduct: true,
                editorOptions: {
                    placeholder: 'Write something...',
                },
    
                isVariations: false,
                productid: '',
                variations: '',
                vprice: '',
                is_fees_status: false,
                is_submit: false,
                is_submit1: false,
                is_submit2: false,
                is_submit3: false,
                img_url: process.env.VUE_APP_IMAGE_URL,
                header: [
                   
                    // {
                    //     value: 'contracttitle',
                    //     text: 'Transaction title'
                    // },

                    {
                        value: 'payment_reference',
                        text: 'Transaction reference'
                    },
                    {
                        value: 'payment_mode',
                        text: 'Transaction method'
                    },
                    {
                        value: 'credit',
                        text: 'Credit'
                    },
                    {
                        value: 'debit',
                        text: 'Debit'
                    },
                    {
                        value: 'payment_date',
                        text: 'Transaction date'
                    },

                ],
    
                desserts: [],
                user_role: '',
                product_id: '',
                userid: '',
                contractQty: '',
                deliveryType: [{
                        text: 'Same day delivery (14 hours max)',
                        value: 'Same day delivery (14 hours max)'
                    },
                    {
                        text: 'Interstate',
                        value: 'Interstate'
                    },
                ],
    
                transactionType: [{
                        text: 'Physical Product',
                        value: 'Physical Product'
                    },
                    {
                        text: 'Services',
                        value: 'Services'
                    },
                    {
                        text: 'Digital Product',
                        value: 'Digital Product'
                    },
                    {
                        text: 'Mutual Contract',
                        value: 'Mutual Contract'
                    },
                ],
                roleType: [{
                        text: 'Seller',
                        value: '1'
                    },
                    {
                        text: 'Buyer',
                        value: '2'
                    },
                ],
    
                contractsData: [],
                isPeriod: false,
                delivery_period: '',
                payment_status: '',
                is_escrow_charge: false,
    
            };
        },
    
        beforeMount() {
    
        },
    
        mounted() {
    
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
    
            this.bank_name = localStorage.getItem("bank_name");
            this.account_name = localStorage.getItem("account_name");
            this.account_number = localStorage.getItem("account_number");
            this.user_role = localStorage.getItem("user_role");
    
            this.fetchAllPaymentByUser(this.user_id)
    
            // if (this.user_role == 1) {
            //     this.fetchProducts()
               
            // } else if (this.user_role == 3) {
            //     this.fetchMyProducts(this.user_id)
            // }
        },
    
        created() {
    
        },
    
        computed: {
    
            isProductEnabled() {
                return (
                    this.role_type === '' ||
                    this.contract_title === '' ||
                    this.delivery_type === '' ||
                    this.delivery_time === '' ||
                    this.contract_amount === '' ||
                    this.product_description === '' ||
                    this.whois_paying === '' ||
                    this.accept_terms === '' ||
                    this.delivery_location === ''
                );
            },
    
            indexSn() {
                return this.desserts.map((d, index) => ({
                    ...d,
                    sn: index + 1
                }))
            },
    
        },
    
        methods: {
            fee_handling(){
                this.is_escrow_charge = true
            },
            processPayment(contract_id){
                this.$router.push('/checkout/summary/'+contract_id)
            },
          
            addVariations(productid) {
                this.productid = productid
                this.isVariations = true
            },
    
          
            formatToCurrency(value) {
              return value.toLocaleString('en-NG', { minimumFractionDigits: 2 })
            },
    
            copyToClipboard(link) {
               // var copyText = document.getElementById("myInput");
                navigator.clipboard.writeText(link).then(function() {
                    alert("Contract link copied to clipboard!");
                }).catch(function(err) {
                    console.error('Failed to copy: ', err);
                });
            },
    
            async fetchAllPaymentByUser(userid) {
                try {

                    this.isLoading = true
                    this.desserts = []
                    const response = await this.$http.post('/fetch-wallets-byuser', {
                        user_id: userid
                    })
                    this.isLoading = false
                    
                    this.contractsData = response.data.wallet_list
                    this.contractQty = this.contractsData.length
                    this.contractsData.forEach(el => {
                        this.desserts.push({
                            contracttitle: el.contract_title,
                            payment_reference: el.payment_reference,
                            payment_mode: el.payment_method,
                            credit: el.credit.toLocaleString('en-NG', {
                                minimumIntegerDigits: 2
                            }),
                            debit: el.debit.toLocaleString('en-NG', {
                                minimumIntegerDigits: 2
                            }),
                            payment_date: new Date(el.created_at).toUTCString(),
        
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
       
        }
    
    }
    </script>
      
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
    
    .quill-container {
        height: 200px;
        /* Set the desired height here */
    }
    .copy-refid{
        cursor: pointer;
        font-size: 16px;
      }
    .a-text {
        font-size: 12px;
        font-family: Poppins;
    }
    .process-payment{
        cursor:pointer;
    
    }
    label {
        font-size: 12px;
        font-family: Poppins;
        font-weight: normal;
    }
    
    .inputs {
        margin-top: 20px;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .price-fee {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        div {
            margin: 4px;
            width: 100%;
    
        }
    }
    
    .adjust-fee-input {
        // width: 100px;
        text-align: center;
    }
    
    .whopays-fee-input {
        //width: 100% !important;
    }
    
    .shop-detail {
        font-size: 12px;
        font-family: Poppins;
    }
    
    .btn-danger {
        text-transform: uppercase;
        font-size: 13px;
        font-family: Poppins;
        background: #2979FF;
        border: 0px;
        height: 45px;
    }
    
    .text-header {
        padding: 0.5rem;
        font-size: 14px;
        font-weight: bold;
        font-family: Poppins;
        background: #ccc;
    }
    
    .contract-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #2979FF;
        color: #fff;
        border-radius: 4px;
        padding: 0.8rem;
        font-size: 14px;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: 5px;
    }
    
    .welcome {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        color: #170e0fac;
    }
    
    .account-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        //padding: 0.9rem;
        background: #fff;
        width: 98%;
    }
    
    .account-balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #780712;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .wallet-detail {
        padding-top: 1rem;
        line-height: 1.4px;
        font-size: 15px;
    
        h4 {
            font-weight: 650;
        }
    
        .acct-d {
            line-height: 0.8;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    
    .wallet-icon {
        font-size: 60px
    }
    
    .fund-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #076378;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .inputs-field {
        font-size: 14px;
        font-family: Poppins;
        height: 45px;
    }
    
    .content-center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 1px #170e0fac;
        border-radius: 4px;
        padding: 0.5rem;
        margin: 4px;
    }
    
    .box1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .image-input {
        margin-top: 10px;
    }
    
    .box-icon {
        padding-left: 0.4rem;
        font-size: 35px;
        color: #637807;
    }
    
    .box-details {
        font-size: 14px;
        color: #170e0fac;
    }
    
    .box1:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box2:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box3:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box4:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box5:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box6:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    
    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;
    
        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 10px;
            text-transform: capitalize;
            color: #ff4d63;
        }
    
        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }
    
    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        color: rgb(237, 138, 108);
        margin-right: 10px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            ;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Poppins;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Poppins;
        }
    }
    
    .account-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;
    }
    
    .account-content-h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        margin: 10px;
        padding-top: 1.3rem;
        text-transform: uppercase;
        text-align: center;
        //text-decoration: underline;
    }
    
    .add-fund {
        font-size: 12px;
        text-decoration: none !important;
        text-transform: capitalize;
    }
    
    .account-content-d {
        padding-top: 1.3rem;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .btn-secondary {
        text-transform: uppercase;
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .shop-name {
        //font-weight: 550;
        color: #000;
        font-family: Poppins;
    }
    
    @media screen and (max-width: 768px) {
        .contract-header {
    
            font-size: 12px !important;
    
        }
    
        .counter {
            font-size: 13px;
            font-weight: 500 !important;
        }
    
        .welcome {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            color: #170e0fac;
        }
    
        .content-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0.9rem;
        }
    
        .image-input {
            margin-top: 20px;
        }
    
        .price-fee {
            display: flex;
            flex-direction: column;
    
            div {
                width: 100%;
            }
        }
        .margin-mobile{
            margin-top: 90px;;
        }
    
        .adjust-fee-input {
            width: 100%;
        }
    
        .account-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            width: 100%;
        }
    
        .account-balance {
            margin-bottom: 10px;
        }
    
        .box-details {
            padding: 0.4rem;
            line-height: 1.4px;
    
            .title {
                font-family: Poppins;
                font-weight: 550;
                font-size: 8px;
                text-transform: capitalize;
                color: #ff4d63;
            }
    
            .description {
                font-family: Poppins;
                font-size: 12px;
            }
        }
    
        .inputs {
            font-size: 12px;
        }
    
        .box1 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box2 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box3 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box4 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box5 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .continue {
            display: flex;
            flex-direction: row;
            justify-content: right;
            padding-right: 0.4rem;
            font-size: 30px;
            margin-top: -10px;
            color: rgb(237, 138, 108);
        }
    
        .modal-close-mobile {
            display: block;
        }
    
        .modal-close {
            display: none;
        }
    
        .account-content {
            margin: 10px;
            margin-right: 30px;
        }
    
    }
    </style>
    