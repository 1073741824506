<template>
    <div class="container-fluid">
        <p class="welcome"></p>
         <div class="col-lg-12 col-sm-12 pt-3 dashboard-page">             
              <div class="col-lg-6 col-sm-6 pt-3">
                <div @click="go_back()" style="cursor:pointer" class="font-weight-normal back-button mb-4"> 
                    Back
                </div>
                <div class="dashboard-inner-new" v-if="!isShowUserSubscription">
                    <div class="panel-title mt-4">
                        <!-- <p class="ptitle">{{service}}</p> -->
                    </div>
                    <div class="airtime-data">
                        <button class="airtime1 ml-2" >Electricity</button>
                    </div>
                    <div class="payment-method mt-4">
                        <label for="" class="payment-label">Select Payment Method</label>
                        <select name="" id="" class="payment-input" v-model="payment_method">
                            <option value="">-Select-</option>
                            <option value="wallet">Zedlocker Wallet (N{{ walletBal.toLocaleString('en-NG', {
                                minimumIntegerDigits: 2
                            }) }})</option>
                            <!-- <option value="quick-payment">Quick Payment(Bank Transfer)</option> -->
                        </select>
                    </div>
                    <div class="payment-method " >
                            <v-autocomplete
                            required
                            v-model="company_package"
                            label="Select Electricity Service *"
                            :items="dataList"
                            variant="outlined"
                            item-value="value"
                            item-text="text"
                            >
                            <!-- <template v-slot:item="data">
                            <v-list-item>
                                <v-list-item-avatar>
                                <img :src="data.item.logo" :alt="data.item.text" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            </template> -->

                            <!-- Slot for displaying the selected item with image -->
                            <!-- <template v-slot:selection="data">
                            <v-list-item>
                                <v-list-item-avatar>
                                <img :src="data.item.logo" :alt="data.item.text" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            </template> -->
                        </v-autocomplete>
                    </div>
                    <div class="payment-method">
                        <label for="" class="payment-label">Meter Type</label>
                        <select name="" id="" class="payment-input" v-model="meter_type">
                            <option value="">-Select-</option>
                            <option value="prepaid">Prepaid</option>
                            <option value="postpaid">Postpaid</option>
                        </select>
                    </div>
                    <div class="payment-method">
                        <label for="" class="payment-label">Meter Number</label>
                        <input type="text" name="" id="" class="payment-input" placeholder="E.g. 2233561782" inputmode="numeric" pattern="[0-9]*" v-model="meter_number" @keypress="onlyNumber($event)">
                    </div>

                    <div class="payment-method">
                        <label for="" class="payment-label">Amount</label>
                        <input type="text" name="" id="" class="payment-input" placeholder="E.g. 10000" inputmode="numeric" pattern="[0-9]*" v-model="amount" @keypress="onlyNumber($event)">
                    </div>
                   
                    <!-- <div class="payment-method" v-if="is_loading_tv">
                       <center><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> loading packages... </center> 
                    </div> -->
                   
                    <div class="btn-panel">
                        <button id="submit4" class="btn-continue"  @click="continue_operation()" v-if="!loading5">Continue</button>
                        <button class="btn-continue" type="button" disabled v-if="loading5">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                        <button class="btn-cancel" @click="go_back()">Cancel</button>
                    </div>
                    
                </div>
                <div class="dashboard-inner-new" v-if="isShowUserSubscription">
                    <div class="panel-title mt-4">
                        <!-- <p class="ptitle">{{service}}</p> -->
                    </div>
                    <div class="airtime-data">
                        <button class="airtime1 ml-2" >Customer Info</button>
                    </div>
                    <div class="payment-method">
                        <label for="" class="payment-label">Customer name</label>
                        <input type="text" name="" id="" class="payment-input a-text"  v-model="subscription_details.Customer_Name" disabled>
                    </div>
                    <div class="payment-method">
                        <label for="" class="payment-label">Address</label>
                        <input type="text" name="" id="" class="payment-input a-text"  v-model="subscription_details.Address" disabled>
                    </div>
                    <!-- <div class="payment-method">
                        <label for="" class="payment-label">Meter number</label>
                        <input type="text" name="" id="" class="payment-input"  v-model="subscription_details.MeterNumber" disabled>
                    </div> -->
                   
                    <div class="payment-method">
                        <label for="" class="payment-label">Account type</label>
                        <input type="text" name="" id="" class="payment-input a-text" v-model="subscription_details.Customer_Account_Type" disabled>
                    </div>
                    
                    <div class="payment-method">
                        <label for="" class="payment-label">Meter type</label>
                        <input type="text" name="" id="" class="payment-input a-text" v-model="subscription_details.Meter_Type" readonly>
                    </div>
                    <div class="payment-method">
                        <label for="" class="payment-label">Amount</label>
                        <input type="text" name="" id="" class="payment-input a-text" v-model="display_amount" readonly>
                    </div>
                    <div class="payment-method">
                       <center> <span for="">Convenience fee: N{{ service_fee }}</span></center>
                    </div>
                    
                    <div class="btn-panel">
                        <button id="submit4" class="btn-continue"  @click="subscribe_operation()">Subscribe</button>
                        <button class="btn-cancel" @click="go_back1()">Back</button>
                    </div>
                    
                </div>
              </div>
         </div>
         <div v-if="isConfirm">
            <transition name="modal">
                <div class="vcard-modal-mask">
                    <div class="modal-wrapper col-md-3" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row d-flex flex-column align-items-center">
                                    <p class="text-black text-uppercase text-center mt-6">Enter your transaction pin</p>
                                    <div class="inputs1"> 
                                        <input type="password" maxlength="1" class="form-control inputs-field1" inputmode="numeric" pattern="[0-9]*" autocomplete="off"  v-model="verify_code1" required @input="moveToNext(1)" ref="input1" name="input1" id="input1" @keypress="onlyNumber($event)">
                                        <input type="password" maxlength="1" class="form-control inputs-field1" inputmode="numeric" pattern="[0-9]*" autocomplete="off" v-model="verify_code2" required @input="moveToNext(2)" ref="input2" name="input2" id="input2" @keypress="onlyNumber($event)">
                                        <input type="password" maxlength="1" class="form-control inputs-field1" inputmode="numeric" pattern="[0-9]*" autocomplete="off" v-model="verify_code3" required @input="moveToNext(3)" ref="input3" name="input3" id="input3" @keypress="onlyNumber($event)">
                                        <input type="password" maxlength="1"  class="form-control inputs-field1" inputmode="numeric" pattern="[0-9]*" autocomplete="off" v-model="verify_code4" required @input="moveToNext(4)" ref="input4" name="input4" id="input4" @keypress="onlyNumber($event)">
                                    </div>
                                
                                    <div class="btn-inputs">
                                        <button id="submit4" class="btn btn-primary btn-block mt-4 mb-4"  @click="confirm_purchase()" v-if="!loading4">Confirm</button>
                                        <button class="btn btn-primary btn-block mt-4 mb-4" type="button" disabled v-if="loading4">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                        <button class="btn btn-block" @click="isConfirm = false">X</button>
                                        <br>
                                    </div>     
                                </div>
                            </div>
                            <div class="col-md-1">
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
         </div>
         <div v-if="isToken">
            <transition name="modal">
                <div class="vcard-modal-mask">
                    <div class="modal-wrapper col-md-3" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row d-flex flex-column align-items-center">
                                    <p class="text-black text-uppercase text-center mt-6">Copy your purchase token</p>
                                    <div class="inputs1">                                         
                                           <span class=" font-weight-bold" style="font-size: 14px">1234545575675675</span> 
                                    </div>
                                    <div class="btn-inputs">
                                        <button class="btn btn-block" @click="go_back">X</button>
                                        <br>
                                    </div>     
                                </div>
                            </div>
                            <div class="col-md-1">
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
         </div>
          <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "AirtimeDataPage",
    components: {
      PageLoader
    },
  
    data(){
      return {
          search: '',
          loading: false,
          isLoading: false,
          loading4: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          funding_amount: '',
          error: '',
          payment_reference: '',
          fullname: '',
          cpayment_method: 'regular-payment',
          utility_option: '',  
          service: '', 
          company: '',
          payment_method: '',
          meter_number: '',
          airtime_amount: '',   
          walletBal: '',
          quick_amount: '',
          dataList: [],
          is_plan_available: false,
          company_package: '',
          plan_name: '',
          verify_code: '',
          verify_code1: '',
          verify_code2: '',
          verify_code3: '',
          verify_code4: '',
          isConfirm: false,
          is_loading_tv: false,
          isShowUserSubscription: false,
          subscription_details: [],
          loading5: false,
          amount: '',
          display_amount: '',
          meter_type: '',
          token: '',
          isToken: false,
          service_fee: 100,
        };
    },
  
    beforeMount(){
      this.user_id = localStorage.getItem("userid");
    },
  
    mounted(){
        
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.fullname = this.firstname +'_'+ this.lastname 
        
        this.get_wallet_balance(this.user_id)
        this.fetch_user_pin()
        this.select_company()
    },
  
    created(){
          
    },
  
    computed: {
    
    },
  
    methods: {
        onlyNumber(event) {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    },
        async fetch_user_pin(){
            try {
                const response = await this.$http.post('/fetch-pin', {
                    user_id: this.user_id
                })         
                this.user_pin = response.data.data

            } catch (err) {
                console.log(err);
            }
        },

        moveToNext(index) {
            const verifyCode = this[`verify_code${index}`];
            if (verifyCode.length === 1) {
                const nextIndex = index + 1;
                if (nextIndex <= 4) {
                    this.$refs[`input${nextIndex}`].focus();
                }
            }
        },
        handlePaste(event) {
        event.preventDefault(); // Prevent the default paste behavior
        const pasteData = event.clipboardData.getData('text');
        if (pasteData.length === 4) {
            this.verify_code1 = pasteData[0];
            this.verify_code2 = pasteData[1];
            this.verify_code3 = pasteData[2];
            this.verify_code4 = pasteData[3];

            // Move focus to the last input field
            this.$nextTick(() => {
            this.$refs.input4.focus();
            });
            }
        },
       async select_company(){
                    try{
                        this.dataList = []

                        const response = await this.$http.get('/list-electricity-companies', {
                        });

                        if (response.data.success) {
                            response.data.data.forEach(el => {
                                this.dataList.push({
                                    text: el.name,
                                    value: el.merchant_code,
                                    logo: el.logo
                                })
                            });  

                            return;  
                        }
                        else if (!response.data.success) {
                            swal.fire({
                                icon: 'error',
                                title: 'Error',
                                width: 450,
                                height: 5,
                                text: 'Could not data!',
                            })
                            return;
                        }         
                        
                }catch (err) {
                    console.log(err);
                }            
       },

        quick_option(option){
            this.airtime_amount = option
            this.quick_amount = option
        },
        async get_wallet_balance(){
                try{
                        const response = await this.$http.post('/fetch-wallet-balance', {
                            userid: this.user_id,
                        });
                        this.walletBal = response.data.balance
                        return;              
                        
                }catch (err) {
                    console.log(err);
                }
       },

        validateInput(){
            const value = this.funding_amount;
            if (value < 0) {
            this.error = 'Value must be non-negative';
            this.funding_amount = ''
            return
            } else if (!Number.isInteger(Number(value))) {
            this.error = 'Value must be an integer';
            this.funding_amount = ''
            return
            } else {
            this.error = '';
            }
        },

        async continue_operation(){

            if (!this.payment_method) {
            swal.fire({
                icon: 'warning',
                title: 'Warning',
                width: 450,
                height: 5,
                text: 'Please select payment method',
            })
            return;
            }
            if (!this.company_package) {
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please select operator',
                })
                return;
            }
            if (!this.meter_type) {
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please select meter type',
                })
                return;
            }
            if (!this.meter_number) {
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please enter meter number',
                })
                return;
            }
            if (!this.amount) {
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please enter amount',
                })
                return;
            }
            this.display_amount = Number.parseInt(this.amount) + Number.parseInt(this.service_fee)
            this.loading5 = true

            const response = await this.$http.post('/verify-meter-number', {
                meter_number: this.meter_number,
                meter_type: this.meter_type,
                company: this.company_package,
            })
            this.loading5 = false
            if (response.data.success) {
                if(response.data.error == null){
                    this.isShowUserSubscription = true
                    this.subscription_details = response.data.data
                    return;
                }else{
                    swal.fire({
                    icon: 'error',
                    title: 'Invalid Meter Number',
                    width: 450,
                    height: 5,
                    text: response.data.error,
                })
                return;
                }
                
            }
            else{
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                return;
            }
        },
        subscribe_operation(){
            this.isConfirm = true
        },

        async confirm_purchase() {
            try {              
                
                    if (!this.payment_method) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please select payment method',
                    })
                    return;
                    }
                    if (!this.company_package) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please select operator',
                    })
                    return;
                }
                if (!this.meter_type) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please select meter type',
                    })
                    return;
                }
                if (!this.meter_number) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please enter meter number',
                    })
                    return;
                }
                if (!this.amount) {
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please enter amount',
                })
                return;
                }
                    if (this.payment_method == 'quick-payment') {
                        this.loading4 = true
                        const verify_code = this.verify_code1+''+this.verify_code2+''+this.verify_code3+''+this.verify_code4
                            const response = await this.$http.post('/purchase-electricity', {
                            meter_number: this.meter_number,
                            meter_type: this.meter_type,
                            amount: this.amount,
                            company: this.company_package,
                            userid: this.user_id,
                            verify_code: verify_code,
                            payment_method: this.payment_method,

                        })
                        this.loading4 = false

                        if (!response.data.success) {
                            swal.fire({
                                icon: 'warning',
                                title: 'Warning',
                                width: 450,
                                height: 5,
                                text: response.data.message,
                            })
                            return;
                        }
                        if (response.data.success) {
                            this.$router.push('/utility-payment/'+this.utility_option+'/'+this.company_package +'/'+this.company+'/'+this.meter_number)
                            return;                        
                        }
                      
                    }
                    else{
                        this.loading4 = true

                        const verify_code = this.verify_code1+''+this.verify_code2+''+this.verify_code3+''+this.verify_code4

                        const response = await this.$http.post('/purchase-electricity', {
                            meter_number: this.meter_number,
                            meter_type: this.meter_type,
                            amount: Number.parseInt(this.amount) + Number.parseInt(this.service_fee),
                            company: this.company_package,
                            userid: this.user_id,
                            verify_code: verify_code,
                            payment_method: this.payment_method,
                        })
                        this.loading4 = false

                        if (!response.data.success) {
                            swal.fire({
                                icon: 'warning',
                                title: 'Warning',
                                width: 450,
                                height: 5,
                                text: response.data.message,
                            })
                            return;
                        }

                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })

                        this.token = response.data.data.purchase_token

                        if (this.meter_type == 'prepaid') {
                            this.isToken = true
                        } 
                        else if (this.meter_type == 'postpaid') {
                            this.$router.push('/home')
                        }
                        return;
                    }
                             
            } catch (err) {
                console.log(err);
            }
        },

        async bank_transfer(){
            try {
                    if (!this.funding_amount) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please enter amount to fund',
                    })
                    return;
                    }

                    this.loading = true
                    this.payment_reference = Date.now() % 100000
                    const full_name = this.firstname
                    const ref = full_name + '_' + this.payment_reference

                    if (this.cpayment_method == 'regular-payment') {

                    this.loading = false
                    this.$router.push('/bank-transfer/'+ref+'/'+this.funding_amount)
                    }
                    else if (this.cpayment_method == 'quick-payment') {
                    swal.fire({
                        icon: 'info',
                        title: 'Quick Payment!',
                        width: 450,
                        height: 5,
                        text: 'Not available at the moment',
                    })
                    this.loading = false
                    return;
                    }
                
                    
                    this.loading = false


                } catch (err) {
                    console.log(err);
                }
            
        },

        go_back(){
            this.$router.push('/home')
        },
        go_back1(){
            this.isShowUserSubscription= false
        },
        
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
  .a-text{
    color: #000 !important;
    font-weight: 500 !important;
  }
  .vcard-modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 90%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
  }
  .inputs1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border-radius: 3px;
    font-size:11px;
    font-weight: 400;

    .inputs-field1{
        font-size: 14px;
        font-weight: bold;
        font-family: Poppins;
        text-align: center;
        height: 40px;
        width: 100%;
        margin: 5px;
        border: solid 1px;
    }
}
.btn-primary{
    width: 100%;
    height: 52px;
    padding: 0px 36.19px 0px 36.19px;
    border-radius: 9.05px;
    background: #2879FD;
    color: #fff;
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
}
  .error{
    color: #fff;
    font-size: 10px;
  }
  .copy-refid1{
    cursor: pointer;
    //font-size: 20px;
  }
   
  .welcome{
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    padding: 0px 20px;
    text-align: left;
    color: #170e0fac;
  }
   .dashboard-page{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: #fff;
      padding: 0.6rem;
      font-family: Poppins;
      strong{
        font-weight: normal;
        font-family: Poppins;
      }
   }
 
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 18px 35px 18px 35px;
    gap: 10px;
    border-radius: 16px;
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .back-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 40px;
    border-radius: 102px;
    background: var(--Color-4, #D9D9D9);
    color: #fff;
   }
   .panel-title{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

    .ptitle{
        //font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        text-align: left;
    }
   }
   .airtime-data{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
   }
   .airtime{
    width: 140px;
    height: 45px;
    padding: 10px 39px 10px 39px;
    border-radius: 8px;
    border: 1px solid #4281FE;
    background: #ECF3FF;
    color: #4281FE;
    //font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
   }
   .airtime1{
    width: 100%;
    height: 45px;
    padding: 10px 39px 10px 39px;
    border-radius: 8px;
    background: #4281FE;
    color: #D9D9D9;
    cursor: none;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
   }
   .data{
    width: 140px;
    height: 45px;
    padding: 10px 39px 10px 39px;
    border-radius: 8px;
    border: 1px solid #4281FE;
    background: #ECF3FF;
    color: #4281FE;
     //font-family: Montserrat;
     font-size: 15px;
     font-weight: 500;
     text-align: center;
   }
   .data1{
    width: 140px;
    height: 45px;
    padding: 10px 39px 10px 39px;
    border-radius: 8px;
    background: #ECF3FF;
    color: #D9D9D9;
     //font-family: Montserrat;
     font-size: 15px;
     font-weight: 500;
     text-align: center;
   }
   .payment-method{
        display: flex;
        flex-direction: column;
        justify-content: left;
        //align-items: center;
   }
   .payment-label{
    //font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.07px;
    text-align: left;
    color:  #7F7F7F;
   }
   .payment-input{
    width: 100%;
    height: 45px;
    padding: 13px 18px 13px 18px;
    border-radius: 8px;
    background: #ECF3FF;
    color:  #7F7F7F;
   }
   .mobile-operator{
    display: flex;
    flex-direction: column;
    justify-content: left;
    }
    .operator-icons{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        .op-icons{
            width: auto;
            height: 55px;
            padding: 1px 2px 1px 2px;
            border-radius: 8px;
            border: 1px solid #E8EBF0;
            margin: 4px;
        }
    }
    
    .op-icons.selected {
        border-color: #2879FD; /* Change to your desired border color */
    }
   
    .quick-option{
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        color:  #7F7F7F;
        font-size: 13px !important;
       // font-weight: 400;
       .quick-amount{
            width: 65px;
            height: 28px;
            padding: 5px 13px 5px 13px;
            border-radius: 8px;
            margin: 4px;
            background: #ECF3FF;
            border: 1px solid #E8EBF0;
        }
       
    }
    .quick-amount.selected {
        border-color: #2879FD; /* Change to your desired border color */
    }
    .btn-panel{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
   
    .btn-continue{
        width: 300px;
        height: 52px;
        padding: 0px 36.19px 0px 36.19px;
        border-radius: 9.05px;
        background: #2879FD;
        color: #fff;
        text-transform: uppercase;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
    }
    .btn-cancel{
        width: 300px;
        height: 52px;
        padding: 0px 36.19px 0px 36.19px;
        border-radius: 9.05px;
        background: #fff;
        color: #000;
        text-transform: uppercase;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 17.07px;

    }

  
  @media screen and (max-width: 768px) {
    .welcome{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      padding: 0px 15px;
      text-align: left;
      color: #170e0fac;
    }
  
    .dashboard-page{
      display: flex;
      flex-direction: column;
      strong{
        margin-top: 10px;
      }
   }
   .dashboard-inner{
    margin: 0px;
    margin-top: 10px;
    padding: 0.8rem;
   }
   .inputs1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border-radius: 3px;
    font-size:11px;
    font-weight: 400;

    .inputs-field1{
        font-size: 14px;
        font-weight: bold;
        font-family: Poppins;
        text-align: center;
        height: 40px;
        width: 50px;
        margin: 5px;
        border: solid 1px;
    }
}
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.6rem;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .quick-option{
    font-size: 11px !important;
    span{
        width: 100%;
    }
   button{
        width: 60px;
        padding: 0px 10px 0px 10px;
        border-radius: 8px;
        margin: 3px;
        }
    }
    
    .btn-continue{
        width: 100%;
        font-size: 13px;
    }
    .btn-cancel{
        width: 100%;;
        font-size: 13px;
    }
    
  }
  
  </style>