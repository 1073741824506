<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="scss" scoped>
table.v-table thead th {
  font-size: 20px !important;
}
table.v-table tbody td {
  font-size: 12px !important;
}
</style>
