<template>
    <div class="container-fluid">
        <p>&nbsp;</p>
        <div class="account-details">
            <div class="col-sm-12">
                <div class="contract-header">
                    <div>EDIT CONTRACT </div>
                    <div> <i class="fa fa-arrow-left" @click="view_contracts" style="cursor:pointer;"> List
                            Contracts</i></div>
                </div>
                <form enctype="multipart/form-data">

                    <div class="inputs price-fee">
                        <v-autocomplete required v-model="role_type" label="Whats is your role *" :items="roleType"
                            variant="outlined"></v-autocomplete>
                        <v-autocomplete required v-model="transaction_type" label="Transaction Type *"
                            :items="transactionType" variant="outlined"></v-autocomplete>
                    </div>
                    <div class="inputs">
                        <label for=""> Contract title *</label>
                        <input type="text" class="form-control inputs-field" placeholder="Enter contract title"
                            v-model="contract_title" required>
                    </div>
                    <div class="inputs">
                        <label for=""> Contract description/expectations *</label>
                        <quill-editor class="quill-container" v-model="product_description"
                            :options="editorOptions"></quill-editor>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div class="inputs price-fee margin-mobile">
                        <div>
                            <label for=""> Transaction amount *</label>
                            <input type="text" class="form-control inputs-field" placeholder="Enter contract amount"
                                v-model="contract_amount" required @input="fetchContractFees(contract_amount)">
                        </div>
                        <div>
                            <label>Escrow service charge(<span style="color: red">who bears the escrow fee?</span>)</label>
                            <input type="text" class="form-control inputs-field" v-model="transaction_fee" required
                                readonly>
                        </div>

                        <!-- <div>
                            <label for=""> Swift Response Rebate </label>
                            <input type="text" class="form-control inputs-field" v-model="contract_rebate" required
                                readonly>
                        </div> -->
                        <div>
                            <label>Fees handling *</label>
                            <select name="" id="" class="form-control inputs-field whopays-fee-input"
                                v-model="whois_paying">
                                <option value="">-Select-</option>
                                <option value="buyer">Buyer 100%</option>
                                <option value="seller">Seller 100%</option>
                                <option value="both">50/50 split</option>
                            </select>
                        </div>

                    </div>

                    <div class="inputs price-fee">
                        <div>
                            <label>Delivery type *</label>
                            <select name="" id="" class="form-control inputs-field" v-model="delivery_type" @change="select_durations()">
                                <option value="">-Select-</option>
                                <option value="1">Same day delivery(12hrs)</option>
                                <option value="2">Interstate</option>
                            </select>
                        </div>
                        <div>
                            <label>Delivery location *</label>
                            <input type="text" class="form-control inputs-field" name="" id=""
                                v-model="delivery_location">
                        </div>
                       
                        <div>
                            <label>Delivery duration {{label1}}*</label>
                            <input type="text" class="form-control inputs-field" name="" id="" v-model="delivery_time" v-if="duration_twelve" readonly>
                            <select name="" id="" class="form-control inputs-field" v-model="delivery_time"
                                @change="selectDays()" v-if="duration_twelve1" >
                                <option value="">-Select-</option>
                                <option value="day">Day(s)</option>
                                <option value="week">Week(s)</option>
                                <option value="month">Month(s)</option>
                            </select>
                        </div>
                        <div v-if="isPeriod">
                            <label>Enter period *</label>
                            <input type="number" class="form-control inputs-field" name="" id=""
                                v-model="delivery_period">
                        </div>
                    </div>
                    <!-- <div class="inputs">              
                  <label for=""> Conditions for acceptance of goods or services  </label>
                  <textarea class="form-control" rows="3" cols="5" placeholder="Enter acceptance conditions"></textarea>
    
                </div> -->

                    <div class="image-input">
                        <div class="inputs">
                            <table class="table table-stripped table-bordered">
                                <thead>
                                    <th>Document</th>
                                    <th>Edit</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img width="50" height="50"
                                                :src="img_url + 'contractDocuments/' + contract_document" alt=""
                                                style="border-radius: 4px">
                                        </td>
                                        <td>
                                            <input type="file" ref="fileInput" id="image_input" class="file-input"
                                                @change="pickFile" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="inputs">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" value="" v-model="accept_terms">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I accept the terms and conditions of this contract
                        </div>
                    </div>

                </form>

                <div class="inputs" v-if="contract_status != 1">
                    <button id="submit2" class="btn btn-danger btn-block" @click="updateContract()"
                        v-if="!loading">update contract</button>
                    <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <p>&nbsp;</p>
                </div>
                <div class="inputs" v-else-if="contract_status == 1">
                    <button id="submit2" class="btn btn-danger btn-block disabled"
                        >update contract</button>
                   
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>

        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>


<script>
import 'quill/dist/quill.snow.css';
import {
    quillEditor
} from 'vue-quill-editor';
import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {

    name: "ProductPage",
    components: {
        quillEditor,
        PageLoader
    },

    data() {
        return {

            isLoading: false,
            isRightImage: false,
            isLeftImage: false,
            isBackImage: false,

            user_id: '',
            firstname: '',
            lastname: '',
            bank_name: '',
            account_name: '',
            account_number: '',

            loading: false,
            loading1: false,
            loading2: false,
            loading3: false,
            role_type: '',
            contract_title: '',
            transaction_type: '',
            delivery_type: '',
            delivery_location: '',
            delivery_time: '',
            contract_amount: '',
            transaction_fee: '',
            whois_paying: '',
            contract_rebate: '',
            product_description: '',
            accept_terms: '',

            contracts: [],
            subcategorys: [],
            selectedFile: '',
            selectedFile1: '',
            selectedFile2: '',
            selectedFile3: '',
            addProduct: false,
            viewProduct: true,
            editorOptions: {
                placeholder: 'Write something...',
            },

            isVariations: false,
            productid: '',
            variations: '',
            vprice: '',
            is_fees_status: false,
            is_submit: false,
            is_submit1: false,
            is_submit2: false,
            is_submit3: false,
            img_url: process.env.VUE_APP_IMAGE_URL,

            desserts: [],
            user_role: '',
            product_id: '',
            userid: '',
            contractQty: '',
            deliveryType: [{
                text: 'Same day delivery (14 hours max)',
                value: 'Same day delivery (14 hours max)'
            },
            {
                text: 'Interstate',
                value: 'Interstate'
            },
            ],

            transactionType: [{
                text: 'Physical Product',
                value: 'Physical Product'
            },
            {
                text: 'Services',
                value: 'Services'
            },
            {
                text: 'Digital Product',
                value: 'Digital Product'
            },
            {
                text: 'Mutual Contract',
                value: 'Mutual Contract'
            },
            ],
            roleType: [{
                text: 'Seller',
                value: 1
            },
            {
                text: 'Buyer',
                value: 2
            },
            ],

            contractsData: [],
            isPeriod: false,
            delivery_period: '',
            contract_id: '',
            contract_document: '',
            error: '',
            error1: '',
            duration_twelve: false,
            duration_twelve1: true,
            label1: '',
            contract_status: '',
            payment_status: '',
            delivery_status: '',

        };
    },

    beforeMount() {

    },

    mounted() {

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");

        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.user_role = localStorage.getItem("user_role");
        this.fetchContractById(this.$route.params.id)

    },

    created() {

    },

    computed: {


        indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
        },

    },

    methods: {

        async fetchContractById(contract_id) {
            try {

                this.isLoading = true
                const response = await this.$http.post('/fetch-contract-byid', {
                    contract_id: contract_id
                })
                this.isLoading = false
                this.contracts = response.data.data[0]

                this.role_type = this.contracts.role_type,
                    this.transaction_type = this.contracts.transaction_type
                this.contract_title = this.contracts.contract_title
                this.product_description = this.contracts.contract_description
                this.contract_amount = this.contracts.amount
                this.transaction_fee = this.contracts.transaction_fee
                this.contract_rebate = this.contracts.rebate_fee
                this.whois_paying = this.contracts.whois_paying
                this.delivery_type = this.contracts.delivery_type
                this.delivery_location = this.contracts.delivery_location
                this.delivery_time = this.contracts.delivery_time
                this.delivery_period = this.contracts.delivery_period
                this.accept_terms = this.contracts.terms_conditions
                this.contract_id = this.contracts.contractid
                this.contract_document = this.contracts.documents
                this.contract_status = this.contracts.accept_contract
                this.payment_status = this.contracts.contract_status
                this.delivery_status = this.contracts.delivered

                if (this.contracts.delivery_time) {
                    this.isPeriod = true
                }

                if (this.contracts.delivery_type == 1) {
                    this.duration_twelve = true
                    this.duration_twelve1 = false
                    this.isPeriod = false
                    this.delivery_time = 12
                    this.label1 = '(hrs)'
                }
                if (this.contracts.delivery_type == 2) {
                    this.duration_twelve1 = true
                    this.duration_twelve = false
                    this.delivery_time = ''
                    this.label1 = ''
                }

            } catch (err) {
                console.log(err);
            }
        },

        async updateContract() {
            try {

                if (this.delivery_time != '') {

                    if (!this.delivery_period) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            width: 450,
                            height: 5,
                            text: 'Please enter delivery period',
                        })
                        this.loading = false
                        return;
                    }
                }

                this.loading = true

                    const formData = new FormData();
                    formData.append('images', this.selectedFile),
                    formData.append('role_type', this.role_type),
                    formData.append('transaction_type', this.transaction_type),
                    formData.append('contract_title', this.contract_title),
                    formData.append('delivery_type', this.delivery_type),
                    formData.append('delivery_location', this.delivery_location),
                    formData.append('delivery_time', this.delivery_time),
                    formData.append('delivery_period', this.delivery_period),
                    formData.append('contract_amount', this.contract_amount),
                    formData.append('transaction_fee', this.transaction_fee),
                    formData.append('contract_rebate', this.contract_rebate),
                    formData.append('whois_paying', this.whois_paying),
                    formData.append('accept_terms', this.accept_terms),
                    formData.append('product_description', this.product_description),
                    formData.append('addedby', this.user_id),
                    formData.append('contractid', this.contract_id),

                    this.response = await this.$http.post('/update-contract', formData

                    );
                this.loading = false

                if (!this.response.data.success) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })
                    return;
                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    })

                    this.view_contracts()
                    return;
                }
            } catch (err) {
                console.log(err);
            }
        },

        select_durations(){
            if (this.delivery_type == 1) {
                this.duration_twelve = true
                this.duration_twelve1 = false
                this.isPeriod = false
                this.delivery_time = 12
                this.label1 = '(hrs)'
            }else if(this.delivery_type == 2){
                this.duration_twelve1 = true
                this.duration_twelve = false
                this.delivery_time = ''
                this.label1 = ''
            }
        },
        selectDays() {
            if (this.delivery_time == '') {
                this.isPeriod = false
            } else {
                this.isPeriod = true
            }
        },

        formatToCurrency(value) {
            return value.toLocaleString('en-NG', { minimumFractionDigits: 2 })
        },

        async fetchContractFees(amount) {
            try {

                this.is_fees_status = true
                const response = await this.$http.post('/fetch-fees', {
                    contract_amount: amount,
                })

                this.contract_amount = this.formatToCurrency(amount);
                this.transaction_fee = this.contract_amount * (response.data.fees[0].per / 100)
                this.contract_rebate = this.contract_amount * (0.5 / 100)

                this.is_fees_status = false

            } catch (err) {
                console.log(err);
            }
        },


        copyToClipboard(link) {
            // var copyText = document.getElementById("myInput");
            navigator.clipboard.writeText(link).then(function () {
                alert("Contract link copied to clipboard!");
            }).catch(function (err) {
                console.error('Failed to copy: ', err);
            });
        },

        pickFile() {
            this.selectedFile = this.$refs.fileInput.files[0];
        },

        view_contracts() {
            this.$router.push('/contract')
        },

    }

}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

.quill-container {
    height: 200px;
    /* Set the desired height here */
}

.copy-refid {
    cursor: pointer;
    font-size: 16px;
}

.a-text {
    font-size: 11px;
    font-family: Poppins;
}

label {
    font-size: 12px;
    font-family: Poppins;
    font-weight: normal;
}

.inputs {
    margin-top: 20px;
    border-radius: 3px;
    font-size: 12px;
}

.price-fee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
        margin: 4px;
        width: 100%;

    }
}

.adjust-fee-input {
    // width: 100px;
    text-align: center;
}

.whopays-fee-input {
    //width: 100% !important;
}

.shop-detail {
    font-size: 12px;
    font-family: Poppins;
}

.btn-danger {
    text-transform: uppercase;
    font-size: 13px;
    font-family: Poppins;
    background: #2979FF;
    border: 0px;
    height: 45px;
}

.text-header {
    padding: 0.5rem;
    font-size: 12px;
    font-family: Poppins;
    background: #ccc;
}

.contract-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2979FF;
    color: #fff;
    border-radius: 4px;
    padding: 0.8rem;
    font-size: 14px;
    font-weight: 700;
    font-family: Poppins;
    margin-bottom: 5px;
}

.welcome {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    color: #170e0fac;
}

.account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    //padding: 0.9rem;
    background: #fff;
    width: 98%;
}

.account-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Poppins;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wallet-detail {
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;

    h4 {
        font-weight: 650;
    }

    .acct-d {
        line-height: 0.8;
        cursor: pointer;
        text-decoration: underline;
    }
}

.wallet-icon {
    font-size: 60px
}

.fund-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Poppins;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.inputs-field {
    font-size: 14px;
    font-family: Poppins;
    height: 45px;
}

.content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
}

.box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.image-input {
    margin-top: 10px;
}

.box-icon {
    padding-left: 0.4rem;
    font-size: 35px;
    color: #637807;
}

.box-details {
    font-size: 14px;
    color: #170e0fac;
}

.box1:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box2:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box3:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box4:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box5:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Poppins;

    a {
        text-decoration: none;
        color: #000;
    }
}

.box6:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
}

.img {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.box-details {
    padding: 0.4rem;
    line-height: 1.4px;

    .title {
        font-family: Poppins;
        font-weight: 550;
        font-size: 10px;
        text-transform: capitalize;
        color: #ff4d63;
    }

    .description {
        font-family: Poppins;
        font-size: 12px;
    }
}

.continue {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
}

.account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;
}

.account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
}

.add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
}

.account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
}

.btn-secondary {
    text-transform: uppercase;
}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

a {
    text-decoration: none;
}

.shop-name {
    //font-weight: 550;
    color: #000;
    font-family: Poppins;
}

@media screen and (max-width: 768px) {
    .contract-header {

        font-size: 12px !important;

    }

    .counter {
        font-size: 13px;
        font-weight: 500 !important;
    }

    .welcome {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: #170e0fac;
    }

    .content-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.9rem;
    }

    .image-input {
        margin-top: 120px;
    }

    .price-fee {
        display: flex;
        flex-direction: column;

        div {
            width: 100%;
        }
    }

    .margin-mobile {
        margin-top: 90px;
        ;
    }

    .adjust-fee-input {
        width: 100%;
    }

    .account-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 100%;
    }

    .account-balance {
        margin-bottom: 10px;
    }

    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;

        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 8px;
            text-transform: capitalize;
            color: #ff4d63;
        }

        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }

    .inputs {
        font-size: 12px;
    }

    .box1 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box2 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box3 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box4 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .box5 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        margin-top: -10px;
        color: rgb(237, 138, 108);
    }

    .modal-close-mobile {
        display: block;
    }

    .modal-close {
        display: none;
    }

    .account-content {
        margin: 10px;
        margin-right: 30px;
    }

}
</style>