<template>
    <div class="layout-container">
        <div class="main-container">
            <div class="sidebar">
                <div class="menu-sidebar-top">
                     <img src="../assets/user.svg" alt="ZEDLOCKER" ><br>
                     <p class="fullname pt-4">{{ firstname }}&nbsp; {{ lastname.slice(0,1) }}.</p>
                     <p class="email">{{ email }}</p>
                </div>
                <div class="menu-sidebar" v-if="user_role == 1">
                    <!-- <router-link class="sub-menu" to="/home"><i class="fa fa-home"></i>&nbsp;Home</router-link> -->
                    <router-link class="sub-menu" to="/home"><i class="fa fa-home"></i>&nbsp;Overview </router-link>
                    <!-- <router-link class="sub-menu" to="/vendor/orders"><i class="fa fa-book" aria-hidden="true"></i>&nbsp;Active contracts </router-link> -->
                    <router-link class="sub-menu" to="/contract"><i class="fa fa-tasks" aria-hidden="true"></i>&nbsp;Contract </router-link>
                    <router-link class="sub-menu" to="/payments"><i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;Payments  </router-link>
                    <router-link class="sub-menu" to="/disputes"><i class="fa fa-paw" aria-hidden="true"></i>&nbsp;Disputes  </router-link>
                    <router-link class="sub-menu" to="/wallet"><i class="fa fa-briefcase"></i>&nbsp;Wallet  </router-link>
                    <router-link class="sub-menu" to="/available-balance"><i class="fa fa-briefcase"></i>&nbsp;Zedlocker Account Balance  </router-link>
                    <router-link class="sub-menu" to="/bank"><i class="fa fa-bank"></i>&nbsp;Bank  </router-link>
                    <router-link class="sub-menu" to="/subscriptions"><i class="fa fa-database"></i>&nbsp;Subscriptions </router-link>
                    <router-link class="sub-menu" to="/all-escrows"><i class="fa fa-tasks"></i>&nbsp;All Escrows  </router-link>
                    <router-link class="sub-menu" to="/all-users"><i class="fa fa-users"></i>&nbsp;All Users  </router-link>
                    <router-link class="sub-menu" to="/all-disputes"><i class="fa fa-paw" aria-hidden="true"></i>&nbsp;All Disputes  </router-link>
                    <router-link class="sub-menu" to="/underpayments"><i class="fa fa-users"></i>&nbsp;Underpayments  </router-link>
                    <router-link class="sub-menu" to="/list-withdrawals"><i class="fa fa-bank"></i>&nbsp;Approve Withdrawals  </router-link>
                    <router-link class="sub-menu" to="/wallet-funding"><i class="fa fa-briefcase"></i>&nbsp;Approve Wallet Funding  </router-link>
                    <router-link class="sub-menu" to="/approve-payments"><i class="fa fa-credit-card"></i>&nbsp;Approve Payments  </router-link>
                    <router-link class="sub-menu" to="/profile"><i class="fa fa-user"></i>&nbsp;Profile  </router-link>
                    <router-link class="sub-menu" to="#"><i class="fa fa-headphones" aria-hidden="true"></i>&nbsp;Help Center  </router-link>
                    <div class="sub-menu" style="cursor: pointer" @click="vendorLogout()">&nbsp;<i class="fa fa-sign-out"></i> Logout</div>
                </div>
                <div class="menu-sidebar" v-else>
                    <!-- <router-link class="sub-menu" to="/home"><i class="fa fa-home"></i>&nbsp;Home</router-link> -->
                    <router-link class="sub-menu" to="/home"><i class="fa fa-home"></i>&nbsp;Overview </router-link>
                    <router-link class="sub-menu" to="/contract"><i class="fa fa-tasks" aria-hidden="true"></i>&nbsp;Contract </router-link>
                    <router-link class="sub-menu" to="/my-subscriptions"><i class="fa fa-database" aria-hidden="true"></i>&nbsp;My Subscriptions </router-link>
                    <router-link class="sub-menu" to="/payments"><i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;Payments  </router-link>
                    <router-link class="sub-menu" to="/disputes"><i class="fa fa-paw" aria-hidden="true"></i>&nbsp;Disputes  </router-link>
                    <router-link class="sub-menu" to="/wallet"><i class="fa fa-briefcase"></i>&nbsp;Wallet  </router-link>
                    <router-link class="sub-menu" to="/bank"><i class="fa fa-bank"></i>&nbsp;Bank  </router-link>
                    <router-link class="sub-menu" to="/profile"><i class="fa fa-user"></i>&nbsp;Profile  </router-link>
                    <router-link class="sub-menu" to="#"><i class="fa fa-headphones" aria-hidden="true"></i>&nbsp;Help Center  </router-link>
                    <div class="sub-menu" style="cursor: pointer" @click="vendorLogout()">&nbsp;<i class="fa fa-sign-out"></i> Logout</div>
                </div>
            </div>
    
            <div class="content">
                <div class="top-header">
                    <div class="notifications menu-bar">
                        <img src="../assets/bar.svg" alt="" @click="show_menu()">
                        <div class="submenu-bar" v-if="isMenuOpen && user_role == 1">
                            <div class=" d-flex flex-row justify-content-between font-weight-bolder"> <button class="close-menu" @click="close_menu()">X</button></div>
                            <div class="menu-sidebar-top">
                                <img src="../assets/user.svg" alt="">
                                <p class="fullname pt-4">{{ firstname }}&nbsp; {{ lastname.slice(0,1) }}.</p>
                                <p class="email">{{ email }}</p>
                            </div>
                            <div class="sub-menu1"  @click="close_menu('/home')"><i class="fa fa-home"></i>&nbsp;Overview </div>
                            <div class="sub-menu1"  @click="close_menu('/contract')"><i class="fa fa-tasks" aria-hidden="true"></i>&nbsp;Contracts </div>
                            <div class="sub-menu1"  @click="close_menu('/payments')"><i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;Payments  </div>
                            <div class="sub-menu1"  @click="close_menu('/disputes')"><i class="fa fa-paw" aria-hidden="true"></i>&nbsp;Disputes  </div>
                            <div class="sub-menu1"  @click="close_menu('/wallet')"><i class="fa fa-briefcase"></i>&nbsp;Wallet  </div>
                            <div class="sub-menu1"  @click="close_menu('/available-balance')"><i class="fa fa-briefcase"></i>&nbsp;Zedlocker Account Balance  </div>
                            <div class="sub-menu1"  @click="close_menu('/bank')"><i class="fa fa-bank"></i>&nbsp;Bank  </div>
                            <div class="sub-menu1"  @click="close_menu('/subscriptions')"><i class="fa fa-database"></i>&nbsp;Subscriptions </div>
                            <div class="sub-menu1"  @click="close_menu('/all-escrows')"><i class="fa fa-tasks"></i>&nbsp;All Escrows  </div>
                            <div class="sub-menu1"  @click="close_menu('/all-users')"><i class="fa fa-users"></i>&nbsp;All Users  </div>
                            <div class="sub-menu1"  @click="close_menu('/all-disputes')"><i class="fa fa-paw" aria-hidden="true"></i>&nbsp;All Disputes </div>
                            <div class="sub-menu1"  @click="close_menu('/underpayments')"><i class="fa fa-users"></i>&nbsp;Underpayments  </div>
                            <div class="sub-menu1"  @click="close_menu('/list-withdrawals')"><i class="fa fa-bank"></i>&nbsp;Approve Withdrawals  </div>
                            <div class="sub-menu1"  @click="close_menu('/wallet-funding')"><i class="fa fa-briefcase"></i>&nbsp;Approve Wallet Funding  </div>
                            <div class="sub-menu1"  @click="close_menu('/approve-payments')"><i class="fa fa-credit-card"></i>&nbsp;Approve Payments  </div>
                            <div class="sub-menu1"  @click="close_menu('/profile')"><i class="fa fa-user"></i>&nbsp;Profile  </div>
                            <div class="sub-menu1" to="#"><i class="fa fa-headphones" aria-hidden="true"></i>&nbsp;Help Center  </div>
                            <div class="sub-menu1" style="cursor: pointer" @click="vendorLogout()">&nbsp;<i class="fa fa-sign-out"></i> Logout</div>
                        </div>
                        <div class="submenu-bar" v-else-if="isMenuOpen">
                            <div class=" d-flex flex-row justify-content-between font-weight-bolder"> <button class="close-menu" @click="close_menu()">X</button></div>
                            <div class="menu-sidebar-top">
                                <img src="../assets/user.svg" alt="" @click="show_menu()">
                                <p class="fullname pt-4">{{ firstname }}&nbsp; {{ lastname.slice(0,1) }}.</p>
                                <p class="email">{{ email }}</p>
                            </div>
                                    <div class="sub-menu1"  @click="close_menu('/home')"><i class="fa fa-home"></i>&nbsp;Overview </div>
                                    <div class="sub-menu1"  @click="close_menu('/contract')"><i class="fa fa-tasks" aria-hidden="true"></i>&nbsp;Contracts </div>
                                    <div class="sub-menu1"  @click="close_menu('/my-subscriptions')"><i class="fa fa-database"></i>&nbsp;My Subscriptions </div>
                                    <div class="sub-menu1"  @click="close_menu('/disputes')"><i class="fa fa-paw" aria-hidden="true"></i>&nbsp;Disputes  </div>
                                    <div class="sub-menu1"  @click="close_menu('/wallet')"><i class="fa fa-briefcase"></i>&nbsp;Wallet  </div>
                                    <div class="sub-menu1"  @click="close_menu('/bank')"><i class="fa fa-bank"></i>&nbsp;Bank  </div>
                                    <div class="sub-menu1"  @click="close_menu('/profile')"><i class="fa fa-user"></i>&nbsp;Profile  </div>
                                    <div class="sub-menu1" to="#"><i class="fa fa-headphones" aria-hidden="true"></i>&nbsp;Help Center  </div>
                                    <div class="sub-menu1"  @click="close_menu('/payments')"><i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;Payments  </div>
                            <div class="sub-menu1" style="cursor: pointer" @click="vendorLogout()">&nbsp;<i class="fa fa-sign-out"></i> Logout</div>
                        </div>
                    </div>      
                    <div>
                       
                    </div>
                    <div class="message-notifications">
                        <span class="notifications envelope"><i class="fa fa-envelope"></i></span>
                        <span class="notifications bell"><i class="fa fa-bell"></i></span>
                    </div>            
                </div>
                <router-view/>
            </div>
        </div>
    
        <!-- <div class="footer">
            Privacy Policy | Disclaimer
            Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i>
            <br>
            &copy;{{ new Date().getFullYear() }} {{ appTitle }} All rights reserved.
        </div> -->
    </div>
    
</template>
<script>
import inactivityMixin from '../mixins/inactivityMixin';
export default {

    name: "Dashboard",
    mixins: [inactivityMixin],
    components: {

    },

    data(){
        return {
            appTitle: 'ZEDLOCKER',
            //appTitle: process.env.VUE_APP_COMPANY,
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_role: '',
            pageTitle: '',
            isMenuOpen: false, // Control menu visibility
        };
    },

    created() {
    this.$root.$on('title', (title) => {
      this.pageTitle = title;
        });
    },
    beforeDestroy() {
        this.$root.$off('title');
    },
      
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        this.user_role = localStorage.getItem("user_role");
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");

        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }
    },
   
    computed: {
        
    },
    methods:{
        show_menu(){
            this.isMenuOpen = true; // Show the submenu-bar
        },
        close_menu(router){
            this.isMenuOpen = false; // Show the submenu-bar
            this.$router.push(router)
        },
        vendorLogout(){
            localStorage.removeItem("wertyuiojhdfghhdrtfyguh")
            localStorage.removeItem("firstname");
            localStorage.removeItem("lastname");
            localStorage.removeItem("phone");
            localStorage.removeItem("email");
            localStorage.removeItem("userid");

            localStorage.removeItem("bank_name");
            localStorage.removeItem("account_name");
            localStorage.removeItem("account_number");
            localStorage.removeItem("user_role");
            this.$router.push("/login")
        }
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

.close-menu{
    font-size: 18px;
}
.layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.search-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.top-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 20px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.038);
}
.message-notifications{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}
  
.envelope{
    color: #ccc;
    cursor: pointer;
    padding-right: 10px;
}
.bell{
    color: #2979FF;
    cursor: pointer;
}
.inputs-field{
    font-size: 14px;
    font-family: Poppins;
    height: 45px;
    width: 400px;
    border-radius: 0px;
    position: relative;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #cccccc2b;
    border: solid 0px;
}
.search-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem;
    background: #cccccc2b;
    color: #ccc;
    border-radius: 0px;
    font-family: Poppins;
    font-size: 18px;
    text-transform: capitalize;
    height: 45px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.logo-title {
    padding: 30px 30px 20px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 800;
    color: #fff;
    flex: 1;
    border-bottom: solid 1px #ccc;
}

.menu-bar {
    flex: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menu-bar .submenu-bar {
    display: none;
}

.menu-bar .fa-bars:hover + .submenu-bar,
.menu-bar .submenu-bar:hover {
    display: block;
    position: absolute;
    background: #2979FF;
}

.user-info {
    flex: 1;
    text-align: right;
}

.dropdown .dropbtn {
    background-color: #2979FF;
    color: #000;
    padding: 12px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}

.main-container {
    display: flex;
    flex: 1;
}
.menu-sidebar-top {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 50px 30px 0;

    .fullname{
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        font-family: Poppins;
        font-weight: 500;
        text-transform: capitalize;
        color: #fff;
    }
    .email{
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 1px;
        color: #7F7F7F;

    }
    img{
        width: 60%
    }
}
.menu-sidebar {
    padding: 20px 10px 0;
}

.sidebar {
    background: #000000ef;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px; /* Adjust this width according to your design */
    overflow-y: auto; /* Allows scrolling if the content is longer than the viewport */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    z-index: 1000; /* Keeps it on top of other elements */
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar .sub-menu {
    display: block;
    padding: 0.5rem;
    color: #ccc;
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    text-decoration: none;
    margin-left: 10px;
    padding: 10px 10px 10px 10px;
   
}

.sidebar .sub-menu:hover {
    background: #030303;
    color: #fff;
    border-top-left-radius: 9.26px;
    border-bottom-left-radius: 9.26px;
    border-right: solid 2px #2979FF;
}

.content {
    flex: 1;
    width: 100%;
    margin-left: 200px; /* This should match the sidebar width */
    padding: 20px; /* Optional: add some padding */
    background: #F5F5F5;
}

.footer {
    text-align: center;
    padding: 10px;
    background: #fff;
    box-shadow: 0 -1px 2px rgba(0,0,0,0.1);
}

 .menu-bar{
    display:none;
 }

 .logo-title{
    display:block;
 }

/* Style The Dropdown Button */
.dropbtn {
   // background-color: #4CAF50;
    color: white;
    //padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    color: #f1f1f1 !important;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 3px 16px;
    text-decoration: none;
    display: block;
    border-bottom: solid 1px #ccc;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #474646}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    //background-color: #3e8e41;
  }
#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: Poppins;
}
.dropdown-menu {
    margin-left: 250px;
    background: #fff;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}
.navbar-bgcolor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2979FFc7;
    color: #fff;
    font-family: Poppins;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);

    a{
        color: #fff !important;
        text-decoration: none;
        font-size: 15px !important;
        font-weight: normal !important;

        img{
            width: 60px;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}

@media screen and (max-width: 768px) { 

    .top-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 15px 10px;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.038);
        width: 100%;
        background: #fff;
    }
    .search-bar{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .menu-sidebar-top {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        padding: 10px 10px 0;
    
        .fullname{
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            font-family: Poppins;
            font-weight: 500;
            text-transform: capitalize;
            color: #fff;
        }
        .email{
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 400;
            line-height: 1px;
            color: #7F7F7F;
    
        }
        img{
            width: 20% !important;
        }
    }
    .inputs-field{
        width: 100%;
    }
    .message-notifications{
        display: inline;
    }
    .notifications{
       margin: 5px;
    }
    .envelope{
        padding-right: 0px;
    }
    .envelope:hover{
        cursor: pointer;
    }
    .bell:hover{
        cursor: pointer;
    }

    .sidebar{
        display: none;
    }
    .content {
        flex: 1;
        width: 100%;
        margin-left: 0px; /* This should match the sidebar width */
        padding: 0px; /* Optional: add some padding */
    }
    .logo-title{
        display: none;
    }
    .submenu-bar{
        display:block;
        justify-content: left;
        height: 50%;
        overflow-y: auto; /* Allows scrolling if the content is longer than the viewport */
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        z-index: 1000; /* Keeps it on top of other elements */
        img{
            cursor: pointer;
        }
     }
     .submenu-bar::-webkit-scrollbar {
        display: none;
    }

    .menu-bar{
        display:block;
        justify-content: left;
        img{
            cursor: pointer;
        }
     }
     .menu-bar:hover{
        
            .submenu-bar{
                display: flex;
                flex-direction: column;
                background: #181818;
                top: 0;
                left: 0;
                font-family: Poppins;
                font-size: 10px;
                border-right: solid 1px rgba(0, 0, 0, 0.3);
                position: fixed;
                z-index: 9998;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                width: 100%;
                height: 100%;
                left: -2px;
                padding: 38px 49px 38px 49px;
                            
                a{
                    text-decoration: none;
                    //color: #312F33;
                }
                img{
                    width: 50% !important
                }
                button{
                    margin-top: 20px;
                    color: #fff
                }
            }
            .sub-menu1{
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                //border-left: solid 1px #ccc;
                //border-bottom: solid 1px #ccc;
                text-transform: capitalize;
                cursor: pointer;
                padding: 0.4rem;
                width: 100%;
                color: #ccc;
                text-decoration: none;
                padding: 10px 10px 10px 10px;
                border-top-left-radius: 9.26px;
                border-bottom-left-radius: 9.26px;
            }
     }
     .sub-menu1:hover{
        background: #030303;
        border-right: solid 2px #2979FF;
     }
     
    .menu{
        display: none;       
    }
    .dropdown-menu {
        margin-left: 0;
    }

    .dropdown {
        font-size: 12px;
      }
    
    .avater{
        font-size: 12px;
        display: flex;
    }
    .navbar-bgcolor{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #2979FFc7;
        color: #fff;
        font-family: Poppins;
       box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    
        a{
            color: #fff !important;
            text-decoration: none;
            font-size: 13px !important;
            font-weight: normal !important;
    
            img{
                width: 50px;
            }
        }
        a:hover{
            color: #fff;
        }
    }
}

</style>