<template>
    <div class="container-fluid">
        <p class="welcome"></p>
         <div class="col-lg-12 col-sm-12 pt-3 dashboard-page">             
              <div class="col-lg-6 col-sm-6 pt-3">
                <div @click="go_back()" style="cursor:pointer" class="font-weight-normal pb-5"><i class="fa fa-long-arrow-left"></i> Cancel</div>
                <div class="dashboard-inner-new">
                    <div class="wallet-board-new amount">
                      <div class="avail-amount">
                        <div class="topup">Confirm Payment</div>
                        <div class="add-money">Confirm the payment you made below</div>
                      </div>
                    </div>
                    <div class="confirm-text">
                        <div class="c-text-1"></div>
                          <div class="c-text-10">Be sure that you have successfully made the payment to the account details. if you tap <span>“Comfirm Payment”</span> without making any payment, your account may be suspended</div>  
                        <div class="c-text-1"></div>
                    </div>
                    <!-- <div class="amount-p pl-4">Enter Bank Paid From</div>
                    <div class="wallet-board-new amount-v">
                      <div class="avail-amount-p">
                        <div class="input-amount-symbol"><span></span>&nbsp;
                            <input  class="input-amount" type="text" name="" id="" v-model="banks">
                        </div>
                      </div>
                    </div> -->
                    <div class="amount-p pl-4">Bank Paid From</div>
                    <div class="wallet-board-new amount-v">
                      <div class="avail-amount-p">
                        <div class="input-amount-symbol"><span></span>&nbsp;
                          <v-autocomplete
                          required
                          v-model="banks"
                          label="Select bank *"
                          :items="banksList"
                          variant="outlined"
                        ></v-autocomplete>
                        </div>
                      </div>
                    </div>
                    
                    <div class="amount-p pl-4">Account Number</div>
                    <div class="wallet-board-new amount-v">
                      <div class="avail-amount-p">
                        <div class="input-amount-symbol"><span></span>&nbsp;
                            <input  class="input-amount" type="text" name="" id="" v-model="source_account_number" inputmode="numeric" pattern="[0-9]*" @keypress="onlyNumber($event)" @change="validateBank()">
                        </div>
                        <!-- <span v-if="error" class="error">{{ error }}</span> -->
                      </div>
                    </div>
                    <div class="amount-p pl-4" v-if="loading_account_name">
                     <center> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> validating account no...</center>
                    </div>
                    <div class="amount-p pl-4" v-if="isAccountName">Account Name</div>
                    <div class="wallet-board-new amount-v" v-if="isAccountName">
                      <div class="avail-amount-p">
                        <div class="input-amount-symbol"><span></span>&nbsp;
                            <input  class="input-amount" type="text" name="" id="" v-model="source_account_name">
                        </div>
                      </div>
                    </div>
                    <div class="add-withd-panel-enter-p" v-if="isAccountName">
                      <button :disabled="isSubmitButtonDisabled" @click="confirm_payment()" class="proceed" v-if="!loading">Confirm Payment</button>
                      <button class="proceed" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                    <div class="add-withd-panel-enter" v-if="isAccountName">
                      <button @click="go_back()" class="go-back"> Go Back</button> 
                    </div>
                </div>
              </div>
         </div>

          <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "ComfirmPaymentPage",
    components: {
      PageLoader
    },
  
    data(){
      return {
          search: '',
          loading: false,
          loading2: false,
          isLoading: false,
          phone_number: '',
          email_address: '',
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          img_url: process.env.VUE_APP_IMAGE_URL + '/products/',
          amount: '',
          reference_number: '',
          banks: '',
          bank_lists: '',
          source_account_name:'',
          source_account_number: '',
          dest_bank_name: '',
          dest_account_name: '',
          dest_account_number: '',
          error: '',
          banksList: [],
          isAccountName: false,
          loading_account_name: false,
          user_bank_name: '',

        };
    },
  
    beforeMount(){
      this.user_id = localStorage.getItem("userid");
    },
  
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.referral_code = localStorage.getItem("referral_code");
        this.fullname = this.firstname +' '+ this.lastname 
       
        this.amount = this.$route.params.amount
        this.reference_number = this.$route.params.ref
        this.dest_bank_name = this.$route.params.bank_name
        this.dest_account_name = this.$route.params.account_name
        this.dest_account_number = this.$route.params.account_number
        this.fetchAllBanks()
    },
  
    created(){
          
    },
  
    computed: {
      cummulativeEarnings(){
        return this.total_orders + this.referral_earnings;
      },
      indexSn() {
        return this.desserts.map((d, index) => ({
            ...d,
            sn: index + 1
        }))
      },
      isSubmitButtonDisabled() {
          return (
             this.source_account_name === '' ||
             this.source_account_number === '' ||
             this.banks === ''
          );
      }
    },
  
    methods: {
      async fetchAllBanks() {
            try {

               const response =  await this.$http.get('/get-banks', {
                })

                let arrData = [];
                arrData = response.data.bank.data 
                arrData.forEach(el => {
                  this.banksList.push({
                    text: el.name,
                    value: el.bank_code +'-' + el.name
                  })
                });                     
               
            } catch (err) {
              console.log(err);
          }
      },
      async validateBank() {
            try {

               this.loading_account_name = true

               let arrData = this.banks.split('-')
               let bcode = arrData[0]
               this.user_bank_name = arrData[1]

               const response =  await this.$http.post('/validate-bank', {
                  account_number: this.source_account_number,
                  bank_code: bcode,
                })

                this.loading_account_name = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'warning',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Invalid data. Check your account number',
                    })
                    return;
                }

                this.source_account_name = response.data.bank_detail.account_name
                this.isAccountName = true

            } catch (err) {
              console.log(err);
        }
      },
      onlyNumber(event) {
            const charCode = event.which ? event.which : event.keyCode;
            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
      },

      validateInput(){
        const value = this.source_account_number;
        if (value < 0) {
        this.error = 'Value must be non-negative';
        this.source_account_number = ''
        return
        } else if (!Number.isInteger(Number(value))) {
        this.error = 'Value must be an integer';
        this.source_account_number = ''
        return
        } else {
        this.error = '';
        }
      },

      async confirm_payment(){
        try {
                 if (!this.banks) {
                    swal.fire({
                    icon: 'error',
                    title: 'Error',
                    width: 450,
                    height: 5,
                    text: 'Please select bank',
                  })
                  return;
                 }
                 this.loading = true
                 const response =  await this.$http.post('/confirm-bank-payment', {
                    userid: this.user_id,
                    bank_name: this.user_bank_name,
                    account_name: this.source_account_name,
                    account_number: this.source_account_number,
                    dest_bank_name: this.dest_bank_name,
                    dest_account_name: this.dest_account_name,
                    dest_account_number: this.dest_account_number,
                    amount: this.amount,
                    reference_number: this.reference_number
                  })
                  this.loading = false
                  if(response.data.success){
                
                  this.$router.push('/transfer-success/'+this.reference_number+'/'+this.amount+'/'+this.dest_bank_name+'/'+this.source_account_name+'/'+this.dest_account_number)
                  return;
                }
              } catch (err) {
                console.log(err);
            }
      },
      go_back(){
          this.$router.push('/bank-transfer/'+this.reference_number+'/'+this.amount)
      },

      async fetchAllBanks1() {
            try {
                const response =  await this.$http.get('/get-bank-lists', {
                })

                this.bank_lists = response.data.banks
                // arrData.forEach(el => {
                //   this.banks.push({
                //     text: el.bank,
                //     value: el.bank
                //   })
                // });                     
               
            } catch (err) {
              console.log(err);
          }
        },

      async fetch_contract_users(contract_number, user_id) {
            try {
                await this.$http.post('/fetch-user-payment-role', {
                  contract_number: contract_number,
                  user_id: user_id
                })
                  // this.contract_user_payment = response.data.data[0].is_payment
                  // const userRole = this.contract_user_payment;
  
            } catch (err) {
                console.log(err);
            }
      },
      processPayment(contract_id){
          this.$router.push('/checkout/summary/'+contract_id)
      },
      copyToClipboard1(link) {
             // var copyText = document.getElementById("myInput");
              navigator.clipboard.writeText(link).then(function() {
                  alert("Contract link copied to clipboard!");
              }).catch(function(err) {
                  console.error('Failed to copy: ', err);
              });
      },
     
     
  
      
      copyToClipboard() {
        var copyText = document.getElementById("myInput");
        navigator.clipboard.writeText(copyText.value).then(function() {
            alert("Referral link copied!");
        }).catch(function(err) {
            console.error('Failed to copy: ', err);
        });
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
  .error{
    color: #fff;
    font-size: 10px;
  }
  .copy-refid1{
    cursor: pointer;
    //font-size: 20px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
  }
  
  .account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  
  .add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .btn-secondary {
    text-transform: normal;
  }
  .welcome{
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    padding: 0px 20px;
    text-align: left;
    color: #170e0fac;
  }
   .dashboard-page{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: #fff;
      padding: 0.6rem;
      font-family: Poppins;
      strong{
        font-weight: normal;
        font-family: Poppins;
      }
   }
   .wallet-board{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
      //background: #807b7b33;
      border-radius: 4px;
      padding: 0.5rem;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
      height: 100%;
   }
   .wallet-account{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    background: #2979FF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
   }
   .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.6rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1.2;
        color: #ccc;
      }
  
   }
   .account-number{
      padding: 0.6rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
  
      span{
        color: #fff;
      }
    }
   }
   .wallet-balance{
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      width: 60%;
      height: 100%;
      //background-image: linear-gradient(-90deg, #fff, #cccccc56, #fff);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      
      h4{
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 12px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
      border-radius: 4px;
   }
   .dashboard-inner{
    overflow-y: auto;
    height: 255px;
    width: 100%;
    border-radius: 15px;
    padding: 1.2rem;
    margin: 10px;
    background: #fff;
   }
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 18px 35px 18px 35px;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
  
      .topup{
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: var(--DIM, #181818);
      }
      .add-money{
        font-size: 8px;
        font-weight: 400;
        text-align: center;
        color: var(--DIM, #181818);
      }
  
    }   
   }
   .confirm-text{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }
    .c-text-1{
       width: 10%;
    }
    .c-text-10{
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        width: 90%;
        color: var(--DIM, #7F7F7F);

        span{
            font-weight: 550;
        }
    }

   .avail-amount-p{
      display: flex;
      flex-direction: column;
      justify-content: left;
      
   }
   .amount-p{
    font-size: 11px;
    font-weight: 450;
    color: var(--DIM, #7F7F7F);
   }
   .amount{
    padding: 0.6rem !important;
    background: var(--sub-layer, #ECF3FF) !important;
   }
   .amount-v{
    border-radius: 6px;
    margin-top: -10px;
    padding: 0.2rem !important;
    background: var(--sub-layer, #ECF3FF) !important;
   }
   .input-amount-symbol{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span{
      width: 21px;
      height: 20px;
      border-right: solid 1.4px #7F7F7F;
    }
   }
   .input-amount{
    width: 100%;
    height: 32px;
    gap: 10px;
    
    background: #ECF3FF;
    color:#7F7F7F
   }
   .enter-fund{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #7F7F7F);
   }
   .trans-charge{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #2879FD);
   }
   .add-withd-panel-center{
    display: flex;
    flex-direction: column;
   }
   .change-payment{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    text-align: right;
    color: #414244;
    .fa-angle-down{
      color: var(--DIM, #2879FD);
      font-size: 12px;
    }
   }
   .add-withd-panel-enter-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-withd-panel-enter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .proceed{
    width:138px;
    height:auto;
    padding: 0.5rem;
    border-radius: 8px;
    background: var(--Primary-Color, #2879FD);
    color: #FFFFFF;
    font-weight: 600;
    font-size: 10px;
   }

   .go-back{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
   }
   .cancel-trans{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;  
    color: var(--DIM, #7F7F7F);
   }
   .separator{
    color: var(--Primary-Color, #2879FD);
   }
   .active-c{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 15px;
    height: 100px;
    width: 100%;
  
    .icon{
      font-size: 44px;
    }
   }
   .active-c-history{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
  
    .icon2{
      font-size: 20px;
    }
   }
   .activeo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
   }
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 400;
      font-size: 11px;
      font-family: Poppins;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .location{
      color: #807b7b;
      font-weight: 550;
      font-size: 11px;
      font-family: Poppins;
      margin-top: 4px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
  
      button{
        font-size: 10px;
      }
    }
    button{
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .active-cc-history{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #00000091;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
  
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 12px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 40px;
    }
   }
  
  
  @media screen and (max-width: 768px) {
    .welcome{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      padding: 0px 15px;
      text-align: left;
      color: #170e0fac;
    }
  
    .dashboard-page{
      display: flex;
      flex-direction: column;
      strong{
        margin-top: 10px;
      }
   }
   .dashboard-inner{
    margin: 0px;
    margin-top: 10px;
    padding: 0.8rem;
   }
  
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.6rem;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
   
    width: 100%;
    height: auto;
    padding: 0.9rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      
      width: 100%;
      .topup{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }
      .add-money{
        color: var(--DIM, #181818);
      }
      .avail-bal{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 400;
      }
      .trans-his{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: normal;
        color: #D9D9D9;
      }
  
    }
   
    .input-amount-symbol{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span{
        color: #2D2D2D;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        width: 21px;
        height: 20px;
        gap: 0px;
        
      }
     }
     .input-amount{
        background: #ECF3FF;
        color:#7F7F7F;
        font-size: 12px;
       }
     .amount-p{
      color: var(--DIM, #7F7F7F);
      font-size: 10px;
     }
     .enter-fund{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #7F7F7F);
     }
     .trans-charge{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #2879FD);
     }
     .add-withd-panel-center{
      display: flex;
      flex-direction: column;
     }
     .change-payment{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: 400;
      text-align: right;
      color: #414244;
      .fa-angle-down{
        color: var(--DIM, #2879FD);
        font-size: 10px;
      }
     }
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        font-family: Montserrat;
        font-size: 8px;
        font-weight: normal;
        color: #D9D9D9;
      }
      .pending-fund{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 600;
      }
    }
   }
   .add-withd-panel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-fund{
    width: Fixed (111.7px)px;
    height: Fixed (38px)px;
    padding: 12.67px 16.12px 12.67px 16.12px;
    border-radius: 81.53px;
    
    background: var(--Primary-Color, #2879FD);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 4px;
  
   }
   .withd-fund{
    width: Fixed (112.59px)px;
    height: Hug (38px)px;
    padding: 12.67px 9.85px 12.67px 9.85px;
    border-radius: 81.53px;
    
    background: var(--Secondary-color, #08294A);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
  
   }
  
   .active-c{
  
    .icon{
      font-size: 40px;
    }
   }
  
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .location{
      color: #807b7b;
      font-weight: 200;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .wallet-board{
    padding: 0.5rem;
  }
  
  .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.4rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1;
      }
  
   }
   .account-number{
      padding: 0.4rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
      span{
        color: #fff;
        font-size: 11px;
      }
    }
   }
   .wallet-balance{
      h4{
        font-weight: normal;
        font-size: 14px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
   }
  
   .active-c-history{
    padding: 0px;
    margin-top: 10px;
    .icon2{
      font-size: 15px;
    }
   }
  
   .active-cc-history{
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      
      span{
        color: #00000091;
        font-style: italic;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 23px;
    }
   }
   .account-content {
    margin: 0px;
    margin-right: 0px;
  }
    
  }
  
  </style>