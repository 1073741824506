<template>
    <div class="container-fluid">
        <p class="welcome"></p>
         <div class="col-lg-12 col-sm-12 pt-3 dashboard-page">             
              <div class="col-lg-6 col-sm-6 pt-3">
                <!-- <div @click="go_back()" style="cursor:pointer" class="font-weight-normal pb-5"><i class="fa fa-long-arrow-left"></i> </div> -->
                <div class="dashboard-inner-new">
                    <div class="wallet-board-new">
                      <div class="avail-amount">
                        <div class="topup">Bank Transfer</div>
                        <div class="add-money">Pay via mobile or internet banking</div>
                      </div>
                    </div>
                    <div class="wallet-board-new-c mt-2">
                        <div class="avail-amount-c">
                          <div class="amount-cc">Transfer to the following account details</div>
                          <div class="trans-expired">Transaction will expire in</div>
                          <div class="trans-time">{{countdown_Timer}}</div>
                          <div class="trans-amount-send">Amount You will send</div>
                          <div class="trans-amount-charge">₦{{ amount  }}</div>
                        </div>
                    </div>
                    <div class=" d-flex flex-column justify-content-between">
                        <div class="account-number-text">Account Number</div>
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <div class="account-number">{{ payment_banks.account_number}}</div>
                            <button @click="copyToClipboard(payment_banks.account_number)" class="copy-number">Copy Number</button>
                            <button @click="copyToClipboard(payment_banks.account_number)" class="copy-number1"><i class="fa fa-copy"></i></button>
                        </div>
                    </div>
                    <div class="wallet-board-new-t amount mt-4">
                      <div class="bank-acc-name">
                        <h4 class="bank-name-text">Bank Name</h4>
                        <h4 class="account-name-text">Account Name</h4>
                      </div>
                      <div class="bank-acc-number">
                        <h4 class="bank-name">{{ payment_banks.bank_name}}</h4>
                        <h4 class="account-name">{{ payment_banks.account_name}}</h4>
                      </div>
                    </div>
                    <div class="wallet-board-new-t amount mt-4">
                        <div class="bank-acc-name">
                          <h4 class="bank-name-text reference-text">Narration/Ref: <span class="reference">{{ reference_number}}</span></h4>
                          <button @click="copyToClipboard1(reference_number)" class="copy-number-t"><i class="fa fa-copy"></i></button>
                        </div>
                    </div>
                    <div class="add-withd-panel-enter-p"><button  @click="confirm_payment()" class="proceed">I have made the payment</button></div>
                    <div class="add-withd-panel-enter">
                      <button @click="go_back()" class="cancel-trans">Cancel</button>
                    </div>
                </div>
                <div class="dashboard-inner-t mt-4 pt-4 pl-10 pr-10">
                    <h5 class="pls-remember">PLEASE REMEMBER:</h5>
                    <ol>
                        <li>
                           <p>Ensure you use the exact details from this transaction. Any modifications could result in a default.</p>
                        </li>
                        <li>
                           <p> Only tap the “I have made payment” button AFTER you have successfully made payment to the details above. </p>
                        </li>
                        <li>
                            <p>If you overpay or underpay, the refund process may take more than 48 hours.</p>
                        </li>
                    </ol>
                    
                </div>
              </div>
         </div>

          <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  //import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "TopupPage",
    components: {
      PageLoader
    },
  
    data(){
      return {
          search: '',
          isLoading: false,
          loading: false,
          phone_number: '',
          email_address: '',
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          amount: '',
          reference_number: '',
          payment_banks: [],
          timer: null, 
          countdown_Timer: '',
          interval: '',
          img_url: process.env.VUE_APP_IMAGE_URL + '/products/',
          selectedPaymentMethod: '',
          contract_id: '',
        };
    },
  
    beforeMount(){
      this.user_id = localStorage.getItem("userid");
    },
  
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.referral_code = localStorage.getItem("referral_code");
        this.fullname = this.firstname +' '+ this.lastname 
        this.amount = this.$route.params.amount
        this.amount.toLocaleString('en-NG', {
                            minimumIntegerDigits: 2
                        }),
        this.reference_number = this.$route.params.ref
        this.selectedPaymentMethod = this.$route.params.payment_method
        this.contract_id = this.$route.params.contractid
       
        this.fetchPaymentBanks();
        this.countDownTimer();
      
    },
  
    created(){
          
    },
  
    computed: {
      cummulativeEarnings(){
        return this.total_orders + this.referral_earnings;
      },
      indexSn() {
        return this.desserts.map((d, index) => ({
            ...d,
            sn: index + 1
        }))
      },
      isSubmitButtonDisabled() {
          return (
            this.amount === ''
          );
      }
    },
  
    methods: {
      go_back(){
        this.$router.push('/contract')     
      },
      confirm_payment(){
          this.$router.push('/confirm-bank-payment/'+this.reference_number+'/'+this.amount+'/'+this.contract_id+'/'+this.selectedPaymentMethod+'/'+this.payment_banks.bank_name+'/'+this.payment_banks.account_name+'/'+this.payment_banks.account_number)
      },
     
      processPayment(contract_id){
          this.$router.push('/checkout/summary/'+contract_id)
      },
      copyToClipboard(link) {
          navigator.clipboard.writeText(link).then(function() {
              alert("Account number copied to clipboard!");
          }).catch(function(err) {
              console.error('Failed to copy: ', err);
          });
      },
      copyToClipboard1(link) {
          navigator.clipboard.writeText(link).then(function() {
              alert("Reference number copied to clipboard!");
          }).catch(function(err) {
              console.error('Failed to copy: ', err);
          });
      },
      async fetchPaymentBanks(){
        try {
                const response = await this.$http.get('/payment-banks', {
                })
                this.payment_banks = response.data.bank[0]  
            } catch (err) {
                console.log(err);
            }
      },

      
      async countDownTimer() {
            // Set the countdown time in seconds
            var countdownTime = 1200; // 5 minutes = 5 * 60 seconds

            // Update the countdown timer every second
            this.interval = setInterval(() => {
                // Calculate minutes and seconds
                var minutes = Math.floor(countdownTime / 60);
                var seconds = countdownTime % 60;

                // Display the time in the HTML element
                this.countdown_Timer = minutes + ":" + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2});

                // Check if the countdown has finished
                if (countdownTime == 0) {
                    clearInterval(this.interval); // Stop the interval
                    this.countdown_Timer = "00:00";
                    //this.$router.push('/topup-account');
                }

                // Decrease the countdown time by 1 second
                countdownTime--;
            }, 1000);
        },
     
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
  
  .copy-refid1{
    cursor: pointer;
    //font-size: 20px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 90%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
  }
  
  .account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  
  .add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .btn-secondary {
    text-transform: normal;
  }
  .welcome{
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    padding: 0px 20px;
    text-align: left;
    color: #170e0fac;
  }
   .dashboard-page{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: #fff;
      padding: 0.6rem;
      font-family: Poppins;
      strong{
        font-weight: normal;
        font-family: Poppins;
      }
   }
   .wallet-board{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
      //background: #807b7b33;
      border-radius: 4px;
      padding: 0.5rem;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
      height: 100%;
   }
   .wallet-account{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    background: #2979FF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
   }
   .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.6rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1.2;
        color: #ccc;
      }
  
   }
   .wallet-balance{
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      width: 60%;
      height: 100%;
      //background-image: linear-gradient(-90deg, #fff, #cccccc56, #fff);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      
      h4{
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 12px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
      border-radius: 4px;
   }
   .dashboard-inner{
    overflow-y: auto;
    height: 255px;
    width: 100%;
    border-radius: 15px;
    padding: 1.2rem;
    margin: 10px;
    background: #fff;
   }
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 19px;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
  
      .topup{
        font-size: 28.69px;
        font-weight: 500;   
      }
      .add-money{
        font-size: 9px;
        font-weight: normal;
        color: #fff;
      }
  
    }
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
       // font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        text-align: left;
  
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        //font-family: Montserrat;
        font-size: 8px;
        font-weight: 600;
        text-align: left;
        color: #D9D9D9;
      }
    }
   }

   .wallet-board-new-c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
  
    .avail-amount-c{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
    }
    .amount-cc{
       // font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        color: #181818;
    }
    .trans-expired{
        font-size: 13px;
        font-weight: 300;
        color: #181818;
    }
    .trans-time{
        //font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        color: #F22323;
    }
    .trans-amount-send{
        //font-family: Montserrat;
        font-size: 12.04px;
        font-weight: 400;
        text-align: center;
        color: #414244;
    }
    .trans-amount-charge{
       // font-family: Montserrat;
        font-size: 40px;
        font-weight: 500;
        color: #2879FD;
    }
   }
   .wallet-board-new-t{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
   }
   .account-number-text{
    font-size: 14px;
    font-weight: 400;
    color: #181818;
   }
   .account-number{
    font-size: 30px;
    font-weight: 501;
    color: var(--DIM, #7F7F7F);
    padding: 0rem;
   }
   .copy-number1{
    display: none;
   }
   .copy-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 34px;
    border-radius: 72px;
    background: var(--Primary-Color, #2879FD);
    color: #FFF;
    font-size: 11px;
    font-weight: 500;
   }
   .copy-number-t {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10%;
    padding: 0.2rem;
    height: 34px;
    border-radius: 72px;
    background: var(--Primary-Color, #2879FD);
    color: #FFF;
    font-size: 11px;
    font-weight: 500;
   }
   .bank-acc-name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
   }
   .bank-acc-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -12px;
   }
   .bank-name-text{
    //font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    color: #181818;
   }
   .account-name-text{
    //font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    color: #181818;
   }
   .bank-name{
    //font-family: Montserrat;
    font-size: 17px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .account-name{
    //font-family: Montserrat;
    font-size: 17px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .reference-text{
    font-size: 14px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
   }
   .reference{
    font-size: 14px;
    font-weight: 500;
    color: var(--DIM, #252323);
    text-align: center;
   }
   .dashboard-inner-t{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    //padding: 19px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .pls-remember{
    //ont-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
    ;
   }
   ol li{
     font-size: 10px;
     color: #2879FD;
     padding: -0.8rem;
   }
   ol li p{
    //font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
   // line-height: 10px;
    text-align: justify;
    color: var(--DIM, #7F7F7F);
   }
  
   .amount-p{
    color: var(--DIM, #7F7F7F);
   }
   .amount{
    padding: 0.6rem !important;
    background: var(--sub-layer, #ECF3FF) !important;
   }
   .input-amount-symbol{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #7F7F7F;

    span{
      color: #2D2D2D;
      //font-family: Montserrat;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      width: 21px;
      height: 20px;
      gap: 0px;
      

    }
   }
   .input-amount{
    width: 100%;
    height: 32px;
    gap: 0px;
    
    background: #FFFFFF;
   }
   .enter-fund{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #7F7F7F);
   }
   .trans-charge{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #2879FD);
   }
   .add-withd-panel-center{
    display: flex;
    flex-direction: column;
   }
   .change-payment{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    text-align: right;
    color: #414244;
    .fa-angle-down{
      color: var(--DIM, #2879FD);
      font-size: 12px;
    }
   }
   .add-withd-panel-enter-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-withd-panel-enter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .proceed{
    width: Hug (138px)px;
    height: Hug (36px)px;
    padding: 10px;
    border-radius: 8px;
    
    background: var(--Primary-Color, #2879FD);
    color: #FFFFFF;
    font-weight: 450;

   }

   .go-back{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
   }
   .cancel-trans{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;  
    color: var(--DIM, #7F7F7F);
   }
   .separator{
    color: var(--Primary-Color, #2879FD);
   }
   .active-c{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 15px;
    height: 100px;
    width: 100%;
  
    .icon{
      font-size: 44px;
    }
   }
   .active-c-history{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
  
    .icon2{
      font-size: 20px;
    }
   }
   .activeo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
   }
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 400;
      font-size: 11px;
      font-family: Poppins;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .location{
      color: #807b7b;
      font-weight: 550;
      font-size: 11px;
      font-family: Poppins;
      margin-top: 4px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
  
      button{
        font-size: 10px;
      }
    }
    button{
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .active-cc-history{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #00000091;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
  
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 12px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 40px;
    }
   }
  
  
  @media screen and (max-width: 768px) {
    .welcome{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      padding: 0px 15px;
      text-align: left;
      color: #170e0fac;
    }
  
    .dashboard-page{
      display: flex;
      flex-direction: column;
      strong{
        margin-top: 10px;
      }
   }
   .dashboard-inner{
    margin: 0px;
    margin-top: 10px;
    padding: 0.8rem;
   }
  
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.6rem;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
   
    width: 100%;
    height: auto;
    padding: 0.9rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      
      width: 100%;
      .topup{
        //font-family: Montserrat;
        font-size: 25px;
        font-weight: 500;
        text-align: center;
      }
      .add-money{
        //font-family: Montserrat;
        font-size: 8px;
        font-weight: 400;
        text-align: center;
        color: #fff;
      }
      .avail-bal{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 400;
      }
      .trans-his{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: normal;
        color: #D9D9D9;
      }
  
    }
   
    .input-amount-symbol{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #7F7F7F;
  
      span{
        color: #2D2D2D;
        //font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        width: 21px;
        height: 20px;
        gap: 0px;
        
  
      }
     }
     .input-amount{
      width: 100%;
      height: 32px;
      gap: 0px;
      
      background: #FFFFFF;
     }
     .amount-p{
      color: var(--DIM, #7F7F7F);
      font-size: 10px;
     }
     .enter-fund{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #7F7F7F);
     }
     .trans-charge{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #2879FD);
     }
     .add-withd-panel-center{
      display: flex;
      flex-direction: column;
     }
     .change-payment{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: 400;
      text-align: right;
      color: #414244;
      .fa-angle-down{
        color: var(--DIM, #2879FD);
        font-size: 10px;
      }
     }
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        font-family: Montserrat;
        font-size: 8px;
        font-weight: normal;
        color: #D9D9D9;
      }
      .pending-fund{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 600;
      }
    }
   }
   .wallet-board-new-c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
  
    .avail-amount-c{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
    }
    .amount-cc{
        font-size: 10px;
        font-weight: 500;
        color: #181818;
    }
    .trans-expired{
        font-size: 11px;
        font-weight: 300;
        color: #181818;
    }
    .trans-time{
        //font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        color: #F22323;
    }
    .trans-amount-send{
        //font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #414244;
    }
    .trans-amount-charge{
       // font-family: Montserrat;
        font-size: 30px;
        font-weight: 500;
        color: #2879FD;
    }
   }
   .wallet-board-new-t{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.2rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
   }
   .account-number-text{
    font-size: 12px;
    font-weight: 400;
    color: #181818;
   }
   .account-number{
    font-size: 20px;
    font-weight: 501;
    color: var(--DIM, #7F7F7F);
    margin-left: -0.5rem;
   }
   .copy-number{
    display: none;
   }
   .copy-number1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 25px;
    border-radius: 72px;
    padding: 0rem;
    background: var(--Primary-Color, #2879FD);
   
    .fa-copy{
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
    }
   }
   .copy-number-t {
    height: 20px;
   }
   .bank-name-text{
    //font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    color: #181818;
   }
   .account-name-text{
    //font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    color: #181818;
   }
   .bank-name{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .account-name{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .reference-text{
    font-size: 10px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
   }
   .reference{
    font-size: 10px;
    font-weight: 500;
    color: var(--DIM, #252323);
    text-align: center;
   }
   .proceed{
    font-size: 10px;
    font-weight: 450;
   }
   .pls-remember{
    font-size: 12px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
    ;
   }
   ol li p{
    font-size: 10px;
    font-weight: 400;
    text-align: justify;
    color: var(--DIM, #7F7F7F);
   }
   .add-withd-panel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-fund{
    width: Fixed (111.7px)px;
    height: Fixed (38px)px;
    padding: 12.67px 16.12px 12.67px 16.12px;
    border-radius: 81.53px;
    
    background: var(--Primary-Color, #2879FD);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 4px;
  
   }
   .withd-fund{
    width: Fixed (112.59px)px;
    height: Hug (38px)px;
    padding: 12.67px 9.85px 12.67px 9.85px;
    border-radius: 81.53px;
    
    background: var(--Secondary-color, #08294A);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
  
   }
  
   .active-c{
  
    .icon{
      font-size: 40px;
    }
   }
  
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .location{
      color: #807b7b;
      font-weight: 200;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .wallet-board{
    padding: 0.5rem;
  }
  
  .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.4rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1;
      }
  
   }
   .account-number{
      padding: 0.4rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
      span{
        color: #fff;
        font-size: 11px;
      }
    }
   }
   .wallet-balance{
      h4{
        font-weight: normal;
        font-size: 14px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
   }
  
   .active-c-history{
    padding: 0px;
    margin-top: 10px;
    .icon2{
      font-size: 15px;
    }
   }
  
   .active-cc-history{
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      
      span{
        color: #00000091;
        font-style: italic;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 23px;
    }
   }
   .account-content {
    margin: 0px;
    margin-right: 0px;
  }
    
  }
  
  </style>