<template>
    <div class="container">
      <center> <p class="welcome"> Shipping Information</p> </center>
        <div class="account-details">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <div class="shipping-detail">
                <div class="inputs"> 
                    <div class="ship-address">
                        <span>Address from where item is shipped from</span> 
                    </div>
                    <label for="">Address *</label>
                    <textarea name="" class="form-control" id="" cols="30" rows="2" placeholder="Enter business address" v-model="address"></textarea>
                    <label for="">City / Town *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter City / Town" v-model="city_town" required>
                    <label for="">State / Region *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter State / Region" v-model="state_region" required>
                    <label for="">Country *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter Country" v-model="country" required>
                    <label for="">Postal Code *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter Postal Code" v-model="postal" required>
                </div>
                <div class="inputs">   
                    <div class="ship-address">
                        <span>Return address from where item is shipped to</span>  
                    </div>
                    <label for="">Address *</label>
                    <textarea name="" class="form-control" id="" cols="30" rows="2" placeholder="Enter business address" v-model="address1"></textarea>
                    <label for="">City / Town *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter City / Town" v-model="city_town1" required>
                    <label for="">State / Region *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter State / Region" v-model="state_region1" required>
                    <label for="">Country *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter Country" v-model="country1" required>
                    <label for="">Postal Code *</label>
                    <input type="text" class="form-control inputs-field" placeholder="Enter Postal Code" v-model="postal1" required>
                </div>
            </div>
            
            <div class="">
                <div class="inputs">
                    <button id="submit2" class="btn btn-danger btn-block" :disabled="isShopEnabled"  @click="createShipping()" v-if="!loading">save</button>
                    <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="table table-responsive">
              <h6>Shipping record</h6>
              <v-data-table
                :headers="header"
                :items="desserts"
                dense
                class="table table-bordered"
              >             
                <template v-slot:item.shippingid="{ item }">
                    <div style="display: flex; flex-direction:row;align-content:center">
                        <button @click="editShipping(item.shippingid)" style="color:green"><i class="fa fa-edit"></i></button>
                        <button @click="removeShipping(item.shippingid)" style="color:red"><i class="fa fa-trash"></i></button>
                    </div>
                </template>
              </v-data-table>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
          <div class="col-sm-1"></div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "ShippingInfo",
    components: {
      PageLoader
    },
  
    data(){
      return {
          
          isLoading: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',

          loading: false,
          city_town: '',
          state_region: '',
          country: '',
          postal: '',
          address: '',
          city_town1: '',
          state_region1: '',
          country1: '',
          postal1: '',
          address1: '',
          shipping:[],
          user_role: '',
          header: [
            {
                align: 'start',
                value: 'shipAddress',
                sortable: false,
                text: 'Shipped Address',
            },
            { value: 'city', text: 'City / Town' },
            { value: 'state', text: 'State / Region' },
            { value: 'country', text: 'Country' },
            { value: 'postal', text: 'Postal' },
            {
                align: 'start',
                value: 'shipAddress1',
                sortable: false,
                text: 'Returned Address',
            },
            { value: 'city1', text: 'City / Town' },
            { value: 'state1', text: 'State / Region' },
            { value: 'country1', text: 'Country' },
            { value: 'postal1', text: 'Postal' },
            { value: 'shippingid', text: 'Action' },
          ],
          desserts: [],
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
  
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");

        this.user_role = localStorage.getItem("user_role");

        this.fetchShippingByUserId()

    },
  
    created(){
          
    },
  
    computed: {
      isShopEnabled() {
        return (
            this.shop_name === '' ||
            this.shop_firstname === '' ||
            this.shop_lastname === '' ||
            this.shop_email === '' ||
            this.shop_phone === ''
        );
      },
    },
  
    methods: {

        async createShipping() {
            try {

                this.loading = true
                const response =  await this.$http.post('/create-shipping', {
                  shipping_address: this.address,
                  city: this.city_town,
                  state: this.state_region,
                  country: this.country,
                  postal: this.postal,
                  return_address: this.address1,
                  return_city: this.city_town1,
                  return_state: this.state_region1,
                  return_country: this.country1,
                  return_postal: this.postal1,
                  addedby: this.user_id,
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }
                else{
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })

                    this.address = '',
                    this.city_town = '',
                    this.state_region = '',
                    this.country = '',
                    this.postal = '',
                    this.address1 = '',
                    this.city_town1 = '',
                    this.state_region1 = '',
                    this.country1 = '',
                    this.postal1 = ''
                    this.fetchShippingByUserId()
                    return;
                }
            } catch (err) {
              console.log(err);
          }
        },

        editShipping(shippingid){
          this.$router.push('/shipping/edit/'+shippingid)
        },

        async fetchShippingByUserId() {
            try {
              this.isLoading = true
              this.desserts = []
               const response =  await this.$http.post('/fetch-shipping-byuserid', {
                  userid: this.user_id
                })
              this.isLoading = false

                this.shipping = response.data.data
                this.shipping.forEach(el => {
                        this.desserts.push({
                        shipAddress: el.shipping_address,
                        city: el.city,
                        state: el.state,
                        country: el.country,
                        postal: el.postal,
                        shipAddress1: el.return_address,
                        city1: el.return_city,
                        state1: el.return_state,
                        country1: el.return_country,
                        postal1: el.return_postal,
                        shippingid: el.shippingid,
                        })
                    });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async removeShipping(shippingid){
        let con = confirm("          You're about to remove this shop. Are you sure?")
          if (con == true) {
            try {

              const response =  await this.$http.post('/delete-shipping-byid', {
                shippingid: shippingid,
              })

              if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetchShops()
                return;

              } catch (err) {
              console.log(err);
              }
          }
        }
    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

 .shipping-detail{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
 }
 .ship-address{
    font-size: 16px;
    font-family: Mulish;
    margin-bottom: 1.8rem;
    text-align: left;
 }
.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  margin: 2px;
}
.shop-detail{
    font-size: 12px;
    font-family: Mulish;
}
.btn-danger{
  text-transform: uppercase;
  font-size: 13px;
  font-family: Mulish;
  background: #800080;
  border: 0px;
  height: 45px;
}
  .welcome{
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    color: #170e0fac;
  }
  .account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 100%;
    background: #fff;
  }
  .account-balance{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .wallet-detail{
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;
    h4{
      font-weight: 650;
    }
    .acct-d{
      line-height: 0.8;
      cursor:pointer;
      text-decoration: underline;
    }
  }
  .wallet-icon{
    font-size: 60px
  }
  .fund-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 50px;
}
  .content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
  }
  .box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box-icon{
    padding-left: 0.4rem;
    font-size: 35px;
    color:#637807;
  }
  .box-details{
    font-size: 14px;
    color:#170e0fac;
  }
  .box1:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box2:hover{
     transform: scale(1.1); 
     transition: transform 0.3s ease; 
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
   }
  .box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box3:hover{
      transform: scale(1.1); 
      transition: transform 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
      cursor: pointer;
   }
   .box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box4:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box5:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box6:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: Mulish;
      font-weight: 550;
      font-size: 10px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: Mulish;
      font-size: 12px;
    }
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  .add-fund{
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .btn-secondary{
    text-transform: uppercase;
  }
  
  .modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  }
  
  .modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  
    display: none;
  }
  
  a {
    text-decoration: none;
  }
  .shop-name{
    font-weight: 650;
    color: #637807;
  }
  @media screen and (max-width: 768px) {

    .shipping-detail{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }
    .counter{
      font-size: 13px;
      font-weight: 500 !important;
    }
    .welcome{
      font-family: Mulish;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #170e0fac;
    }
    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.9rem;
    }
    .account-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 0.9rem;
      margin: 4px;
    }
    .account-balance{
      margin-bottom: 10px;
    }
  
    .box-details{
      padding: 0.4rem;
      line-height: 1.4px;
      .title{
        font-family: Mulish;
        font-weight: 550;
        font-size: 8px;
        text-transform: capitalize;
        color: #ff4d63;
      }
      .description{
        font-family: Mulish;
        font-size: 12px;
      }
    }
    
    .inputs{
      font-size: 12px;
    }
    .box1 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box2 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box3 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box4 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box5 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .continue{
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding-right: 0.4rem;
      font-size: 30px;
      margin-top: -10px;
      color: rgb(237, 138, 108);
    }
  
    .modal-close-mobile {
      display: block;
    }
    .modal-close {
      display: none;
    }
  
    .account-content{ 
      margin: 10px;
      margin-right: 30px;
    }
  
  }
  
  </style>