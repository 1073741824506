import Vue from 'vue'
import VueRouter from 'vue-router'
import SellerSignup from '../views/SellerSignup.vue'
import SellerSignin from '../views/SellerSignin.vue'
import Dashboard from '../views/DashboardPage.vue'
import HomeDashboard from '../views/HomeDashboard.vue'
import BankPage from '../views/BankPage.vue'
import ShopPage from '../views/ShopPage.vue'
import ShippingInfo from '../views/ShippingInfo.vue'
import EditShipping from '../views/EditShipping.vue'
import EditShop from '../views/EditShop.vue'
import ProductPage from '../views/ProductPage.vue'
import TransactionPage from '../views/TransactionPage.vue'
import WalletPage from '../views/WalletPage.vue'
import EditContract from '../views/EditContract.vue'
import ViewContract from '../views/ViewContract.vue'
import ProfilePage from '../views/ProfilePage.vue'

import PaymentDashboard from '../views/PaymentDashboard.vue'
import CheckoutDashboard from '../views/CheckoutDashboard.vue'
import PaymentPage from '../views/PaymentPage.vue'
import CheckoutPage from '../views/CheckoutPage.vue'

import TopupPage from '../views/TopupPage.vue'
import BankTransferPage from '../views/BankTransferPage.vue'
import ComfirmPaymentPage from '../views/ComfirmPaymentPage.vue'
import PaymentSuccessfulPage from '../views/PaymentSuccessfulPage.vue'
import PaymentSuccessPage from '../views/PaymentSuccessPage.vue'
import TopupPaymentPage from '../views/TopupPaymentPage.vue'
import BankPaymentSuccess from '../views/BankPaymentSuccess.vue'

import BankPaymentPage from '../views/BankPaymentPage.vue'
import ConfirmBankPaymentPage from '../views/ConfirmBankPaymentPage.vue'
import QuickPaymentPage from '../views/QuickPaymentPage.vue'

// DISPUTE
import DisputePage from '../views/DisputePage.vue'
import AllDisputesPage from '../views/AllDisputesPage.vue'

// ADMIN
import ListWithdrawalPage from '../views/ListWithdrawalPage.vue'
import ListFundWalletsPage from '../views/ListFundWalletsPage.vue'
import ApprovePaymentPage from '../views/ApprovePaymentPage.vue'
import AllContractsPage from '../views/AllContractsPage.vue'
import AllUsersPages from '../views/AllUsersPages.vue'
import UnderpaymentPage from '../views/UnderpaymentPage.vue'


// FORGOT PASSWORD
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/CreatePassword.vue'

// SIGNUP PAGE
import SigninPage from '../views/SigninPage.vue'
import SignupPage from '../views/SignupPage.vue'
import VerifyCodePage from '../views/VerifyCodePage.vue'

// BUYERS
import MyOrders from '../views/MyOrders.vue'

// MY ACCOUNTS
import MyCommissions from '../views/MyCommissions.vue'

import WithdrawalPage from '../views/WithdrawalPage.vue'

// UTILITIES
import AirtimeDataPage from '../views/AirtimeDataPage.vue'
import UtilityQuickPayment from '../views/UtilityQuickPayment.vue'
import UtilityReport from '../views/UtilityReport.vue'
import MyUtilityReport from '../views/MyUtilityReport.vue'
import CableTv from '../views/CableTv.vue'
import Electricity from '../views/Electricity.vue'

// ZEDLOCKER BALANCE
import ZedlockerBalance from '../views/ZedlockerBalance.vue'


Vue.use(VueRouter)

// parse jwt token
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function beforeRouteEnter (to, from, next) {
  try{
  if (localStorage.getItem('wertyuiojhdfghhdrtfyguh')) {
    const jwtPayload = parseJwt(localStorage.getItem('wertyuiojhdfghhdrtfyguh'));
    if (jwtPayload.exp < Date.now() / (1000 * 60)) {
        // token expired
        //deleteTokenFromLocalStorage();
        next("/login");
    }
    next();
  } else {
    next("/login");
  }
  }catch (err) {
    console.log(err);
  }
}

const routes = [
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },  
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  },  
  {
    path: '/contract/:contract_number',
    name: 'SellerSignup',
    component: SellerSignup
  },
  {
    path: '/login/:contract_number',
    name: 'SellerSignin',
    component: SellerSignin
  },
  {
    path: '/login',
    name: 'SigninPage',
    component: SigninPage
  },
  {
    path: '/register',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/verify-email',
    name: 'VerifyCodePage',
    component: VerifyCodePage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/home',
        name: 'HomeDashboard',
        beforeEnter : beforeRouteEnter,
        component: HomeDashboard,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/topup-account',
        name: 'TopupPage',
        beforeEnter : beforeRouteEnter,
        component: TopupPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/buy/:service',
        name: 'AirtimeDataPage',
        beforeEnter : beforeRouteEnter,
        component: AirtimeDataPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/subscribe/tv',
        name: 'CableTv',
        beforeEnter : beforeRouteEnter,
        component: CableTv,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/purchase/electricity',
        name: 'Electricity',
        beforeEnter : beforeRouteEnter,
        component: Electricity,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/bank-transfer/:ref/:amount',
        name: 'BankTransferPage',
        beforeEnter : beforeRouteEnter,
        component: BankTransferPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/pay-option/:ref/:amount/:contractid/:payment_method',
        name: 'TopupPaymentPage',
        beforeEnter : beforeRouteEnter,
        component: TopupPaymentPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/bank-payment/:ref/:amount/:contractid/:payment_method',
        name: 'BankPaymentPage',
        beforeEnter : beforeRouteEnter,
        component: BankPaymentPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/quick-payment/:ref/:amount/:contractid/:payment_method',
        name: 'QuickPaymentPage',
        beforeEnter : beforeRouteEnter,
        component: QuickPaymentPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/utility-payment/:utility/:amount/:network/:phone',
        name: 'UtilityQuickPayment',
        beforeEnter : beforeRouteEnter,
        component: UtilityQuickPayment,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/confirm-bank-payment/:ref/:amount/:contractid/:payment_method/:bank_name/:account_name/:account_number',
        name: 'ConfirmBankPaymentPage',
        beforeEnter : beforeRouteEnter,
        component: ConfirmBankPaymentPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/confirm-payment/:ref/:amount/:bank_name/:account_name/:account_number',
        name: 'ComfirmPaymentPage',
        beforeEnter : beforeRouteEnter,
        component: ComfirmPaymentPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/transfer-success/:ref/:amount/:bank_name/:account_name/:account_number',
        name: 'PaymentSuccessfulPage',
        beforeEnter : beforeRouteEnter,
        component: PaymentSuccessfulPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/payment-success/:ref/:amount/:contractid/:dest_bank_name/:dest_account_name/:dest_account_number/:bank_name/:account_name/:account_number',
        name: 'PaymentSuccessPage',
        beforeEnter : beforeRouteEnter,
        component: PaymentSuccessPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/payment-successful/:ref/:amount/:dest_bank_name/:dest_account_name/:dest_account_number',
        name: 'BankPaymentSuccess',
        beforeEnter : beforeRouteEnter,
        component: BankPaymentSuccess,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/disputes',
        name: 'DisputePage',
        beforeEnter : beforeRouteEnter,
        component: DisputePage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/subscriptions',
        name: 'UtilityReport',
        beforeEnter : beforeRouteEnter,
        component: UtilityReport,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/my-subscriptions',
        name: 'MyUtilityReport',
        beforeEnter : beforeRouteEnter,
        component: MyUtilityReport,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/available-balance',
        name: 'ZedlockerBalance',
        beforeEnter : beforeRouteEnter,
        component: ZedlockerBalance,
        meta: {
          secure:true,
        }
      },
      {
        path: '/all-disputes',
        name: 'AllDisputesPage',
        beforeEnter : beforeRouteEnter,
        component: AllDisputesPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/list-withdrawals',
        name: 'ListWithdrawalPage',
        beforeEnter : beforeRouteEnter,
        component: ListWithdrawalPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/wallet-funding',
        name: 'ListFundWalletsPage',
        beforeEnter : beforeRouteEnter,
        component: ListFundWalletsPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/approve-payments',
        name: 'ApprovePaymentPage',
        beforeEnter : beforeRouteEnter,
        component: ApprovePaymentPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/all-escrows',
        name: 'AllContractsPage',
        beforeEnter : beforeRouteEnter,
        component: AllContractsPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/all-users',
        name: 'AllUsersPages',
        beforeEnter : beforeRouteEnter,
        component: AllUsersPages,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/underpayments',
        name: 'UnderpaymentPage',
        beforeEnter : beforeRouteEnter,
        component: UnderpaymentPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/profile',
        name: 'ProfilePage',
        beforeEnter : beforeRouteEnter,
        component: ProfilePage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shop',
        name: 'ShopPage',
        beforeEnter : beforeRouteEnter,
        component: ShopPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shipping',
        name: 'ShippingInfo',
        beforeEnter : beforeRouteEnter,
        component: ShippingInfo,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shipping/edit/:shippingid',
        name: 'EditShipping',
        beforeEnter : beforeRouteEnter,
        component: EditShipping,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shop/edit/:shopid',
        name: 'EditShop',
        beforeEnter : beforeRouteEnter,
        component: EditShop,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/contract',
        name: 'ProductPage',
        beforeEnter : beforeRouteEnter,
        component: ProductPage,
        meta: {
          secure:true,
        }
      },    
      {
        path: '/bank',
        name: 'BankPage',
        beforeEnter : beforeRouteEnter,
        component: BankPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/edit-contract/:id',
        name: 'EditContract',
        beforeEnter : beforeRouteEnter,
        component: EditContract,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/view-contract/:id',
        name: 'ViewContract',
        beforeEnter : beforeRouteEnter,
        component: ViewContract,
        meta: {
          secure:true,
        }
      },   
      
      {
        path: '/vendor/orders',
        name: 'MyOrders',
        beforeEnter : beforeRouteEnter,
        component: MyOrders,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/vendor/settlements',
        name: 'MyCommissions',
        beforeEnter : beforeRouteEnter,
        component: MyCommissions,
        meta: {
          secure:true,
        }
      },  

      {
        path: '/payments',
        name: 'TransactionPage',
        beforeEnter : beforeRouteEnter,
        component: TransactionPage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/wallet',
        name: 'WalletPage',
        beforeEnter : beforeRouteEnter,
        component: WalletPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/withdrawal',
        name: 'WithdrawalPage',
        beforeEnter : beforeRouteEnter,
        component: WithdrawalPage,
        meta: {
          secure:true,
        }
      }, 
      
    ]
  },

  {
    path: '/checkout/summary/:id',
    name: 'CheckoutDashboard',
    component: CheckoutDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/checkout/summary/:id',
        name: 'CheckoutPage',
        beforeEnter : beforeRouteEnter,
        component: CheckoutPage,
        meta: {
          secure:true,
        }
      },       
    ]
  },

  {
    path: '/checkout/payment/review/:id',
    name: 'PaymentDashboard',
    component: PaymentDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/checkout/payment/review/:id/:payMethod',
        name: 'PaymentPage',
        beforeEnter : beforeRouteEnter,
        component: PaymentPage,
        meta: {
          secure:true,
        }
      },   
       
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
