import { render, staticRenderFns } from "./QuickPaymentPage.vue?vue&type=template&id=8a008146&scoped=true"
import script from "./QuickPaymentPage.vue?vue&type=script&lang=js"
export * from "./QuickPaymentPage.vue?vue&type=script&lang=js"
import style0 from "./QuickPaymentPage.vue?vue&type=style&index=0&id=8a008146&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a008146",
  null
  
)

export default component.exports