<template>
    <div>
        <div class="package-header">
            <p>&nbsp;</p>
            <p class="package-title">WALLET FUNDING</p>
            <p>&nbsp;</p>
        </div>
        <div class="package-container">
            <div class="product">
                <div class="product-0">
                    <div class="button-task">
                        <div class="sort-record">
                            Sort by: 
                            <select class="text-success" name="" id="" @change="sortFunding" v-model="sort_funding">
                                <option value="">--Select--</option>
                                <option :value="3">All</option>
                                <option :value="1">Pending</option>
                                <option :value="2">Approved</option>
                            </select>
                            &nbsp;|&nbsp; Record count: <span class="badge badge-success">{{ wallet_list_count }}</span> 
                        </div>
                        <div>
                          Select All:  <input type="checkbox" class="form-control1 input-check" name="" id="" @change="selectAll" >
                          <v-btn class="assign-task" id="assign-task" @click="approveFundingRequest()" v-if="!loading1">Approve</v-btn>
                          <v-btn class="assign-task" id="assign-task" type="button" disabled v-if="loading1">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </v-btn>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <v-data-table
                          :headers="header"
                          :items="desserts"
                        >
                        <template v-slot:item.amount="{ item }">
                            {{ item.amount.toLocaleString('en-NG', {
                              minimumIntegerDigits: 2
                          })}}
                        </template>
                          <template v-slot:item.approval_status="{ item }">
                            <span v-html="item.approval_status"></span>
                          </template>
                          <template v-slot:item.userid="{ item }">
                            <input type="checkbox" class="form-control1 input-check pl" name="" id="pl" v-model="item.selectedUser" @change="selectAll1">
                          </template>
                        </v-data-table>
                      </div>
                </div>
            </div>   
        </div>   
        <div v-if="isAutoriseCode">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   authorise transaction
                                </div>
                                <div class="account-content-d">
                                  <span>Authorise code</span><br>
                                  <input type="text" class="form-control" placeholder="Enter authorise code" v-model="authorise_code">
                                </div>
                                <button class="btn btn-success" :disabled="isSubmitButtonDisabled" @click="authoriseCode()" v-if="!loading">Authorise</button>
                                <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <!-- <button class="btn btn-secondary" @click="isAutoriseCode = false"><i class="fa fa-long-arrow-left"></i> Back</button> -->
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    <script>
    import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
        name: "ListFundWalletsPage",
        components: {
            PageLoader
        },
      
        data(){
          return {
    
              isLoading: false,
              isaddVendor: false,
              isAutoriseCode: false,
              user_id: '',
              firstname: '',
              lastname: '',
              bank_name:'',
              account_name:'',
              account_number: '',
              ussd: '',
              exp_time: '',
              referral_code: '',
              loading: false,
              loading1: false,
              plans: [],      
              transfer_data: [], 
              current_plan: '',   
              tasks: '',

              vfirstname: '',
              vlastname: '',
              vemail: '',
              vphone: '',
              vusername: '',
              vplan:'',
              vamount: '0.00',
              vbank: '',
              vaccount: '',
              date_joined: '',
              plan_active_status: '',

              authorise_code: '',
              confirm_authorise_code: '',
              header: [
                {
                    align: 'start',
                    value: 'user',
                    sortable: false,
                    text: 'Fullname',
                },
                { value: 'payment_reference', text: 'Payment reference' },
                { value: 'amount', text: 'Amount' },
                { value: 'source_bank', text: 'Source account' },
                { value: 'dest_bank', text: 'Destination account' },
                { value: 'approval_status', text: 'Status' },
                { value: 'date', text: 'Date' },
                { value: 'userid', text: '' },
              ],
            desserts: [],
            wallet_list: [],
            vendor_name: '',
            phone_number1: '',
            email_address1: '',
            countUsers: 0,
            sort_funding: '',
            wallet_list_count: 0,
            disbursement_details: [],
            transaction_success: '',
            transaction_success_code: '',
            transaction_batch_ref: '',
            authorise_success:'',
          };
        },
      
        beforeMount(){
         
        },
      
        mounted(){
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
            this.referral_code = localStorage.getItem("referral_code");
            this.fetchFundingList()
        },
      
        created(){
              
        },
      
        computed: {
          isSubmitButtonDisabled() {
            return (
              this.authorise_code === ''
            );
          },
          indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
            },
        },
      
        methods: {
        
          generateRandomId() {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          },
  
          setShowBtn(index, value) {
            this.$set(this.showBtn, index, value);
          },
  
          async fetchFundingList() {
              try {
                  this.desserts = []
                  this.isLoading = true
                  const response =  await this.$http.get('/fetch-funding-request', {
                  })
                  this.isLoading = false
                  let withd_status = '';
                  this.wallet_list = response.data.funding_request
                  this.wallet_list_count = this.wallet_list.length
                  this.wallet_list.forEach(el => {
                      if(el.approval_status == 1){
                        withd_status = "<span class='text-warning'><strong>Pending</strong></span>";
                      }else if(el.approval_status == 2){
                        withd_status = "<span class='text-success'><strong>Approved</strong></span>";
                      }
                      let source_bank_info = el.source_bank_name + ' - ' + el.source_account_name + ' - ' + el.source_account_number;
                      let dest_bank_info = el.dest_bank_name + ' - ' + el.dest_account_name + ' - ' + el.dest_account_number;
                      this.desserts.push({
                        user: el.firstname + ' ' + el.lastname,
                        payment_reference: el.payment_reference,
                        amount: el.credit,
                        source_bank: source_bank_info,
                        dest_bank: dest_bank_info,
                        account_number: el.account_number,
                        bankcode: el.bank_code,
                        approval_status: withd_status,
                        date: new Date(el.created_at).toDateString(),
                        userid: el.userid,
                        walletid: el.walletid
                      })
                  }); 
                                          
              } catch (err) {
                console.log(err);
            }
          },

          async sortFunding() {
              try {
                  this.desserts = []
                  this.isLoading = true
                  let response = ''
                  if(this.sort_funding == 3){
                        response =  await this.$http.get('/fetch-funding-request', {
                      })
                  }else{
                        response =  await this.$http.post('/fetch-all-funding-bystatus', {
                          approval_status: this.sort_funding
                      })
                  }
                  this.isLoading = false
                  let withd_status = '';
                  this.wallet_list = response.data.funding_request
                  this.wallet_list_count = this.wallet_list.length
                  this.wallet_list.forEach(el => {
                      if(el.approval_status == 1){
                      withd_status = "<span class='text-warning'><strong>Pending</strong></span>";
                      }else if(el.approval_status == 2){
                      withd_status = "<span class='text-success'><strong>Approved</strong></span>";
                      }
                      let source_bank_info = el.source_bank_name + ' - ' + el.source_account_name + ' - ' + el.source_account_number;
                      let dest_bank_info = el.dest_bank_name + ' - ' + el.dest_account_name + ' - ' + el.dest_account_number;
                      this.desserts.push({
                        user: el.firstname + ' ' + el.lastname,
                        payment_reference: el.payment_reference,
                        amount: el.credit,
                        source_bank: source_bank_info,
                        dest_bank: dest_bank_info,
                        account_number: el.account_number,
                        bankcode: el.bank_code,
                        approval_status: withd_status,
                        date: new Date(el.created_at).toDateString(),
                        userid: el.userid,
                        walletid: el.walletid
                      })
                  });                  
                                          
              } catch (err) {
                console.log(err);
            }
          },

          addVendor(){
            this.isaddVendor = true
          },

          selectAll(event) {
            this.desserts.forEach((item) => {
                item.selectedUser = event.target.checked;
                this.selectedUsers = this.desserts.filter(item => item.selectedUser);
              });       

              if (this.selectedUsers == '') {
                  document.getElementById('assign-task').style.display = 'none'
              }
              else{
                  document.getElementById('assign-task').style.display = 'block'
              }
              //console.log(" TEST " + JSON.stringify(this.selectedUsers))
          },

          selectAll1() {
           
              this.selectedUsers = this.desserts.filter(item => item.selectedUser);

              if (this.selectedUsers == '') {
                  document.getElementById('assign-task').style.display = 'none'
              }
              else{
                  document.getElementById('assign-task').style.display = 'block'
              }
              //console.log(" TEST " + JSON.stringify(this.selectedUsers))
          },
          async approveFundingRequest(){
            try {

                    this.loading = true
                    const response =  await this.$http.post('/approve-funding', {
                        user_details: this.selectedUsers,
                    })
                    this.loading = false

                        if(response.data.success){
                            swal.fire({
                            icon: 'success',
                            title: 'Approval Successful',
                            width: 450,
                            height: 5,
                            // text: response.data.message,
                            })
                            this.fetchFundingList()
                            return
                        }
                        
                    } catch (err) {
                    console.log(err);
                    }
          },

          async disburseFund1(){

              this.loading1 = true
              const response =  await this.$http.post('/disburse-fund', {
                  user_details: this.selectedUsers,
              })

              this.loading1 = false
              if(response.data.success){

                      this.disbursement_details = response.data.disburseFund
                      this.transaction_success = this.disbursement_details['requestSuccessful']
                      if (this.transaction_success) {
                        this.transaction_success_code = this.disbursement_details['responseCode']
                        this.transaction_batch_ref = this.disbursement_details['responseBody']['batchReference']
                        //let transaction_status = this.disbursement_details['responseBody']['batchStatus']

                        this.isAutoriseCode = true

                  }
              }
          },

          async authoriseCode(){
            try {

                  this.loading = true
                  const response =  await this.$http.post('/authorise-transaction', {
                      user_details: this.selectedUsers,
                      batch_ref: this.transaction_batch_ref,
                      authorise_code: this.authorise_code,
                  })
                  this.loading = false
                  if(response.data.success){

                      this.authorise_success = response.data.authoriseTransfer
                      this.transaction_success = this.authorise_success['requestSuccessful']
                      if (this.transaction_success) {
                        //let authorise_success_code = this.authorise_success['responseCode']
                        let authorise_status = this.authorise_success['responseBody']['batchStatus']
                      
                        swal.fire({
                        icon: 'success',
                        title: 'Batch Disbursement',
                        width: 450,
                        height: 5,
                        text: authorise_status,
                      })
                      this.fetchFundingList()
                      this.isAutoriseCode = false
                      return
                  }
                }
                      
              } catch (err) {
                console.log(err);
              }
          }
        }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    .modal-mask {
      position: fixed;
      //z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .4s ease;
    
      &>div {
          width: 90%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #fff;;
          backdrop-filter: blur(13px);
          border-radius: 10px;
          box-sizing: border-box;
          font-family: Mulish;
      }
    
      h3 {
          margin-top: 10px;
          font-size: 18px;
          color: #000;
          font-weight: bold;
          font-family: Mulish;
      }
    }
    .account-content{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      //margin-right: 8px;
    }
    .account-content-h{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      align-items: center;
      width: 100%;
      height: 50px;   
      margin: 4px;
      padding-top: 1.3rem;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    .account-content-d{
      padding-top: 1.3rem;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .btn-success{
      background: #2979FF;   
      border: solid 0px;
      margin-bottom: 10px;
    }
    .p-img{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 80px;
      height: 80px;
      background: #312F33;
      border-radius: 47px;
      margin: 10px;
    }
    .active-status{
      background: #312f334b;
      border-radius: 4px;
      margin: 10px;
      width: 80px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .package-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #ccc;
      }
      .package-title{
        font-size: 30px;
        font-weight: bold;
        font-family: Mulish;
        color: #2979FF;
      }
    .tasks-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 15px; 
        font-weight: bold; 
    }
    .package-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0.9rem;
        background: #fff;
        border-radius: 4px;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Mulish;
        font-size: 12px;
        border: solid 0px #ccc;
        margin-bottom: 40px;
        border-radius: 6px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
        padding: 1.2rem;
        width: 100%;
      }
      .product-0{
        width: 100%;
      }
      .button-task{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .assign-task{
        font-family: Mulish;
        font-size: 10px;
        display: none;
      }
      .product-1{
        display: flex;
        flex-direction: column;
        font-family: Mulish;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 10px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
      }
      .product-1-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .product-2{
        display: flex;
        flex-direction: column;
        font-family: Mulish;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 30px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
        width: 100%;
      }
      .product-2-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .edit-authorise_code{
        cursor: pointer;
      }
      .form-control{
        height: 40px;
        margin-bottom: 4px;
        border-bottom: solid 1px #ccc;
        font-family: Mulish;
        font-size: 12px;
      }
    
      .fa-user{
        font-size: 120px;
        color: #2979FF;
      }
    
      @media screen and (max-width: 768px) {
        .package-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .product-0{
            width: 100%;
          }
          .product{
            display: flex;
            flex-direction: column;
            padding: 1.7rem;
            font-family: Mulish;
            width: 100%;
    
          }
          .account-content{
            display: flex;
            flex-direction: column;
            justify-content: center;  
            width: 106%;
            padding: 10px;
          }
      }
    </style>