<template>
    <div class="container-fluid">
        <p class="welcome"></p>
         <div class="col-lg-12 col-sm-12 pt-3 dashboard-page">             
              <div class="col-lg-6 col-sm-6 pt-3">
                <div @click="go_back()" style="cursor:pointer" class="font-weight-normal pb-5"><i class="fa fa-long-arrow-left"></i> Go Back</div>
                <div class="dashboard-inner-new">
                    <div class="wallet-board-new">
                      <div class="avail-amount">
                        <div class="topup">Top Up Your Account</div>
                        <div class="add-money">Add money to your account</div>
                      </div>
                    </div>
                    <div class="wallet-board-new amount mt-4">
                      <div class="avail-amount-p">
                        <div class="amount-p">Amount</div>
                        <div class="input-amount-symbol">
                          <span>₦</span>&nbsp;<input  class="input-amount" type="text" name="" id="" v-model="funding_amount" @input="validateInput">
                          
                        </div>
                        <span v-if="error" class="error">{{ error }}</span>
                        <div class="enter-fund">Enter the amount you want to fund your account with</div>
                        <!-- <div class="trans-charge">Transaction charges: #0.00</div> -->
                      </div>
                    </div>
                    <div class="change-payment">
                      Change payment method:
                      <select name="" id="" class="cpayment-method" v-model="cpayment_method">
                        <option value="regular-payment">Regular Payment</option>
                        <!-- <option value="quick-payment">Quick Payment </option> -->
                      </select> <i class="fa fa-angle-down"></i>
                    </div>
                    <div class="add-withd-panel-enter-p">
                      <button :disabled="isEnabled" @click="bank_transfer()" class="proceed" v-if="!loading">Proceed to Pay</button>
                      <button class="proceed" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                    <div class="add-withd-panel-enter">
                      <button @click="go_back()" class="go-back"> Go Back</button> 
                      <!-- &nbsp;<span class="separator">|</span>&nbsp;<button class="cancel-trans">Cancel</button> -->
                    </div>
                </div>
              </div>
         </div>

          <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "TopupPage",
    components: {
      PageLoader
    },
  
    data(){
      return {
          search: '',
          loading: false,
          isLoading: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          funding_amount: '',
          error: '',
          payment_reference: '',
          fullname: '',
          cpayment_method: 'regular-payment',
          img_url: process.env.VUE_APP_IMAGE_URL + '/products/',
       
        };
    },
  
    beforeMount(){
      this.user_id = localStorage.getItem("userid");
    },
  
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.fullname = this.firstname +'_'+ this.lastname 

    },
  
    created(){
          
    },
  
    computed: {
      
      isEnabled() {
          return (
            this.funding_amount === ''
          );
      }
    },
  
    methods: {

      validateInput(){
        const value = this.funding_amount;
        if (value < 0) {
        this.error = 'Value must be non-negative';
        this.funding_amount = ''
        return
        } else if (!Number.isInteger(Number(value))) {
        this.error = 'Value must be an integer';
        this.funding_amount = ''
        return
        } else {
        this.error = '';
        }
      },
      async bank_transfer(){
        try {
                if (!this.funding_amount) {
                  swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please enter amount to fund',
                  })
                  return;
                }

                this.loading = true
                this.payment_reference = Date.now() % 100000
                const full_name = this.firstname
                const ref = full_name + '_' + this.payment_reference

                if (this.cpayment_method == 'regular-payment') {
                  // swal.fire({
                  //   icon: 'info',
                  //   title: 'Regular Payment',
                  //   width: 450,
                  //   height: 5,
                  //   text: 'May take some time',
                  // })
                  this.loading = false
                  this.$router.push('/bank-transfer/'+ref+'/'+this.funding_amount)
                }
                else if (this.cpayment_method == 'quick-payment') {
                  swal.fire({
                    icon: 'info',
                    title: 'Quick Payment!',
                    width: 450,
                    height: 5,
                    text: 'Not available at the moment',
                  })
                  this.loading = false
                  return;
                }
              
                
                this.loading = false


            } catch (err) {
                console.log(err);
            }
        
      },
      go_back(){
          this.$router.push('/home')
      },
        
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
  .cpayment-method{
    width: auto;
    font-weight: bold;
    cursor: pointer;
  }
  .error{
    color: #fff;
    font-size: 10px;
  }
  .copy-refid1{
    cursor: pointer;
    //font-size: 20px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
  }
  
  .account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  
  .add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .btn-secondary {
    text-transform: normal;
  }
  .welcome{
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    padding: 0px 20px;
    text-align: left;
    color: #170e0fac;
  }
   .dashboard-page{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: #fff;
      padding: 0.6rem;
      font-family: Poppins;
      strong{
        font-weight: normal;
        font-family: Poppins;
      }
   }
   .wallet-board{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
      //background: #807b7b33;
      border-radius: 4px;
      padding: 0.5rem;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
      height: 100%;
   }
   .wallet-account{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    background: #2979FF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
   }
   .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.6rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1.2;
        color: #ccc;
      }
  
   }
   .account-number{
      padding: 0.6rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
  
      span{
        color: #fff;
      }
    }
   }
   .wallet-balance{
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      width: 60%;
      height: 100%;
      //background-image: linear-gradient(-90deg, #fff, #cccccc56, #fff);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      
      h4{
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 12px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
      border-radius: 4px;
   }
   .dashboard-inner{
    overflow-y: auto;
    height: 255px;
    width: 100%;
    border-radius: 15px;
    padding: 1.2rem;
    margin: 10px;
    background: #fff;
   }
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 18px 35px 18px 35px;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
   
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
  
      .topup{
        //font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
      }
      .add-money{
        //font-family: Montserrat;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        color: #ccc;
      }
  
    }
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
       // font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        text-align: left;
  
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        //font-family: Montserrat;
        font-size: 8px;
        font-weight: 600;
        text-align: left;
        color: #D9D9D9;
      }
    }
   }
   .avail-amount-p{
      display: flex;
      flex-direction: column;
      justify-content: left;
      
   }
   .amount-p{
    color: var(--DIM, #7F7F7F);
   }
   .amount{
    padding: 0.6rem !important;
    background: var(--sub-layer, #ECF3FF) !important;
   }
   .input-amount-symbol{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #7F7F7F;

    span{
      color: #2D2D2D;
      //font-family: Montserrat;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      width: 21px;
      height: 20px;
      gap: 0px;
      

    }
   }
   .input-amount{
    width: 100%;
    height: 32px;
    gap: 0px;
    
    background: #FFFFFF;
    color: var(--DIM, #7F7F7F);
    font-size: 15px;
    font-weight: 600;
    padding: 0.6rem;
   }
   .enter-fund{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #7F7F7F);
   }
   .trans-charge{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #2879FD);
   }
   .add-withd-panel-center{
    display: flex;
    flex-direction: column;
   }
   .change-payment{
    width: auto;
    font-size: 10px;
    font-weight: 400;
    text-align: right;
    color: #414244;
    .fa-angle-down{
      color: var(--DIM, #2879FD);
      font-size: 12px;
    }
   }
   .add-withd-panel-enter-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-withd-panel-enter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .proceed{
    width: Hug (138px)px;
    height: Hug (36px)px;
    padding: 10px;
    border-radius: 8px;
    
    background: var(--Primary-Color, #2879FD);
    color: #FFFFFF;
    font-weight: 600;

   }

   .go-back{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
   }
   .cancel-trans{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;  
    color: var(--DIM, #7F7F7F);
   }
   .separator{
    color: var(--Primary-Color, #2879FD);
   }
   .active-c{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 15px;
    height: 100px;
    width: 100%;
  
    .icon{
      font-size: 44px;
    }
   }
   .active-c-history{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
  
    .icon2{
      font-size: 20px;
    }
   }
   .activeo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
   }
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 400;
      font-size: 11px;
      font-family: Poppins;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .location{
      color: #807b7b;
      font-weight: 550;
      font-size: 11px;
      font-family: Poppins;
      margin-top: 4px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
  
      button{
        font-size: 10px;
      }
    }
    button{
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .active-cc-history{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #00000091;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
  
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 12px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 40px;
    }
   }
  
  
  @media screen and (max-width: 768px) {
    .welcome{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      padding: 0px 15px;
      text-align: left;
      color: #170e0fac;
    }
  
    .dashboard-page{
      display: flex;
      flex-direction: column;
      strong{
        margin-top: 10px;
      }
   }
   .dashboard-inner{
    margin: 0px;
    margin-top: 10px;
    padding: 0.8rem;
   }
  
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.6rem;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
   
    width: 100%;
    height: auto;
    padding: 0.9rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #2879FD);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      
      width: 100%;
      .topup{
        //font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
      }
      .add-money{
        //font-family: Montserrat;
        font-size: 8px;
        font-weight: 400;
        text-align: center;
        color: #ccc;
      }
      .avail-bal{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 400;
      }
      .trans-his{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: normal;
        color: #D9D9D9;
      }
  
    }
   
    .input-amount-symbol{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #7F7F7F;
  
      span{
        color: #2D2D2D;
        //font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        width: 21px;
        height: 20px;
        gap: 0px;
        
  
      }
     }
     .input-amount{
      width: 100%;
      height: 32px;
      gap: 0px;
      
      background: #FFFFFF;
     }
     .amount-p{
      color: var(--DIM, #7F7F7F);
      font-size: 10px;
     }
     .enter-fund{
      //font-family: Montserrat;
      font-size: 9px;
      font-weight: 400;
      color: var(--DIM, #7F7F7F);
     }
     .trans-charge{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #2879FD);
     }
     .add-withd-panel-center{
      display: flex;
      flex-direction: column;
     }
     .change-payment{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: 400;
      text-align: right;
      color: #414244;
      .fa-angle-down{
        color: var(--DIM, #2879FD);
        font-size: 10px;
      }
     }
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        font-family: Montserrat;
        font-size: 8px;
        font-weight: normal;
        color: #D9D9D9;
      }
      .pending-fund{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 600;
      }
    }
   }
   .add-withd-panel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-fund{
    width: Fixed (111.7px)px;
    height: Fixed (38px)px;
    padding: 12.67px 16.12px 12.67px 16.12px;
    border-radius: 81.53px;
    
    background: var(--Primary-Color, #2879FD);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 4px;
  
   }
   .withd-fund{
    width: Fixed (112.59px)px;
    height: Hug (38px)px;
    padding: 12.67px 9.85px 12.67px 9.85px;
    border-radius: 81.53px;
    
    background: var(--Secondary-color, #08294A);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
  
   }
  
   .active-c{
  
    .icon{
      font-size: 40px;
    }
   }
  
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .location{
      color: #807b7b;
      font-weight: 200;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .wallet-board{
    padding: 0.5rem;
  }
  
  .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.4rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1;
      }
  
   }
   .account-number{
      padding: 0.4rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
      span{
        color: #fff;
        font-size: 11px;
      }
    }
   }
   .wallet-balance{
      h4{
        font-weight: normal;
        font-size: 14px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
   }
  
   .active-c-history{
    padding: 0px;
    margin-top: 10px;
    .icon2{
      font-size: 15px;
    }
   }
  
   .active-cc-history{
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      
      span{
        color: #00000091;
        font-style: italic;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 23px;
    }
   }
   .account-content {
    margin: 0px;
    margin-right: 0px;
  }
    
  }
  
  </style>