export default {
    data() {
      return {
        inactivityTimeout: null,
        inactivityTimeLimit: 5 * 60 * 1000 // 15 minutes
      };
    },
    created() {
      this.resetInactivityTimeout();
      this.setupActivityListeners();
    },
    methods: {
      resetInactivityTimeout() {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(this.logoutUser, this.inactivityTimeLimit);
      },
      setupActivityListeners() {
        window.addEventListener('mousemove', this.resetInactivityTimeout);
        window.addEventListener('keydown', this.resetInactivityTimeout);
        window.addEventListener('click', this.resetInactivityTimeout);
        window.addEventListener('scroll', this.resetInactivityTimeout);
      },
      logoutUser() {
        // Perform logout actions here
        localStorage.removeItem('wertyuiojhdfghhdrtfyguh');
        this.$router.push('/login');
      }
    },
    beforeDestroy() {
      window.removeEventListener('mousemove', this.resetInactivityTimeout);
      window.removeEventListener('keydown', this.resetInactivityTimeout);
      window.removeEventListener('click', this.resetInactivityTimeout);
      window.removeEventListener('scroll', this.resetInactivityTimeout);
      clearTimeout(this.inactivityTimeout);
    }
  };
  